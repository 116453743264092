import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "simple-react-modal";

import UserCategoryConfirm from "./userCategoryConfirm";

export const Container = props => {
  const [user, setUser] = useState(""),
    [xhr, setXhr] = useState(""),
    [showModal, setShowModal] = useState(""),
    [errorMessage, setErrorMessage] = useState(""),
    [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    const loggedUser = window.localStorage.getItem("loggedUser");
    setUser(loggedUser);
    let info = window.location.pathname.split("/")[2];

    let enviar_correo = process.env.REACT_APP_API_URL + "/api/enviar_correo";
    const dataCorreo = { contactar: loggedUser, categoria: info };

    axios
      .post(enviar_correo, dataCorreo)
      .then(response => {
        let { data } = response;
        if (!data.status) {
          setXhr(false);
          setShowModal(true);
          setErrorMessage("server-error: " + data.Mensaje);
        }
      })
      .catch(e => {
        setXhr(false);
        setShowModal(true);
        setErrorMessage("server-error: " + e.response.data.message);
      });
  }, []);

  if (user)
    return (
      <div>
        <Modal
          show={showModal}
          onClose={() => setShowModal(false)}
          className={"modal"}
          containerClassName={"modal-container"}
        >
          <div className={"close-button"}>
            <i className={"material-icons"} onClick={() => setShowModal(false)}>
              close
            </i>
          </div>
          <div className={"modal-body"}>
            {modalMessage}
            {errorMessage}
            <div>
              <button
                className="quote btn btn-default"
                onClick={() => setShowModal(false)}
              >
                Cerrar
              </button>
            </div>
          </div>
        </Modal>
        <UserCategoryConfirm />
      </div>
    );
  return (
    <div>
      <h2>No estas autorizado</h2>
    </div>
  );
};

export default Container;
