import React from "react";
import Sidebar from "shared/components/Sidebar/Sidebar";
import ServiceItem from "./components/ServiceItem";
import background from "assets/backgrounds/01.jpg";
import "./styles.css";

const Services = () => (
  <section className="screen-contents services-screen">
    <div className="background">
      <img src={background} alt="Fondo" />
    </div>
    <div className="details-container">
      <div className="content">
        <div className="content-title">
          <h1>Servicios</h1>
          <div className="phone">
            {/* <a href="tel:08102227722">0810 222 7722</a>{" "} */}
          </div>
        </div>
        <div className="content-description">
          <p>
            Vía Cargo te ofrece una amplia gama de servicios postales de manera
            rápida y segura.
          </p>
          <p>Realizá tus envíos y hasta cualquier punto del país.</p>
        </div>

        <ServiceItem title="Personas">
          Cada persona es única, al igual que su motivo y encomienda. Por eso en
          Vía Cargo ofrecemos diferentes servicios que le proporcionan al
          usuario soluciones personalizadas. Sea lo que sea que tengas que
          enviar nosotros podemos encargarnos. Entendemos la importancia o el
          valor afectivo que pueden tener tus envíos. Ofrecemos un servicio
          personalizado, confiable y seguro, mediante una extensa red a lo largo
          de todo el país.
        </ServiceItem>

        <ServiceItem title="Emprendedores y PyMes">
          En Vía Cargo nos gusta verlos crecer y alcanzar sus objetivos. Por
          este motivo nos complace trabajar a su lado desde esta primer etapa,
          con el compromiso de brindarles un servicio seguro y responsable que
          les permita enviar mercadería de gran volumen con protección
          garantizada y, además, crear un vínculo fuerte, directo y de confianza
          con su cliente. Simplificamos tu día a día para que puedas dedicarte a
          lo importante, para que puedas hacer crecer tus negocios.
        </ServiceItem>

        <ServiceItem title="Corporativo">
          En Vía Cargo sabemos que es necesario cumplir con los tiempos de
          entrega previstos para preservar la confianza y credibilidad de los
          clientes. Por consecuencia, brindamos un servicio corporativo con una
          amplia gama de soluciones logísticas con foco en la puntualidad de la
          encomienda para lograr dichos objetivos.
        </ServiceItem>
        <div>
          <ServiceItem title="Transporte de caudales">
            <br />
            <strong>
              <p>
                Sección 3. Punto 3.2. Comunicación B.C.R.A. "A" 6218 y
                complementarias
              </p>
            </strong>
            <br />
            <div className="ContainerTable">
              <div className="LeftContainer">
                <div className="M1">Servicios</div>
                <div className="M2">Precio por kilómetro</div>
                <div className="M3">
                  Recargo <br /> servicio en <br /> día Sábado Domingo o <br />{" "}
                  feriado
                </div>
              </div>
              <div className="RightContainer">
                <div className="RateField">Tarifa Fija</div>
                <div className="FromTo">
                  <div className="From">Desde</div>
                  <div className="To">Hasta</div>
                </div>
                <div className="Price">
                  <div className="PriceOne">$1.500</div>
                  <div className="PriceTwo">$3.500</div>
                </div>
                <div className="Percent">
                  <div className="PercentOne">50%</div>
                  <div className="PercentTwo">100%</div>
                </div>
              </div>
            </div>
            <br />
            El cuadro tarifario publicado es aplicable a todos nuestros
            clientes.
            <br />
            <br />
            La variación entre el mínimo y máximo publicado depende de factores
            objetivos de cada servicio a<br />
            contratar.
            <br />
            Pueden existir ciertos elementos no contemplados en el presente y
            que serán informados y cotizados al
            <br />
            momento de contratar el servicio:
            <br />
            <br />
            1- El volumen de los valores a transportar
            <br />
            2- El nivel de seguridad de los puntos de carga y descarga
            <br />
            3- Elementos que puedan utilizarse para la carga y descarga del
            material transportado
            <br />
            4- La cantidad de destinos en los que se deba recaudar y/o entregar
            por cada cliente, así como la
            <br />
            distancia geográfica a recorrer.
            <br />
            5- Los impuestos de cada jurisdicción en la que corresponda
            tributar.
          </ServiceItem>
        </div>
      </div>
      <Sidebar />
    </div>
  </section>
);

export default Services;
