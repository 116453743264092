import React, { Component } from "react";

import axios from "axios";
import Select from "react-select";
import Modal from "simple-react-modal";

import Button from "shared/components/Button";
import "./styles.css";
import validators from "../../../../../../shared/utils/validators";

class ContactForm extends Component {
  constructor(props) {
    super(props);

    let type = null;
    let options = [
      {
        value: "commerce-1",
        label: "Asesoramiento comercial sobre productos o servicios"
      },
      {
        value: "commerce-2",
        label: "Asesoramiento comercial / Quiero sumarme a la red de Vía Cargo"
      },
      {
        value: "corporate-1",
        label:
          "Clientes Corporativos / Quiero utilizar los servicios de Vía Cargo"
      },
      {
        value: "pymes-1",
        label: "Pymes / Quiero información para mi negocio y productos"
      },
      {
        value: "accounts-1",
        label: "Cuentas Corrientes / Quiero abrir una Cuenta Corriente"
      },
      { value: "hr-1", label: "R.R.H.H / Quiero trabajar en Vía Cargo" }
    ];

    if (window.location.search.indexOf("join") > -1) {
      type = options[1];
    }

    this.state = {
      options,
      type,
      subject: "",
      name: "",
      email: "",
      location: "",
      message: "",
      showModal: false,
      errorMessage: ""
    };
  }

  _handleInput = (prop, value) => {
    this.setState({ [prop]: value });
  };

  _handleSelect = (prop, values) => {
    this.setState({ [prop]: values });
  };

  _submitForm = () => {
    let { xhr, type, subject, name, email, message, location } = this.state;

    if (xhr) return false;

    let checkComplete = [type, subject, name, email];
    let error = false;
    for (let each of checkComplete) {
      if (each === "") error = true;
    }
    if (error) {
      this.setState({
        showModal: true,
        errorMessage: "incomplete"
      });
      return false;
    }

    if (!validators.email(email)) {
      this.setState({
        showModal: true,
        errorMessage: "wrong-email"
      });
      return false;
    }

    this.setState({ xhr: !xhr });

    let EMAIL_SERVICE = process.env.REACT_APP_API_URL + `/api/email/`;

    let data = {
      type: type["value"],
      name,
      email,
      subject,
      location,
      message
    };

    axios
      .post(EMAIL_SERVICE, data)
      .then(() => {
        this.setState({
          xhr: false,
          subject: "",
          name: "",
          email: "",
          location: "",
          message: "",
          showModal: true,
          errorMessage: "completed"
        });
      })
      .catch(e => {
        this.setState({
          xhr: false,
          showModal: true,
          errorMessage: "server-error"
        });
        console.log(e);
      });
  };

  render() {
    const placeholder =
      "Por favor, incluí cualquier información adicional que consideres necesaria " +
      "para que nuestro equipo de Atención al Cliente pueda derivar tu consulta " +
      "con el área correspondiente.";

    let modalMessage = "";
    let { errorMessage } = this.state;
    if (errorMessage === "incomplete") {
      modalMessage = (
        <div>
          <p>Falta completar uno o más campos obligatorios.</p>
          <p>Por favor, volvé a intentarlo</p>
        </div>
      );
    } else if (errorMessage === "wrong-email") {
      modalMessage = (
        <div>
          <p>Por favor, ingresá un correo electrónico válido</p>
          <br />
        </div>
      );
    } else if (errorMessage === "server-error") {
      modalMessage = (
        <div>
          <p>Hubo un problema de conexión con el sistema.</p>
          <p>Por favor, volvé a intentarlo</p>
        </div>
      );
    } else if (errorMessage === "completed") {
      modalMessage = (
        <div>
          <p>Gracias por tu consulta.</p>
          <p>La misma ya fue redirigida al área de interés seleccionada.</p>
        </div>
      );
    }

    return (
      <div>
        <div className="form-container contact-form">
          <div>
            <div className={"label-div"}>ÁREA DE INTERÉS *</div>
            <Select
              value={this.state.type}
              name="type"
              options={this.state.options}
              className="basic-select"
              classNamePrefix="select"
              placeholder={"Seleccioná una opción"}
              onChange={option => this._handleSelect("type", option)}
            />
          </div>
          <div>
            <div className={"label-div"}>Asunto *</div>
            <input
              type={"text"}
              value={this.state.subject}
              style={{ width: "100%" }}
              onChange={ev => this._handleInput("subject", ev.target.value)}
            />
          </div>
          <div>
            <div className={"label-div"}>Nombre *</div>
            <input
              type={"text"}
              value={this.state.name}
              style={{ width: "100%" }}
              onChange={ev => this._handleInput("name", ev.target.value)}
            />
          </div>
          <div>
            <div className={"label-div"}>Mail *</div>
            <input
              type={"text"}
              value={this.state.email}
              style={{ width: "100%" }}
              onChange={ev => this._handleInput("email", ev.target.value)}
            />
          </div>
          <div>
            <div className={"label-div"}>Localidad</div>
            <input
              type={"text"}
              value={this.state.location}
              style={{ width: "100%" }}
              onChange={ev => this._handleInput("location", ev.target.value)}
            />
          </div>
          <label className="labelForm">
            Mensaje
            <textarea
              className="messageForm"
              placeholder={placeholder}
              value={this.state.message}
              onChange={ev => this._handleInput("message", ev.target.value)}
            />
          </label>
        </div>
        <div className="informationForm">
          <label>Los campos con * son obligatorios.</label>
        </div>
        <div className="sendForm">
          <Button className="send" onClick={this._submitForm}>
            Enviar
          </Button>
        </div>
        <div className={"contact-form-footer"}>
          <p>
            Nuestro Call Center se encuentra disponible para responder a cada
            una de tus consultas de la manera más ágil y eficiente,
            asegurándonos de entregarte información detallada sobre lo que
            necesites. Comunicate con nosotros vía mail{" "}
            <a href="mailto:consultas@viacargo.com.ar">
              consultas@viacargo.com.ar
            </a>{" "}
            .
          </p>
          <div className={"form-footer-service"}>
            <div className={"footer-service-title"}>Sé parte de Vía Cargo</div>
            <div>
              Si estás interesado en ser un concesionario del servicio postal,
              completá el formulario marcando en el área de interés la opción
              “Asesoramiento comercial / Quiero información sobre las
              franquicias” o contactate con uno de nuestros representantes
              comerciales vía mail.
            </div>
          </div>
          <div className={"form-footer-service"}>
            <div className={"footer-service-title"}>
              R.R.H.H / Quiero trabajar en Vía
            </div>
            <div>
              Si estás interesado en trabajar con nosotros, completá el
              formulario marcando en el área de interés la opción “R.R.H.H /
              Quiero trabajar en Vía”.
            </div>
          </div>
        </div>
        <Modal
          show={this.state.showModal}
          onClose={() => this.setState({ showModal: false })}
          className={"modal"}
          containerClassName={"modal-container"}
        >
          <div className={"close-button"}>
            <i
              className={"material-icons"}
              onClick={() => this.setState({ showModal: false })}
            >
              close
            </i>
          </div>
          <div className={"modal-body"}>
            {modalMessage}
            <div>
              <Button
                className="quote"
                onClick={() => this.setState({ showModal: false })}
              >
                Entendido
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ContactForm;
