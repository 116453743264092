import React, { Component, useState, useEffect, useRef } from "react";
import { withRouter } from "react-router";
import { Link, BrowserRouter, Switch, Route } from "react-router-dom";
import Sidebar from "shared/components/Sidebar/Sidebar";
import background from "assets/backgrounds/01.jpg";
import "./styles.css";
import basica from "../../../../../src/assets/entrega_domicilio/basica.png";
import estandar from "../../../../../src/assets/entrega_domicilio/estandar.png";
import styles from "./HeaderContent.module.css";
//import { useParams } from "react-router";
//import { useHistory, useParams } from 'react-router-dom'
//import { useParams } from 'react-router-dom'
import axios from "axios";
import Modal from "simple-react-modal";

//import Form from './Form/Form'

import { Accordion, Card, Col, Button, Form, CardGroup, Transitions, Collapse } from "react-bootstrap";
import Formulario from './Formulario'

import "bootstrap/dist/css/bootstrap.min.css";


class PickUpFormulario extends Component {

  constructor(props) {
    super(props);

    let info = "";
    if (props.match.params && props.match.params.info) {
      info = props.match.params.info;
    }
    

    this.state = {      
      activeId: 1123,
      setActiveId: 1,
      
      urlIframe:"",
      infoFirstData:{},
      formPago:{},
      info: info
    }
    

  }

  componentDidMount() {
    ///this.mostrar()
    //this.setState({formPago:{'clave':"VALOR", 'clave2':"VALOR2"}})
    document.getElementById("checkoutForm").submit();
  }
  
  toggleActive(id) {
    if (this.state.activeId === id) {
      this.setState({setActiveId:null})
    } else {
      this.setState({setActiveId:id})
    }
  }

  render() {
    let { activeId, setActiveId } = this.state;
    let valor = "'"+activeId+"'"
    let formPago = JSON.parse(decodeURIComponent(this.state.info))
    
    return (
      <section className="screen-contents faqs-screen">
        
        
          <div className="content" style={{minHeight:800}}>
          <form target="_self" ref={""} id="checkoutForm" method="post" action={formPago.action} >                         
              {Object.entries(formPago).map(([key,value])=>{
                  let i = 0;
                  return (
                      <input key={i++} type="hidden" name={key} value={value}></input>          
                  );
              })}
            </form>
            
            {/* <Formulario formPago={this.state.formPago} />*/}
          <div id="paymentForm"></div>
          </div>
              
            
      </section>
    )
  }
}

export default PickUpFormulario;
