import React from "react";
import { bool, func, oneOf, string } from "prop-types";
import classnames from "classnames";
import "./styles.css";

const Button1 = ({
  children,
  onClick,
  secondary,
  size,
  className,
  disabled
}) => {
  const buttonType = secondary ? "secondary-button" : "primary-button";
  const rootStyle = classnames(buttonType, size, className);

  return (
    <button onClick={onClick} className={rootStyle} disabled={disabled}>
      {children}
    </button>
  );
};

Button1.defaultProps = {
  disabled: false,
  secondary: false,
  size: "md"
};

Button1.propTypes = {
  children: string,
  onClick: func,
  secondary: bool,
  disabled: bool,
  size: oneOf(["sm", "md", "lg"])
};

export default Button1;
