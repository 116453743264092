import React from "react";
import { bool, node } from "prop-types";
import "./styles.css";

const Menu = ({ children, primaryMenu }) => {
  const underline = primaryMenu && "underline";
  return (
    <section className={`menu ${underline}`}>
      <div className="menu-children">{children}</div>
    </section>
  );
};

Menu.propTypes = {
  children: node,
  primaryMenu: bool
};

export default Menu;
