import React from "react";
import { node, string } from "prop-types";
import "./styles.css";

const ServiceItem = ({ title, children }) => (
  <div className="service-item">
    <h1 className="item-title">{title}</h1>
    <p className="item-details">{children}</p>
  </div>
);

ServiceItem.propTypes = {
  children: node,
  title: string
};

export default ServiceItem;
