import React from "react";
import ProductSummary from "./ProductSummary";
import ps01 from "assets/img/product-summary-1.jpg";
import ps02 from "assets/img/product-summary-2.jpg";
import ps03 from "assets/img/product-summary-3.jpg";
import ps04 from "assets/img/product-summary-4.jpg";
import "./styles.css";

const SummaryProducts = () => (
  <section className="summary-products">
    <ProductSummary img={ps01}>
      <h2 className="product-detail-text">VC Personal</h2>
      <h2 className="product-detail-subtext">Puerta a Puerta</h2>
      <h2 className="product-detail-subtext">-A todo el pais-</h2>
    </ProductSummary>

    <ProductSummary img={ps02}>
      <h2 className="product-detail-text">VC Standard</h2>
      <h2 className="product-detail-subtext">Agencia / Agencia</h2>
    </ProductSummary>

    <ProductSummary img={ps03}>
      <h2 className="product-detail-text">VC Plus</h2>
      <h2 className="product-detail-subtext">Agencia / Domicilio</h2>
      <h2 className="product-detail-subtext">Domicilio / Agencia</h2>
    </ProductSummary>

    <ProductSummary img={ps04}>
      <h2 className="product-detail-text">Vía Automática</h2>
      <h2 className="product-detail-subtext"></h2>
      <h2 className="product-detail-subtext"></h2>
    </ProductSummary>
  </section>
);

export default SummaryProducts;
