export const returnOnlyNumbers = value => {
  let n = 0;

  if (!isNaN(value) && value >= 0) n = value;

  return n;
};

export const isPositiveNumber = n => {
  console.log(n, !isNaN(n) || n >= 0);
  return !isNaN(n) || n >= 0;
};

export function isValidEmail(email) {
  //const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{1,2}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{1,})$/i;
  if (re.test(String(email).toLowerCase())) {
    return "none";
  } else {
    return "block";
  }
  //return re.test(String(email).toLowerCase());
}

export function returnOnlyNumbers1(value) {
  let n = "";

  if (value.length && value)
    n = value.match(/[0-9]/g) ? value.match(/[0-9]/g).toString() : "";

  //console.log(n)
  //n =   value.match(/[0-9]/g)

  return n;
}
