import React, { useState } from "react";

import Password from "./password";

export const Container = () => {
  const [email, setEmail] = useState(""),
    [emailError, setEmailError] = useState(null);

  const emailHandler = e => {
    const inputValue = e.target.value;
    setEmail(inputValue);
  };

  const submitEmail = () => {
    if (!email) {
      setEmailError("Ingresa Email");
      return false;
    }
  };

  return (
    <Password
      submitEmail={submitEmail}
      emailHandler={emailHandler}
      emailError={emailError}
    />
  );
};

export default Container;
