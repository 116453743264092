import React, { Component } from "react";

import Sidebar from "../../../../shared/components/Sidebar/Sidebar";
import Tabbar from "../../../../shared/components/Tabbar/Tabbar";
import CustomerSupport from "../CustomerSupport/CustomerSupport";
import background from "../../../../assets/backgrounds/03.jpg";
import ContactForm from "./components/ContactForm";
import "./styles.css";
import { Link } from "react-router-dom";

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [
        {
          value: "contact",
          label: "Contacto",
          action: () => this._toggleTab("contact"),
          active: window.location.pathname.indexOf("contact") > -1
        },
        {
          value: "claim",
          label: "Reclamos",
          action: () => this._toggleTab("claim"),
          active: window.location.pathname.indexOf("support") > -1
        }
      ],
      width: 850,
      height: 800
    };
  }

  updateDimensions() {
    if (window.innerWidth < 900) {
      let update_width = window.innerWidth - 65;
      let update_height = window.innerHeight + 200;
      this.setState({ width: update_width, height: update_height });
    } else if (window.innerWidth < 1500) {
      let update_width = window.innerWidth - 460;
      let update_height = window.innerHeight + 200;
      this.setState({ width: update_width, height: update_height });
    } else {
      let update_width = window.innerWidth - 490;
      let update_height = window.innerHeight + 200;
      this.setState({ width: update_width, height: update_height });
    }
  }

  componentWillMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  _toggleTab = tab => {
    // Already active
    if (this.state.options.filter(option => option.value === tab)[0]["active"])
      return false;

    let options = [...this.state.options];

    options = options.map(option => {
      option.active = option.value === tab;
      return option;
    });

    this.setState({ options });
  };

  render() {
    let activeTab = this.state.options.find(option => option.active);

    return (
      <section className="screen-contents contact-screen">
        <div className="background">
          <img src={background} alt="Fondo" />
        </div>
        <div className="details-container">
          <div className="content">
            <div className="content-title">
              <h1>Contacto / Atención al cliente</h1>
              <div className="phone">
                {/* <a href="tel:08102227722">0810-222-7722</a>{" "} */}
              </div>
            </div>
            <div className="contact-container">
              <div>
                <h4 className={"titleSeparator"}>
                  Bienvenido al area de atención al cliente de Vía Cargo
                </h4>
                <p>
                  A traves de los siguientes espacios vamos a resolver sus
                  consultas.
                </p>
                <ul>
                  <li>
                    Si pasaron más de 72hs hábiles y no recibiste tu envío y la
                    encomienda no tuvo movimientos en la sección de
                    <a href={"/tracking"} className="titel-tex">
                      {" "}
                      seguimientos{" "}
                    </a>
                    .
                  </li>
                  <li>
                    Amplia tu consulta con la agencia de origen del envío o en
                    el siguiente formulario.
                  </li>
                </ul>
              </div>

              <div className="button-container">
                <span>Pulsa el botón para abrir el formulario</span>
                <Link
                  to={{
                    pathname:
                      "https://gtsviacargo.alertran.net/gts/pub/contactenos_via.seam?tacceso=DEP%20,"
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact-button"
                >
                  Contactar
                </Link>
              </div>
              <div className="video-container">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/Ez3nkW4NS-E"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <Sidebar />
        </div>
        <div className="details-container">
          <div className="content">
            <div className="content-title">
              <h1>Queremos ayudarte a resolver tu consulta</h1>
              <div className="phone">
                {/* <a href="tel:08102227722">0810-222-7722</a>{" "} */}
              </div>
            </div>
            <br />

            <div className="contact-container">
              <div className="questionsandanswers">
                <p className="contact-question">
                  1. ¿Cúal es el costo de realizar un envío?
                </p>
                <p>
                  El costo de un envío dependerá de la distancia entre el origen
                  y destino, del tipo de servicio elegido, y del volumen y peso
                  del producto. Podes estimar el costo de tus envíos ingresando
                  a{" "}
                  <a href={"/quote"} className="titel-tex">
                    {" "}
                    Cotizar.{" "}
                  </a>
                </p>
              </div>

              <div className="questionsandanswers">
                <p className="contact-question">
                  2. ¿Cómo puedo realizar un envío online?
                </p>
                <p>
                  Ingresa al siguiente{" "}
                  <a href={"/product/rd"} className="titel-tex">
                    {" "}
                    link.{" "}
                  </a>
                  <br />
                  Completa el formulario y listo! Lo pasaremos a buscar por tu
                  domicilio.
                  <br />
                  Conoce también nuestro producto
                  <a href={"/product/ea"} className="titel-tex">
                    {" "}
                    Entrega en Agencia,{" "}
                  </a>
                  que te permitirá ahorrar tiempo, si decidís despacharlo desde
                  una sucursal.
                </p>
              </div>

              <div className="questionsandanswers">
                <p className="contact-question">
                  3. ¿Cómo puedo pagar mi envío?
                </p>
                <p>
                  Podes pagar tus envíos en efectivo, tarjetas de débito y/o
                  crédito.
                </p>
              </div>

              <div className="questionsandanswers">
                <p className="contact-question">
                  4. ¿Cómo hago para embalar correctamente mi envío?
                </p>
                <p>
                  El embalaje es muy importante realizarlo correctamente. Por
                  ésta razón te acercamos unas recomendaciones para asegurar el
                  buen acondicionamiento de tu carga.{" "}
                  <a href={"/tips"} className="titel-tex">
                    {" "}
                    Link{" "}
                  </a>
                  <br />
                  Adicionalmente nuestro puntos de venta, te ayudarán a reforzar
                  embalaje si así se requiere.
                </p>
              </div>

              <div className="questionsandanswers">
                <p className="contact-question">
                  5. ¿Qué documentación debo presentar para hacer un envío?
                </p>
                <p>
                  Los envíos nacionales sólo requieren presentar DNI y los datos
                  del destinatario, a excepción de la provincia de MISIONES, en
                  la cual se deberá informar el número de CUIL/CUIT del
                  remitente y destinatario.
                </p>
              </div>

              <div className="questionsandanswers">
                <p className="contact-question">
                  6. ¿Cúando aparecerá información para realizar el seguimiento
                  de mi envío?
                </p>
                <p>
                  Podes comenzar a seguir tu envío, una vez que sea despachado
                  el producto en una sucursal o bien retirado por tu domicilio.
                </p>
              </div>

              <div className="questionsandanswers">
                <p className="contact-question">
                  7. ¿Cuándo voy a recibir mi envío?
                </p>
                <p>
                  El plazo de entrega dependerá del origen / destino
                  seleccionado y puede variar entre 24hs a 72 hs.
                  <br />
                  Tené en cuanta que los tiempos son estimados y pueden variar
                  dependiendo de circunstancias ajenas a la empresa.
                  <br />
                  Te estaremos informando de los cambios de estado a través del
                  mail indicado al momento del despacho.
                  <br />
                  También podes realizar el seguimiento de tu envío ingresando a{" "}
                  <a href={"/tracking"} className="titel-tex">
                    {" "}
                    Seguimientos.{" "}
                  </a>
                  <br />
                  Si ya transcurrieron más de 5 días y no contas con novedades
                  de tu envío completa el siguiente{" "}
                  <a
                    href={
                      "https://gtsviacargo.alertran.net/gts/pub/contactenos_via.seam?tacceso=DEP%20"
                    }
                    className="titel-tex"
                  >
                    {" "}
                    formulario.{" "}
                  </a>
                </p>
              </div>

              <div className="questionsandanswers">
                <p className="contact-question">
                  8. Requisitos para retirar tu envío en sucursal
                </p>
                <p>
                  Los paquetes pueden ser entregados al destinatario declarado
                  en el envío, previa acreditación obligatoria de su DNI.
                  <br />
                  En caso de que se presente una persona distinta del
                  destinatario, podrá presentar una nota de autorización expresa
                  con firma certificada por Escribano, Juez de Paz o Entidad
                  Bancaria y copia de DNI o bien podes optar por ir acompañado
                  del remitente, quien deberá acreditar su identidad presentando
                  su DNI.
                  <br />
                  Podes conocer las ubicaciones y horarios de atención de
                  nuestras sucursales ingresando a{" "}
                  <a href={"/agencies"} className="titel-tex">
                    {" "}
                    Agencias.{" "}
                  </a>
                </p>
              </div>

              <div className="questionsandanswers">
                <p className="contact-question">
                  9. ¿Se puede cambiar el domicilio de entrega o el
                  destinatario?
                </p>
                <p>
                  El cambio de destino o destinatario solo puede ser gestionado
                  por el remitente, a través de la agencia donde fue despachada
                  (origen).
                </p>
              </div>

              <div className="questionsandanswers">
                <p className="contact-question">
                  10. ¿Cuáles son los elementos prohibidos para el transporte
                  postal?
                </p>
                <p>
                  Está terminantemente prohibido realizar envíos que por su
                  naturaleza o embalaje resulten peligrosos para las personas
                  que los manipulan, para otros envíos y para los equipos
                  postales o medios de transporte:
                  <br />
                  - Armas, pólvora, municiones o afines. <br />
                  - Elementos líquidos y/o frágiles y/o explosivos. <br />
                  - Sustancias inflamables, corrosivos, explosivos,
                  contaminantes y precursores químicos. <br />
                  - Materiales magnéticos, radioacticos y biológicos.
                  <br />
                  - Drogas, estupefacientes, medicamentos psicotrópicos y
                  psicofármacos en general. <br />
                  - Sustancias de carácter infeccioso y/o peligroso.
                  <br />
                  - Obras de arte y joyas.
                  <br />
                  - Dinero en efectivo, cheques u otros valores, tarjetas de
                  crédito, débito.
                  <br />
                  - Plantas y animales vivos.
                  <br />
                  - Alimentos perecederos. <br />
                </p>
                <p>
                  Cualquier otra sustancia y/o material que no es permitida por
                  la normativa postal o cuyo uso o tenencia esté penado
                  legalmente o que se hubiera obtenido en violación a normas del
                  Código aduanero y/o cualquier otra normativa vigente.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;
