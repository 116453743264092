import React, { Component } from "react";
import Scroll from "react-scroll";

import Banner from "./components/Banner";
import Carousel from "./components/Carousel";
import CarouselInfo from "./components/CarouselInfo";
import SummaryBar from "./components/SummaryBar";
import SummaryDetails from "./components/SummaryDetails";
import SummaryProducts from "./components/SummaryProducts";
import Slider from "./components/Slider";
import Map from "./components/Map";
import Mapinfo from "./components/Mapinfo";

import ImageText from "./components/Mobile/ImageText";
import QuickAccess from "./components/Mobile/QuickAccess";
import Services from "./components/Mobile/Services";
import mobileLanding from "../../../../assets/main/via_cargo_home_mobile.jpg";
import mobileLandingCompras from "../../../../assets/main/via_compras_mobile.jpg";

import slider01 from "../../../../assets/main/slider/pic1.jpg";

import arrowDown from "./../../../../assets/down-chevron.png";

import "./styles.css";
import Button from "../../../../shared/components/Button/Button";
import ImageTextViacompra from "./components/Mobile/ImageTextViacompra";

import Modal from "simple-react-modal";
import popupImage from "../../../../shared/assets/PICK UP MOVIL-04.png";

const scroll = Scroll.animateScroll;
let ancho;
let alto;
let width;
let height;
class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: false,
      showModal: true,
      errorMessage: "",
      modalMessage: ""
    };
    height = document.documentElement.clientHeight;
    width = document.documentElement.clientWidth;
    if (height < width) {
      alto = 0.9 * height;
    } else {
      ancho = 0.8 * width;
    }
    console.log("width : ", width);
    console.log("height : ", height);
    console.log("ancho : ", ancho);
    console.log("alto : ", alto);
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    if (window.innerWidth < 900) {
      this.setState({ mobile: true });
    } else {
      this.setState({ mobile: false });
    }
  }

  _scrollDown = () => {
    scroll.scrollTo(window.innerHeight);
  };

  render() {
    let textMobile = (
      <div className="legend info">
        <h1 className="carousel-info-title">Asistencia personal</h1>
        <div className="carousel-info-details">
          <p>Simplificamos tu día a día para que</p>
          <p>puedas dedicarte a lo importante</p>
        </div>
        <div>
          <a href={"/services"}>
            <Button secondary>Saber más</Button>
          </a>
        </div>
      </div>
    );

    let textMobileViacompra = (
      <div className="legend info">
        <h1 className="carousel-info-title">Elegí, lo pedís, lo tenés</h1>
        <div className="carousel-info-details">
          <p>Llegó Via Compras, tu nueva tienda online!</p>
        </div>
        <div>
          <a href={"https://www.viacompras.com.ar"} target="_blank">
            <Button className="button-carousel">Comprar</Button>
          </a>
        </div>
      </div>
    );

    return (
      <section>
        {!this.state.mobile && (
          <div>
            <Carousel />
            <div className="go-down" onClick={this._scrollDown}>
              <img src={arrowDown} style={{ width: "50px" }} />
            </div>
            <SummaryBar />
            <Banner />
            <Mapinfo />
            {/* <CarouselInfo /> */}
            <SummaryDetails />
            <SummaryProducts />
            <Slider />
            <Map />
          </div>
        )}
        {this.state.mobile && (
          <div>
            <ImageText image={mobileLanding} text={"A todo el país"} />
            {/* <SummaryBar /> */}
            <QuickAccess />
            <Banner />
            <Mapinfo />
            {/* <ImageText image={slider01} text={textMobile} /> */}
            <ImageTextViacompra
              image={mobileLandingCompras}
              text={textMobileViacompra}
            />
            {/* <ImageText image={slider01} text={textMobile} /> */}
            {/* <Services /> */}
          </div>
        )}
      </section>
    );
  }
}

export default Main;
