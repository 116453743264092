import React, { Component, useState } from "react";

import Sidebar from "shared/components/Sidebar/Sidebar";
import MapSeguimiento from "./components/MapSeguimiento";
import background from "assets/backgrounds/01.jpg";
import icoPickupMovil from "assets/icons/pickup_movil1.png";
import axios from "axios";
import { addYears, addDays, addMinutes } from "date-fns";
import "./styles.css";

class PickUpSeguimiento extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMarker: null,
      //center: { lat: -34.6134019, lng: -58.4993903 },
      center: { lat: "", lng: "" },
      user: null,
      zoom: 12,
      address: "",
      showModal: false,
      modalMessage: "",
      agencies: [],
      token: sessionStorage.getItem("token"),
      feriado: 0,
      dataPosicionamiento: {},
      paradas: ""
    };
  }

  componentDidMount() {
    this._getPuntoRecoleccion(this.state.token);
    this._getCentro(this.state.token);
  }

  _getPuntoRecoleccion = token => {
    let buscarParadas = process.env.REACT_APP_API_URL + `/api/buscar_paradas`;

    var config = {
      method: "get",
      maxBodyLength: Infinity,
      //url: "https://ws.viatesting.com.ar/enc/paradasmovil/search",
      url: buscarParadas,

      headers: {
        "X-API-KEY": token
      }
    };

    axios(config)
      .then(response => {
        if (response.data.length > 0) {
          this.setState({
            paradas: response.data
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  _getCentro = token => {
    let posicionamiento =
      process.env.REACT_APP_API_URL + `/api/posicionamiento`;

    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: posicionamiento,

      headers: {
        "X-API-KEY": token
      }
    };

    axios(config)
      .then(response => {
        // console.log("getPosicionamiento: " + JSON.stringify(response.data));
        let positionlonlatserv = {
          lat: response.data.Posicionamiento.LATITUD,
          lng: response.data.Posicionamiento.LONGITUD
        };
        this.setState({
          dataPosicionamiento: response.data,
          center: positionlonlatserv
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    // let activeTab = this.state.options.find(option => option.active);
    // console.log("Ejecuta render");

    return (
      <section className="screen-contents">
        <div className="background">
          <img src={background} alt="Fondo" />
        </div>
        <div className="details-container container-pickup">
          <div className="content">
            <div class="row">
              <div class="col-md-9">
                <div class="content-header">
                  <div className="content-title">
                    <h1>Servicio Pickup móvil</h1>
                    <div>
                      <img
                        src={icoPickupMovil}
                        alt="Pickup Movil"
                        className="logoico"
                      />
                    </div>
                  </div>
                  <p class="content-text">Seguí el recorrido en tiempo real.</p>
                </div>

                <div class="content-bread">
                  <p>Datos de las paradas</p>
                </div>
                <div class="container-table">
                  <table>
                    <thead>
                      <tr>
                        <th className="th-left">PARADA</th>
                        <th className="th-left">DIRECCIÓN</th>
                        <th>HORARIO DESDE</th>
                        <th>HORARIO HASTA</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.paradas &&
                        this.state.dataPosicionamiento.JornadaContinua == 1 &&
                        this.state.paradas.map(paradas => {
                          return (
                            <tr>
                              <td class="special-border">
                                {paradas.Descripcion}
                              </td>
                              <td class="special-border">
                                {paradas.Direccion}
                              </td>
                              <td class="special-border1">
                                {paradas.HorarioDesde}
                              </td>
                              <td class="special-border2">
                                {paradas.HorarioHasta}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {/* <p class="avisos">
                    Servicio disponible días hábiles de Lunes a Viernes.
                  </p> */}
                </div>
              </div>
              <div class="col-md-3">
                <Sidebar />
              </div>
            </div>

            <div class="content-bread">
              <p>Ubicación en tiempo real</p>
            </div>

            {this.state.paradas && (
              <MapSeguimiento
                stops={this.state.paradas}
                centro={this.state.center}
              />
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default PickUpSeguimiento;
