import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import { Carousel as CarouselReact } from "react-responsive-carousel";

import Button from "shared/components/Button";
import agencies from "assets/icons/agencias1.png";
import cotizar from "assets/icons/cotizar.png";
import tracking from "assets/icons/trackear.png";
import envio from "assets/icons/generar_envios.jpg";
import arrow from "assets/icons/flecha.svg";
// import photo1 from "../../../../../../assets/main/carousel/pic1.jpg";
// import photo2 from "../../../../../../assets/main/carousel/pic2.jpg";
// import photo3 from "../../../../../../assets/main/carousel/pic3.jpg";
import photo1 from "../../../../../../assets/main/carousel/2023-02-09-slider-2.jpg";
import photo2 from "../../../../../../assets/main/carousel/2023-02-09-slider-3.jpg";
import photo3 from "../../../../../../assets/main/carousel/2023-02-09-slider-4.jpg";
import photo4 from "../../../../../../assets/main/carousel/2023-02-09-slider-1.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles.css";

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: ""
    };
  }

  _handleInput = ev => {
    let value = ev.target.value;

    if (value.length > 12) {
      return false;
    }

    this.setState({ code: value });
  };

  _submitSearch = ev => {
    ev.preventDefault();

    let { code } = this.state;
    this.props.history.push(`/tracking${code !== "" ? `/${code}` : ""}`);
  };

  render() {
    let { code } = this.state;

    return (
      <div style={{ position: "relative" }}>
        <div className="sidebar-carousel">
          <div className="sidebar-title">
            <h1>Envíos</h1>
          </div>
          <Link to={"/tracking"}>
            <div className="side-item">
              <img className="side-img" src={tracking} alt="Tracking" />
              <span className="side-title">Seguimiento de tu envío</span>
              {/* <img className="side-img arrow" src={arrow} alt="Next" /> */}
            </div>
          </Link>

          <div className="side-item separator">
            <form onSubmit={this._submitSearch}>
              <input
                type="text"
                value={code}
                onChange={this._handleInput}
                className="tracking-input"
                placeholder="número de seguimiento"
              />
              <button type={"submit"} style={{ display: "none" }} />
            </form>
          </div>
          {/* 
          <Link to={"/agencies"}>
            <div className="side-item separator">
              <img className="side-img" src={agencies} alt="Tracking" />
              <span className="side-title">Agencias</span>
              <img className="side-img arrow" src={arrow} alt="Next" />
            </div>
          </Link>

          <Link to={"/quote"}>
            <div className="side-item separator">
              <img className="side-img" src={cotizar} alt="Tracking" />
              <span className="side-title">Cotizar Envíos</span>
              <img className="side-img arrow" src={arrow} alt="Next" />
            </div>
          </Link>

          <Link to={"/pickup-home"}>
            <div className="side-item separator">
              <img className="side-img" src={envio} alt="Generar Envíos" />
              <span className="side-title">Generar Envíos</span>
              <img className="side-img arrow" src={arrow} alt="Next" />
            </div>
          </Link> */}
        </div>
        <CarouselReact
          autoPlay={true}
          infiniteLoop={true}
          showArrows={true}
          showStatus={false}
          showThumbs={false}
          interval={4000}
        >
          <div className={"slide-div-container"}>
            <img alt="legend 3" src={photo1} className={"carousel-img"} />
            <div className="legend">
              <div className="carousel-content">
                <h1 className="carousel-title">
                  Mayor
                  <br />
                  cobertura
                </h1>
                <div className="carousel-info">
                  Enviá lo que quieras
                  <br />y a donde quieras
                </div>
                <div>
                  <Link to="/agencies">
                    <Button className="button-carousel">Saber más</Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className={"slide-div-container"}>
            <img alt="legend 4" src={photo2} className={"carousel-img"} />
            <div className="legend">
              <div className="carousel-content">
                <h5 className="carousel-title">
                  Elegí, <br />
                  lo pedís, lo tenés
                </h5>
                <div className="carousel-info">
                  Llegó Via Compras,
                  <br />
                  tu nueva tienda online!
                </div>
                <div>
                  <Link
                    to={{ pathname: "https://www.viacompras.com.ar/" }}
                    target="_blank"
                  >
                    <Button className="button-carousel">Comprar</Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className={"slide-div-container"}>
            <img alt="legend 1" src={photo3} className={"carousel-img"} />
            <div className="legend">
              <div className="carousel-content">
                <h1 className="carousel-title">
                  A todo
                  <br />
                  el país
                </h1>
                <div className="carousel-info">
                  La vía más rápida y segura
                  <br />
                  para hacer tus envíos
                </div>
                <div>
                  <Link to="/quote">
                    <Button className="button-carousel">Saber más</Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className={"slide-div-container"}>
            <img alt="legend 2" src={photo4} className={"carousel-img"} />
            <div className="legend">
              <div className="carousel-content">
                <h1 className="carousel-title">
                  Vía
                  <br />
                  Automática
                </h1>
                <div className="carousel-info">
                  La vía más rápida y segura
                  <br />
                  para hacer tus envíos
                </div>
                <div>
                  <Link to="/products">
                    <Button className="button-carousel">Saber más</Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </CarouselReact>
      </div>
    );
  }
}

export default withRouter(Carousel);
