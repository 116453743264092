import React, { Component } from "react";

import Geosuggest from "react-geosuggest";
import Modal from "simple-react-modal";
import Leaflet from "leaflet";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";

import Sidebar from "../../../../shared/components/Sidebar/Sidebar";
import Button from "../../../../shared/components/Button";
import background from "../../../../assets/backgrounds/01.jpg";
import "./style.css";
import { helpers } from "../../../../utils/helpers";
import pinBig from "../../../../assets/icons/pin.png";
import icon from "../../../../assets/my_location_marker.png";
import pin from "../../../../assets/icons/pin_small.png";
//import pin from "../../../../assets/icons/pin_bandera.png";
import axios from "axios";

import Select from "react-select";

import Autosuggest from "react-autosuggest";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function getSuggestions(value, opciones) {
  const escapedValue = escapeRegexCharacters(value.trim());

  if (escapedValue === "") {
    return [];
  }

  const regex = new RegExp("\\b" + escapedValue, "i");

  return opciones.filter(person => regex.test(getSuggestionValue(person)));
}

function getSuggestionValue(suggestion) {
  return `${suggestion.Localidad} - ${suggestion.Provincia}`;
}

function renderSuggestion(suggestion, { query }) {
  const suggestionText = `${suggestion.Localidad} - ${suggestion.Provincia}`;
  const matches = AutosuggestHighlightMatch(suggestionText, query);
  const parts = AutosuggestHighlightParse(suggestionText, matches);

  return (
    <span className={"suggestion-content "}>
      <span className="name">
        {parts.map((part, index) => {
          const className = part.highlight ? "highlight" : null;

          return (
            <span className={className} key={index}>
              {part.text}
            </span>
          );
        })}
      </span>
    </span>
  );
}

class Agencies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMarker: null,
      center: { lat: -34.6134019, lng: -58.4993903 },
      user: null,
      zoom: 5,
      address: "",
      showModal: false,
      modalMessage: "",
      agencies: [],
      localidad_provincia: "", //process.env.WS_USER
      options: [],
      llave: null,
      isLoading: true,
      lista: false,
      titulo: "Ver lista de agencias",
      value: "",
      suggestions: [],
      opciones: [
        {
          Localidad: "carlos",
          Provincia: "azuaje",
          twitter: "dancounsell"
        },
        {
          Localidad: "enrique",
          Provincia: "Arias",
          twitter: "mtnmissy"
        },
        {
          Localidad: "Chloe",
          Provincia: "Jones",
          twitter: "ladylexy"
        },
        {
          Localidad: "Cooper",
          Provincia: "King",
          twitter: "steveodom"
        }
      ],
      xhr: false, // Request in process
      data: null,
      showModal: false,
      errorMessage: ""
    };
  }

  componentDidMount() {
    this._getLocation();
    //this._getKey();
    //this._searchAgencies();
    this._getAgencies();
  }

  replaceDashesWithCommas(value) {
    value = value || "";
    return value.replace(/-/g, ",");
  }

  _getKey = () => {
    //const llave = "D486BAB2-3072-4280-95F7-C8A40C2E9E95"
    const url = "https://ws.busplus.com.ar/sesion/";
    const data = {
      clave_acceso: "7yujs66hkdop",
      usuario: "BUSKO Virtual Charge",
      usuario_clave: "1WqJafs48"
    };
    const headers = {
      //Service_Key: config.credentials.service_key,
      "Content-Type": "application/json; charset=utf8"
    };

    axios
      .post(url, data, { headers })
      .then(response => {
        this.setState({ llave: response.data.key });
        this._getAgencies();
      })
      .catch(e => {
        console.log(e);
      });
  };

  _getAgencies = () => {
    let agenciesUrl = process.env.REACT_APP_API_URL + `/api/agencies`;
    axios
      .get(agenciesUrl, "data")
      .then(response => {
        let { data } = response;
        let opciones = [{ value: null, label: "TODAS LAS AGENCIAS" }];
        data.map(agency => {
          opciones.push({
            Localidad:
              this.replaceDashesWithCommas(agency.RazonSocial) +
              " - " +
              this.replaceDashesWithCommas(agency.Direccion) +
              " - " +
              this.replaceDashesWithCommas(agency.Localidad),
            Provincia: agency.Provincia,
            key: agency.IdAgencia
          });
        });

        this.setState((state, props) => ({
          agencies: data,
          options: opciones
        }));
        this.setState({ isLoading: false });
        this.state.agencies.map(agency => {
          if ("San Fernando" == agency.Localidad) {
            console.log(agency);
          }
        });
      })
      .catch(e => {
        this.setState({
          xhr: false,
          showModal: true,
          errorMessage: "server-error"
        });
        console.log(e);
      });
  };

  _searchAgencies = () => {
    let { xhr } = this.state;

    if (xhr) return false;
    this.setState({ xhr: !xhr });

    let AGENCIES_SERVICE = process.env.REACT_APP_API_URL + `/api/agencies/`;

    axios
      .get(AGENCIES_SERVICE)
      .then(response => {
        this.setState({
          xhr: false,
          data: response.data
        });
      })
      .catch(e => {
        let errorMessage = "";
        if (e.response && e.response.status === 400) {
          errorMessage = "not-found";
        }

        this.setState({
          xhr: false,
          showModal: true,
          errorMessage
        });
      });
  };

  _getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.setState({
          center: {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          },
          user: {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          },
          zoom: 12
        });
      });
    }
  };

  _useMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        let { latitude, longitude } = position.coords;
        helpers.geocode
          .fromCoords(latitude, longitude)
          .then(response => {
            const address = response.results[0].formatted_address;
            this.setState({
              center: {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              },
              user: {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              },
              zoom: 12,
              address
            });
          })
          .catch(e => {
            console.log(e);
          });
      });
    }
  };

  _handleInput = (prop, value) => {
    this.setState({ [prop]: value });
  };

  seleccion = selectedOption => {
    this.setState(
      {
        selectedOption,
        localidad_provincia: selectedOption.value.split(" - ")[2]
      },
      () =>
        console.log(
          "selecciono: ",
          selectedOption.value + "--" + this.state.localidad_provincia
        )
    );
    this.setState({ localidad_provincia: selectedOption.value });
    this.state.agencies.map(agency => {
      if (
        selectedOption.value ==
        this.replaceDashesWithCommas(agency.RazonSocial) +
          " - " +
          this.replaceDashesWithCommas(agency.Direccion) +
          " - " +
          this.replaceDashesWithCommas(agency.Localidad)
      ) {
        if (agency.Latitud != null && agency.Longitud != null) {
          const seleccionado = [agency.Latitud, agency.Longitud];
          this.setState({ center: seleccionado });
        } else {
          alert("Agencia sin coordenadas Asignadas");
        }
      }
    });
  };
  _searchAddress = () => {
    let { address } = this.state;
    let searchAddress = address;
    if (searchAddress.toLowerCase().indexOf("argentina") === -1) {
      searchAddress += ", Argentina";
    }

    helpers.geocode
      .fromAddress(searchAddress)
      .then(response => {
        console.log(response);
        if (response.results.length > 0) {
          const { lat, lng } = response.results[0].geometry.location;
          this.setState({
            zoom: 12,
            center: { lat, lng }
          });
        } else {
          let msg = (
            <div>
              <p>La dirección ingresada es inexistente.</p>
              <p>Por favor, volvé a intentarlo.</p>
            </div>
          );
          this.setState({
            showModal: true,
            modalMessage: msg
          });
        }
      })
      .catch(() => {
        let msg = (
          <div>
            <p>Se ha producido un error en la búsqueda.</p>
            <p>Por favor, volvé a intentarlo.</p>
          </div>
        );
        this.setState({
          showModal: true,
          modalMessage: msg
        });
      });
  };

  listaAgencias = () => {
    this.setState({ lista: !this.state.lista });

    if (this.state.lista) {
      this.state.titulo = "Ver lista de agencias";
    } else {
      this.state.titulo = "Ver mapa";
    }
  };

  listaCompleta = () => {
    const par = true;
    const info = this.state.agencies.map(agency => {
      if (this.state.localidad_provincia == "") {
        if (agency.RazonSocial != "" && agency.RazonSocial != null) {
          return (
            <div className="agencia" key={agency.IdAgencia}>
              <div className="agencia-item">
                <h3>{agency.RazonSocial}</h3>
                <p className="direccion">{agency.Direccion}</p>
                <span>{agency.Localidad + "-" + agency.Provincia}</span>
                <p className="telefono">
                  {agency.Telefono != null && agency.Telefono.length > 1
                    ? "Teléfono: " + agency.Telefono
                    : ""}
                </p>
                <p className="telefono">
                  {agency.Horarios != null && agency.Horarios.length > 1
                    ? "Horarios: " + agency.Horarios
                    : ""}
                </p>
              </div>
              <hr></hr>
            </div>
          );
        }
      } else {
        if (
          this.state.localidad_provincia != "" &&
          this.state.localidad_provincia ==
            agency.Localidad + " - " + agency.Provincia
        ) {
          if (agency.RazonSocial != "" && agency.RazonSocial != null) {
            return (
              <div className="agencia" key={agency.IdAgencia}>
                <div className="agencia-item">
                  <h3>{agency.RazonSocial}</h3>
                  <p className="direccion">{agency.Direccion}</p>
                  <span>{agency.Localidad + "-" + agency.Provincia}</span>
                  <p className="telefono">
                    {agency.Telefono != null && agency.Telefono.length > 1
                      ? "Teléfono: " + agency.Telefono
                      : ""}
                  </p>
                  <p className="telefono">
                    {agency.Horarios != null && agency.Horarios.length > 1
                      ? "Horarios: " + agency.Horarios
                      : ""}
                  </p>
                </div>
                <hr></hr>
              </div>
            );
          } else {
            alert(
              "No se puede mostrar la información ya que no posee Razón social"
            );
          }
        }
      }
    });
    return <div className="lista_agencias">{info}</div>;
  };

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    });
  };

  onClick = () => {
    if (this.state.value == "" || this.state.value.split(" - ").length < 3) {
      this.setState({ localidad_provincia: "" });
      this.state.zoom = 5;
    } else {
      this.setState({
        localidad_provincia:
          this.state.value.split(" - ")[2] +
          " - " +
          this.state.value.split(" - ")[3]
      });
    }
    let seleccionado =
      this.state.value.split(" - ")[1] +
      " - " +
      this.state.value.split(" - ")[2] +
      " - " +
      this.state.value.split(" - ")[3];
    this.state.agencies.map(agency => {
      if (
        seleccionado ==
        this.replaceDashesWithCommas(agency.Direccion) +
          " - " +
          this.replaceDashesWithCommas(agency.Localidad) +
          " - " +
          this.replaceDashesWithCommas(agency.Provincia)
      ) {
        if (agency.Latitud != null && agency.Longitud != null) {
          seleccionado = [agency.Latitud, agency.Longitud];
          this.setState({ center: seleccionado });
          this.state.zoom = 15;
        } else {
          alert("Agencia sin coordenadas Asignadas");
        }
      }
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value, this.state.options)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    let { data, errorMessage } = this.state;

    let modalMessage = "";
    if (errorMessage === "not-found") {
      modalMessage = (
        <div>
          <p>No se ha podido encontrar el código de seguimiento ingresado.</p>
          <p>Por favor, volvé a intentarlo</p>
        </div>
      );
    } else {
      modalMessage = (
        <div>
          <p>Hubo un problema de conexión con el sistema.</p>
          <p>Por favor, volvé a intentarlo</p>
        </div>
      );
    }

    let myIcon = Leaflet.icon({
      iconUrl: icon,
      popupAnchor: [10, 0]
    });

    let marker = Leaflet.icon({
      iconUrl: pin,
      popupAnchor: [0, -18],
      iconSize: [36, 36],
      iconAnchor: [18, 18]
    });

    const { isLoading } = this.state;
    if (isLoading) {
      return <div className="App">Loading...</div>;
    }

    const { agencies, localidad_provincia, titulo } = this.state;
    const InfoComponent = () => <div>{this.listaCompleta()} </div>;

    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "Localidad y/o Provincia",
      value,
      onChange: this.onChange
    };

    const MyComponent = ({ focus, placeHolder }) => (
      <Select
        options={this.state.options}
        autoFocus={focus}
        className="localidad_provincia"
        value={localidad_provincia}
        onChange={this.seleccion}
        placeholder={
          localidad_provincia == null
            ? "Localidad y/o Provincia"
            : localidad_provincia
        }
        menuShouldBlockScroll={true}
      />
    );

    const MostrarMapa = ({
      center,
      user,
      myIcon,
      marker,
      localidad_provincia,
      agencies
    }) => (
      <Map
        center={center}
        style={{ height: 700, marginTop: 50 }}
        zoom={this.state.zoom}
      >
        <TileLayer
          url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
          maxZoom={16}
          minZoom={2}
        />
        {user && user.lat && user.lng && (
          <Marker position={user} icon={myIcon} />
        )}

        <MarkerClusterGroup className="markercluster-map">
          {agencies &&
            agencies.map(agency => {
              if (localidad_provincia == "") {
                if (agency.Latitud != null && agency.Longitud != null)
                  return (
                    <div key={agency.key}>
                      <Marker
                        position={{ lat: agency.Latitud, lng: agency.Longitud }}
                        icon={marker}
                      >
                        <Popup>
                          <div className="info-box">
                            <h2>
                              {this.replaceDashesWithCommas(agency.RazonSocial)}
                            </h2>
                            <p>
                              {this.replaceDashesWithCommas(agency.Direccion)}
                            </p>
                            <p>
                              {this.replaceDashesWithCommas(agency.Localidad) +
                                " " +
                                +this.replaceDashesWithCommas(agency.Provincia)}
                            </p>
                            <p>{agency.Telefono}</p>
                            <p className="mb-2">{agency.Horarios}</p>
                          </div>
                        </Popup>
                      </Marker>
                    </div>
                  );
              } else {
                if (
                  localidad_provincia ==
                  agency.Localidad + " - " + agency.Provincia
                ) {
                  return (
                    <div key={agency.key}>
                      <Marker
                        position={{ lat: agency.Latitud, lng: agency.Longitud }}
                        icon={marker}
                      >
                        <Popup>
                          <div className="info-box">
                            <h2>
                              {this.replaceDashesWithCommas(agency.RazonSocial)}
                            </h2>
                            <p>
                              {this.replaceDashesWithCommas(agency.Direccion)}
                            </p>
                            <p>
                              {this.replaceDashesWithCommas(agency.Localidad) +
                                " " +
                                +this.replaceDashesWithCommas(agency.Provincia)}
                            </p>
                            <p>{agency.Telefono}</p>
                            <p className="mb-2">{agency.Horarios}</p>
                          </div>
                        </Popup>
                      </Marker>
                    </div>
                  );
                }
              }
            })}
        </MarkerClusterGroup>
      </Map>
    );
    if (this.state.agencies.length > 0) {
      return (
        <section className="screen-contents agencies-screen">
          <div className="background">
            <img src={background} alt="Fondo" />
          </div>
          <div className="details-container">
            <div className="content">
              <div className="content-title">
                <h1>Agencias {localidad_provincia}</h1>
              </div>
              <div className="content-title">
                <button
                  className="boton_agencias"
                  onClick={() => this.listaAgencias()}
                >
                  {titulo}
                </button>
              </div>
              {/* <MyComponent focus={false} placeHolder={localidad_provincia}/>*/}
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
              />

              <button
                className="boton_ver"
                style={{ height: 30, display: "inline-block" }}
                onClick={() => this.onClick()}
              >
                {this.state.value != "" ? "Ver" : "Ver todos"}
              </button>
              <div className="agencies-container">
                <div className={"agencies-form"}>
                  <div className={"agencies-form-title"}>
                    Encontrá la Agencia más conveniente para realizar tus
                    envíos, verificá los horarios y cómo llegar.
                  </div>
                </div>
                {agencies.length > 0 && !this.state.lista && (
                  <MostrarMapa
                    center={this.state.center}
                    user={this.state.user}
                    myIcon={myIcon}
                    marker={marker}
                    agencies={this.state.agencies}
                    localidad_provincia={this.state.localidad_provincia}
                  />
                )}

                {this.state.lista && <InfoComponent />}
              </div>
            </div>
            <Sidebar />
          </div>

          <Modal
            show={this.state.showModal}
            onClose={() => this.setState({ showModal: false })}
            className={"modal"}
            containerClassName={"modal-container"}
          >
            <div className={"close-button"}>
              <i
                className={"material-icons"}
                onClick={() => this.setState({ showModal: false })}
              >
                Cerrar
              </i>
            </div>
            <div className={"modal-body"}>
              {modalMessage}
              <div>
                <Button
                  className="quote"
                  onClick={() => this.setState({ showModal: false })}
                >
                  Encontrados
                </Button>
              </div>
            </div>
          </Modal>
        </section>
      );
    }
  }
}

export default Agencies;
