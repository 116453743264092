import React, { Component } from "react";

import YouTube from "react-youtube";
import Modal from "simple-react-modal";

import play from "../../../../../assets/play.png";
import axios from "axios";
import loading from "../../../../../assets/loading.gif";

class News extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      youtube: "CY02iIvAVs8",
      news: [],
      xhr: false
    };
  }

  componentDidMount() {
    this._getNews();
  }

  _getNews = () => {
    this.setState({ xhr: true });
    const newsUrl = "files/news.json";
    axios
      .get(newsUrl)
      .then(response => {
        this.setState({
          news: response.data,
          xhr: false
        });
      })
      .catch(e => {
        console.log(e);
        this.setState({ xhr: false });
      });
  };

  render() {
    const opts = {
      height: "390",
      width: "640"
    };

    const { news, xhr } = this.state;

    return (
      <div style={{ paddingTop: 40 }}>
        {xhr ? (
          <img src={loading} alt={"Cargando"} className={"spinner"} />
        ) : (
          news &&
          news.map((each, i) => {
            return (
              <div
                key={each.id}
                className={`news-item ${i > 0 ? "bordered" : ""}`}
              >
                <div
                  className={"media-container"}
                  onClick={() => {
                    if (each.video)
                      this.setState({ youtube: each.video, showModal: true });
                  }}
                >
                  <img src={`/files/images/${each.image}`} alt={each.item} />
                  {each.video && (
                    <div className={"play-button"}>
                      <img src={play} alt={"reproducir"} />
                    </div>
                  )}
                </div>
                <div className={"text-container"}>
                  <h2>{each.title}</h2>
                  <div className={"news-text"}>{each.text}</div>
                </div>
              </div>
            );
          })
        )}

        <Modal
          show={this.state.showModal}
          onClose={() => this.setState({ showModal: false })}
          className={"modal"}
          containerClassName={"modal-container"}
        >
          <div className={"close-button"}>
            <i
              className={"material-icons"}
              onClick={() => this.setState({ showModal: false })}
            >
              close
            </i>
          </div>
          <div className={"modal-body"}>
            {this.state.youtube && (
              <YouTube videoId={this.state.youtube} opts={opts} />
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export default News;
