import React from "react";
import { Link } from "react-router-dom";
import SummaryItem from "./SummaryItem";
// import contacto from "assets/summary/contacto.png";
// import cotizar from "assets/summary/cotizar.png";
// import packs from "assets/summary/packs.png";
// import rastreo from "assets/summary/rastreo.png";
// import agencias from "assets/summary/agencias.png";
//import pickup_movil from "assets/summary/pickup_movil.png";
//import retiro_domicilio from "assets/summary/pickup_web.png";
// import entrega_agencia from "assets/summary/entrega_agencia.png";

import contacto from "assets/summary/new-atencion-al-cliente.png";
import cotizar from "assets/summary/new-cotiza-tu-envio.png";
import packs from "assets/summary/new-tips-de-empaquetado.png";
import rastreo from "assets/summary/new-seguimiento-de-tu-envio.png";
import agencias from "assets/summary/new-buscador-de-agencias.png";
import pickup_movil from "assets/summary/new-pickup-movil.png";
import retiro_domicilio from "assets/summary/new-retiro-en-domicilio.png";
import entrega_agencia from "assets/summary/new-entrega-en-agencia.png";

import "./styles.css";

const textRetiroWeb =
  "Servicio de retiro a domicilio con entrega en todo el país hasta 50kg.";
const textPickupMovil =
  // "Servicio de despacho de puntos de la Ciudad de Buenos Aires, a todo el país, hasta 15kg.";
  "¡Servicio temporalmente suspendido! Continúa enviando con nosotros a través de Retiro en Domicilio o Entrega en Agencia.";
const textEntregaAgencia =
  "Ahorra tiempo. Contratá online y despachá en agencia.";

const SummaryBar = () => (
  <section className="summary-bar">
    {/* <Link to="/product/pm"> */}
    {/* <Link to="/product/pm"> */}
    <SummaryItem
      icon={pickup_movil}
      // title="Pickup Movil"
      text={textPickupMovil}
    />
    {/* </Link> */}
    <Link to="/product/rd">
      <SummaryItem
        icon={retiro_domicilio}
        // title="Retiro en domicilio"
        text={textRetiroWeb}
      />
    </Link>
    <Link to="/product/ea">
      <SummaryItem
        icon={entrega_agencia}
        // title="Entrega en Agencia"
        text={textEntregaAgencia}
      />
    </Link>
    <Link to="/tracking">
      <SummaryItem
        icon={rastreo}
        // title="Seguimiento de tu envío"
        text=""
      />
    </Link>
    <Link to="/agencies">
      <SummaryItem
        icon={agencias}
        // title="Buscador de Agencias"
        text=""
      />
    </Link>
    <Link to="/tips">
      <SummaryItem
        icon={packs}
        // title="Tips de empaquetado"
        text=""
      />
    </Link>
    <Link to="/quote">
      <SummaryItem
        icon={cotizar}
        // title="Cotizá tu envio"
        text=""
      />
    </Link>
    <Link to="/support">
      {/* <SummaryItem icon={contacto} title="Contacto" text="" /> */}
      <SummaryItem
        icon={contacto}
        // title="Atención al cliente"
        text=""
      />
    </Link>
  </section>
);

export default SummaryBar;
