import React from "react";
import { any, node, string } from "prop-types";
import "./styles.css";

const Iframe = ({ source, id = "", title = "" }) => (
  <div className="">
    {/*<div className="emdeb-responsive">
            <iframe id={id} title={title} src={source} width="100%" height="750px"></iframe >
        </div>*/}
    <div>
      <p>Pulsa el botón para abrir el cotizador</p>
      <button
        onClick={() =>
          window.open("https://gtsviacargo.alertran.net/gts/pub/cotiza.seam")
        }
      >
        Cotizar
      </button>
    </div>
    <div className={"quote-disclaimer"}>
      <p>
        Los valores de cotización son únicamente informativos y están sujetos a
        variaciones según cargo por manejo, peso y/o medida reales registradas
        en el momento de la venta. El valor del servicio contraentrega tiene un
        costo adicional que no está contemplado en esta cotización. El valor del
        envío puede variar en el momento de la entrega en el punto de venta.
      </p>
      {/* <p>
        La prima de reembolso a pagar, es del 2,5% del valor declarado. En todos
        los casos el acondicionamiento del embalaje de las piezas es
        responsabilidad del remitente.
      </p> */}
      <p>
        Los campos con * son obligatorios. El límite de peso de la cotización es
        de 100KG por caja o paquete.
        <br />
        <b>
          PARA CARGA SUPERIORES A 100KG por favor, comunicate con tu agencia más
          cercana. Ver iformación de agencias:&nbsp;
          <a href="https://viacargo.com.ar/agencies">
            https://viacargo.com.ar/agencies
          </a>
        </b>
      </p>
    </div>
  </div>
);

Iframe.propTypes = {
  source: string
};

export default Iframe;
