import React, { Component, useState, render, useEffect } from "react";
import { Button, InputGroup, Form, Col } from "react-bootstrap";
import axios from "axios";

import "./../styles.css";

function TiposServicios(props) {
  const [tipoServicio, setTipoServicio] = useState(0);
  const [active, setActive] = useState("");
  const [IdCotizacion, setIdCotizacion] = useState(0);
  const [DescripcionServicio, setDescripcionServicio] = useState("");
  const [CodigoServicio, setCodigoServicio] = useState(0);
  const [TotalServicio, setTotalServicio] = useState(0);
  const [TipoEntrega, setTipoEntrega] = useState(0);

  const [xhr, setXhr] = useState("");
  const [showModal, setShowModal] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [modalMessage, setModalMessage] = useState("");

  const [boton, setBoton] = useState(false);

  const [token, setToken] = useState(sessionStorage.getItem("token"));

  let servicios = {};

  useEffect(() => {}, [tipoServicio]);

  if (props.tiposServicios) {
    servicios = props.tiposServicios;
  }

  let key = 0;
  let descripcion = "";
  let tiempo = "";
  let imagen = "";
  let total = "";
  let titulo = "";

  const asignarParametros = (
    Id,
    DescripcionServicio,
    Codigo,
    TipoEntrega,
    Total
  ) => {
    if (props.codigoProducto == "pm") {
      Total = 0;
    }
    let valores = {
      Id: Id,
      DescripcionServicio: DescripcionServicio,
      Codigo: Codigo,
      TipoEntrega: TipoEntrega,
      Total: Total
    };

    let campos = {
      IdCotizacion: Id,
      CodigoServicio: Codigo,
      ImporteTotal: Total
    };

    let cotizarPut = process.env.REACT_APP_API_URL + `/api/cotizar`;
    //console.log("TiposServicios cotizarPut request: " + JSON.stringify(campos));
    axios
      .put(cotizarPut, campos, { params: { token: token } })
      .then(response => {
        let { data } = response;
        // console.log(
        //   "TiposServicios cotizarPut response: " + JSON.stringify(data)
        // );

        /*Tarea GDM-1031 - Inicio */
        let datosCotizacion = JSON.parse(
          window.localStorage.getItem("datosRemitente")
        );
        datosCotizacion.IdEnvio = data.IdCotizacion;
        datosCotizacion.CodigoServicio = Codigo;
        datosCotizacion.ImporteReembolso = 100.0; /*COMENTAR PARA DEBUG GDM-1031 */
        if (props.codigoProducto == "pm") {
          datosCotizacion.Interfaz = 6;
          //datosCotizacion.Retiro = 'N';//No aplica en pickup movil
        }
        if (props.codigoProducto == "ea") {
          datosCotizacion.Interfaz = 9;
          datosCotizacion.Retiro = 'N';
        }
        if (props.codigoProducto == "rd") {
          datosCotizacion.Interfaz = 1;
        }
        window.localStorage.setItem(
          "datosRemitente",
          JSON.stringify(datosCotizacion)
        );
        /*Tarea GDM-1031 - Fin */
        ///setFormulario(data.data.Pago)
        //variable = "/pickup-formulario/info="+JSON.stringify(data.data.Pago)

        ///setUrl("/pickup-formulario/"+encodeURIComponent( JSON.stringify(response.data.data.Pago)))
        if (data.Estado) {
          props.parametroSegundoForm(valores);
        }
      })
      .catch(e => {
        setXhr(false);
        setShowModal(true);
        setErrorMessage("server-error: " + e);
        console.log(e);
      });
  };

  const handleSubmit = () => {
    //const selectedService = services.find(s => s.selected);

    props.tercerForm();
  };

  const atrasForm1 = () => {
    //const selectedService = services.find(s => s.selected);

    props.atrasForm1();
  };

  if (
    servicios.Cotizacion &&
    servicios.Cotizacion[0].PRODUCTO_CODIGO !== undefined
  ) {
    // console.log("tipos servicios: " + servicios.Cotizacion);
    return (
      <div>
        <p>Seleccione el tipo de operación</p>
        <div className="row">
          {/* Retiro en Domicilio */}
          {servicios.Cotizacion &&
            props.codigoProducto == "rd" &&
            servicios.Cotizacion.map(servicio => {
              if (servicio.PRODUCTO_CODIGO == "0001") {
                //0001 : Retiro a domicilio / Entrega a Domicilio. rd
                descripcion = servicio.PRODUCTO_DESCRIPCION;
                tiempo = servicio.TIEMPO_ENTREGA;
                imagen = "/images/rd_basica.png";
                total = servicio.TOTAL;
                //titulo = "Básica";
              } else if (servicio.PRODUCTO_CODIGO == "3000") {
                //3000: Retiro a Domicilio/ Agencia
                descripcion = servicio.PRODUCTO_DESCRIPCION;
                tiempo = servicio.TIEMPO_ENTREGA;
                imagen = "/images/rd_estandar.png";
                total = servicio.TOTAL;
                //titulo = "Estandar";
              } else if (servicio.PRODUCTO_CODIGO == "1005") {
                //1005: Retiro a Domicilio/ Agencia
                descripcion = servicio.PRODUCTO_DESCRIPCION;
                tiempo = servicio.TIEMPO_ENTREGA;
                imagen = "/images/rd_basica.png";
                total = servicio.TOTAL;
                //titulo = "Estandar";
              }else{
                //1005: Retiro a Domicilio/ Agencia
                descripcion = servicio.PRODUCTO_DESCRIPCION;
                tiempo = servicio.TIEMPO_ENTREGA;
                imagen = "/images/rd_basica.png";
                total = servicio.TOTAL;
                //titulo = "Estandar";
              }

              if (servicio.PRODUCTO_CODIGO == "0001")
                return (
                  <div key={key++} className="card text-center col-md-6">
                    <div className="card-titulo">{titulo}</div>
                    <div className="card-body">
                      <img
                        src={imagen}
                        className="img-fluid"
                        alt="Placeholder image"
                      />
                      <br />
                      <br />
                      <h5 className="card-title">{descripcion}</h5>
                      <br />
                      <h3>${total}</h3>
                      <br />

                      <p className="card-text">
                        Servicio de retiro a domicilio y entrega en otro
                        domicilio, hasta 50kg.
                      </p>
                      <br />
                      <p className="card-text">
                        La colecta será realizada al día siguiente de tu pedido.
                        <br />
                        De Lunes a Viernes, el horario de la colecta es entre
                        las 08 y las 18hs. Sábados entre las 08 y las 14hs.
                        Domingos, no se realizan colectas.
                      </p>
                      {/* <hr className="my-4" /> */}
                      {/* <p className="card-text">Tiempo de entrega: {tiempo}</p> */}
                      {/* <h3>${total}</h3> */}

                      <input
                        type="button"
                        className={
                          active === servicio.PRODUCTO_CODIGO
                            ? "active btn"
                            : "btn"
                        }
                        value="Seleccionar"
                        onClick={() => {
                          ///console.log(servicios)
                          ///console.log(servicios.IdCotizacion,servicio.PRODUCTO_CODIGO,servicio.TOTAL)
                          setActive(servicio.PRODUCTO_CODIGO);
                          setIdCotizacion(servicios.IdCotizacion);
                          setCodigoServicio(servicio.PRODUCTO_CODIGO);
                          setDescripcionServicio(servicio.PRODUCTO_DESCRIPCION);
                          setTipoEntrega(titulo);
                          setTotalServicio(servicio.TOTAL);
                          asignarParametros(
                            servicios.IdCotizacion,
                            servicio.PRODUCTO_DESCRIPCION,
                            servicio.PRODUCTO_CODIGO,
                            titulo,
                            servicio.TOTAL
                          );
                        }}
                        key={key}
                      />
                    </div>
                  </div>
                );
              else if(servicio.PRODUCTO_CODIGO == "1005")
                return (
                  <div key={key++} className="card text-center col-md-6">
                    <div className="card-titulo">{titulo}</div>
                    <div className="card-body">
                      <img
                        src={imagen}
                        className="img-fluid"
                        alt="Placeholder image"
                      />
                      <br />
                      <br />
                      <h5 className="card-title">{descripcion}</h5>
                      <br />
                      <h3>${total}</h3>
                      <br />

                      <p className="card-text">
                        Servicio de retiro a domicilio y entrega en otro
                        domicilio, hasta 50kg.
                      </p>
                      <br />
                      <p className="card-text">
                        La colecta será realizada al día siguiente de tu pedido.
                        <br />
                        De Lunes a Viernes, el horario de la colecta es entre
                        las 08 y las 18hs. Sábados entre las 08 y las 14hs.
                        Domingos, no se realizan colectas.
                      </p>
                      {/* <hr className="my-4" /> */}
                      {/* <p className="card-text">Tiempo de entrega: {tiempo}</p> */}
                      {/* <h3>${total}</h3> */}

                      <input
                        type="button"
                        className={
                          active === servicio.PRODUCTO_CODIGO
                            ? "active btn"
                            : "btn"
                        }
                        value="Seleccionar"
                        onClick={() => {
                          ///console.log(servicios)
                          ///console.log(servicios.IdCotizacion,servicio.PRODUCTO_CODIGO,servicio.TOTAL)
                          setActive(servicio.PRODUCTO_CODIGO);
                          setIdCotizacion(servicios.IdCotizacion);
                          setCodigoServicio(servicio.PRODUCTO_CODIGO);
                          setDescripcionServicio(servicio.PRODUCTO_DESCRIPCION);
                          setTipoEntrega(titulo);
                          setTotalServicio(servicio.TOTAL);
                          asignarParametros(
                            servicios.IdCotizacion,
                            servicio.PRODUCTO_DESCRIPCION,
                            servicio.PRODUCTO_CODIGO,
                            titulo,
                            servicio.TOTAL
                          );
                        }}
                        key={key}
                      />
                    </div>
                  </div>
                );
              else if (servicio.PRODUCTO_CODIGO == "3000")
                return (
                  <div key={key++} className="card text-center col-md-6">
                    <div className="card-titulo">{titulo}</div>
                    <div className="card-body">
                      <img
                        src={imagen}
                        className="img-fluid"
                        alt="Placeholder image"
                      />
                      <br />
                      <br />
                      <h5 className="card-title">{descripcion}</h5>
                      <br />
                      <h3>${total}</h3>
                      <br />

                      <p className="card-text text-dark">
                        Servicio de retiro a domicilio y entrega en agencia,
                        hasta 50kg.
                      </p>
                      <br />
                      <p className="card-text">
                        La colecta será realizada al día siguiente de tu pedido.
                        <br />
                        De Lunes a Viernes, el horario de la colecta es entre
                        las 08 y las 18hs. Sábados entre las 08 y las 14hs.
                        Domingos, no se realizan colectas.
                      </p>
                      {/* <hr className="my-4" /> */}
                      {/* <p className="card-text">Tiempo de entrega: {tiempo}</p> */}
                      {/* <h3>${total}</h3> */}

                      <input
                        type="button"
                        className={
                          active === servicio.PRODUCTO_CODIGO
                            ? "active btn"
                            : "btn"
                        }
                        value="Seleccionar"
                        onClick={() => {
                          ///console.log(servicios)
                          ///console.log(servicios.IdCotizacion,servicio.PRODUCTO_CODIGO,servicio.TOTAL)
                          setActive(servicio.PRODUCTO_CODIGO);
                          setIdCotizacion(servicios.IdCotizacion);
                          setCodigoServicio(servicio.PRODUCTO_CODIGO);
                          setDescripcionServicio(servicio.PRODUCTO_DESCRIPCION);
                          setTipoEntrega(titulo);
                          setTotalServicio(servicio.TOTAL);
                          asignarParametros(
                            servicios.IdCotizacion,
                            servicio.PRODUCTO_DESCRIPCION,
                            servicio.PRODUCTO_CODIGO,
                            titulo,
                            servicio.TOTAL
                          );
                        }}
                        key={key}
                      />
                    </div>
                  </div>
                );
                else
                return (
                  <div key={key++} className="card text-center col-md-6">
                    <div className="card-titulo">{titulo}</div>
                    <div className="card-body">
                      <img
                        src={imagen}
                        className="img-fluid"
                        alt="Placeholder image"
                      />
                      <br />
                      <br />
                      <h5 className="card-title">{descripcion}</h5>
                      <br />
                      <h3>${total}</h3>
                      <br />

                      <p className="card-text">
                        Servicio de retiro a domicilio y entrega en otro
                        domicilio, hasta 50kg.
                      </p>
                      <br />
                      <p className="card-text">
                        La colecta será realizada al día siguiente de tu pedido.
                        <br />
                        De Lunes a Viernes, el horario de la colecta es entre
                        las 08 y las 18hs. Sábados entre las 08 y las 14hs.
                        Domingos, no se realizan colectas.
                      </p>
                      {/* <hr className="my-4" /> */}
                      {/* <p className="card-text">Tiempo de entrega: {tiempo}</p> */}
                      {/* <h3>${total}</h3> */}

                      <input
                        type="button"
                        className={
                          active === servicio.PRODUCTO_CODIGO
                            ? "active btn"
                            : "btn"
                        }
                        value="Seleccionar"
                        onClick={() => {
                          ///console.log(servicios)
                          ///console.log(servicios.IdCotizacion,servicio.PRODUCTO_CODIGO,servicio.TOTAL)
                          setActive(servicio.PRODUCTO_CODIGO);
                          setIdCotizacion(servicios.IdCotizacion);
                          setCodigoServicio(servicio.PRODUCTO_CODIGO);
                          setDescripcionServicio(servicio.PRODUCTO_DESCRIPCION);
                          setTipoEntrega(titulo);
                          setTotalServicio(servicio.TOTAL);
                          asignarParametros(
                            servicios.IdCotizacion,
                            servicio.PRODUCTO_DESCRIPCION,
                            servicio.PRODUCTO_CODIGO,
                            titulo,
                            servicio.TOTAL
                          );
                        }}
                        key={key}
                      />
                    </div>
                  </div>
                );
            })}

          {/* Entrega en Agencia */}
          {servicios.Cotizacion &&
            props.codigoProducto == "ea" &&
            servicios.Cotizacion.map(servicio => {
              if (servicio.PRODUCTO_CODIGO == "4000") {
                //4000: Agencia / Entrega a Domicilio pm
                descripcion = servicio.PRODUCTO_DESCRIPCION;
                tiempo = servicio.TIEMPO_ENTREGA;
                imagen = "/images/ea_basica.png";
                total = servicio.TOTAL;
                //titulo = "Basica";
              } else if (servicio.PRODUCTO_CODIGO == "2000") {
                // 2000: Agencia a Agencia pa
                descripcion = servicio.PRODUCTO_DESCRIPCION;
                tiempo = servicio.TIEMPO_ENTREGA;
                imagen = "/images/ea_estandar.png";
                total = servicio.TOTAL;
                //titulo = "Estandar";
              }

              if (servicio.PRODUCTO_CODIGO == "4000")
                return (
                  <div key={key++} className="card text-center col-md-6">
                    <div className="card-titulo">{titulo}</div>
                    <div className="card-body">
                      <img
                        src={imagen}
                        className="img-fluid"
                        alt="Placeholder image"
                      />
                      <br />
                      <br />
                      <h5 className="card-title">{descripcion}</h5>
                      <br />
                      <h3>${total}</h3>
                      <br />
                      <p className="card-text">
                        Ahorra tiempo! Precarga tu envío y despacha de forma
                        ágil y sencilla desde la agencia más cercana con entrega
                        en domicilio.
                      </p>
                      {/* <hr className="my-4" /> */}
                      {/* <p className="card-text">Tiempo de entrega: {tiempo}</p> */}
                      {/* <h3>${total}</h3> */}

                      <input
                        type="button"
                        className={
                          active === servicio.PRODUCTO_CODIGO
                            ? "active btn"
                            : "btn"
                        }
                        value="Seleccionar"
                        onClick={() => {
                          ///console.log(servicios)
                          ///console.log(servicios.IdCotizacion,servicio.PRODUCTO_CODIGO,servicio.TOTAL)
                          setActive(servicio.PRODUCTO_CODIGO);
                          setIdCotizacion(servicios.IdCotizacion);
                          setCodigoServicio(servicio.PRODUCTO_CODIGO);
                          setDescripcionServicio(servicio.PRODUCTO_DESCRIPCION);
                          setTipoEntrega(titulo);
                          setTotalServicio(servicio.TOTAL);
                          asignarParametros(
                            servicios.IdCotizacion,
                            servicio.PRODUCTO_DESCRIPCION,
                            servicio.PRODUCTO_CODIGO,
                            titulo,
                            servicio.TOTAL
                          );
                        }}
                        key={key}
                      />
                    </div>
                  </div>
                );
              else if (servicio.PRODUCTO_CODIGO == "2000")
                return (
                  <div key={key++} className="card text-center col-md-6">
                    <div className="card-titulo">{titulo}</div>
                    <div className="card-body">
                      <img
                        src={imagen}
                        className="img-fluid"
                        alt="Placeholder image"
                      />
                      <br />
                      <br />
                      <h5 className="card-title">{descripcion}</h5>
                      <br />
                      <h3>${total}</h3>
                      <br />
                      <p className="card-text">
                        Ahorra tiempo! Precarga tu envío y despacha de forma
                        ágil y sencilla desde la agencia más cercana con entrega
                        en otra agencia.
                      </p>
                      {/* <hr className="my-4" /> */}
                      {/* <p className="card-text">Tiempo de entrega: {tiempo}</p> */}
                      {/* <h3>${total}</h3> */}

                      <input
                        type="button"
                        className={
                          active === servicio.PRODUCTO_CODIGO
                            ? "active btn"
                            : "btn"
                        }
                        value="Seleccionar"
                        onClick={() => {
                          ///console.log(servicios)
                          ///console.log(servicios.IdCotizacion,servicio.PRODUCTO_CODIGO,servicio.TOTAL)
                          setActive(servicio.PRODUCTO_CODIGO);
                          setIdCotizacion(servicios.IdCotizacion);
                          setCodigoServicio(servicio.PRODUCTO_CODIGO);
                          setDescripcionServicio(servicio.PRODUCTO_DESCRIPCION);
                          setTipoEntrega(titulo);
                          setTotalServicio(servicio.TOTAL);
                          asignarParametros(
                            servicios.IdCotizacion,
                            servicio.PRODUCTO_DESCRIPCION,
                            servicio.PRODUCTO_CODIGO,
                            titulo,
                            servicio.TOTAL
                          );
                        }}
                        key={key}
                      />
                    </div>
                  </div>
                );
            })}

          {/* Pickup Movil */}
          {servicios.Cotizacion &&
            props.codigoProducto == "pm" &&
            servicios.Cotizacion.map(servicio => {
              //if (servicio.PRODUCTO_CODIGO == "8686") {
              if (servicio.PRODUCTO_CODIGO == "0001") {
                //8686: Movil / Entrega a Domicilio pm
                descripcion = "PICKUP DOMICILIO"; //servicio.PRODUCTO_DESCRIPCION;
                tiempo = servicio.TIEMPO_ENTREGA;
                imagen = "/images/pm_basica.png";
                total = servicio.TOTAL;
                //titulo = "Basica";
                //} else if (servicio.PRODUCTO_CODIGO == "8687") {
              } else if (servicio.PRODUCTO_CODIGO == "3000") {
                // 8686: Movil / Entrega en Agencia pm
                descripcion = "PICKUP AGENCIA"; //servicio.PRODUCTO_DESCRIPCION;
                tiempo = servicio.TIEMPO_ENTREGA;
                imagen = "/images/pm_estandar.png";
                total = servicio.TOTAL;
                //titulo = "Estandar";
              } else if (servicio.PRODUCTO_CODIGO == "1005") {
                // 8686: Movil / Entrega en Agencia pm
                descripcion = "PICKUP AGENCIA"; //servicio.PRODUCTO_DESCRIPCION;
                tiempo = servicio.TIEMPO_ENTREGA;
                imagen = "/images/pm_estandar.png";
                total = servicio.TOTAL;
                //titulo = "Estandar";
              }else {
                // 8686: Movil / Entrega en Agencia pm
                descripcion = "PICKUP AGENCIA"; //servicio.PRODUCTO_DESCRIPCION;
                tiempo = servicio.TIEMPO_ENTREGA;
                imagen = "/images/pm_estandar.png";
                total = servicio.TOTAL;
                //titulo = "Estandar";
              }

              //if (servicio.PRODUCTO_CODIGO == "8686")
              if (servicio.PRODUCTO_CODIGO == "0001")
                return (
                  <div key={key++} className="card text-center col-md-6">
                    <div className="card-titulo">{titulo}</div>
                    <div className="card-body">
                      <img
                        src={imagen}
                        className="img-fluid"
                        alt="Placeholder image"
                      />
                      <br />
                      <br />
                      <h5 className="card-title">{descripcion}</h5>
                      <br />
                      <h3>
                        <p>
                          <b className="card-no-price">${total}</b> $0
                        </p>
                        <p>
                          <b className="card-servicio-limitado">
                            Servicio bonificado por tiempo limitado
                          </b>
                        </p>
                      </h3>
                      <br />
                      <p className="card-text">
                        Servicio de despacho en puntos de la ciudad para entrega
                        en domicilio, hasta 15kg.
                      </p>
                      {/* <hr className="my-4" /> */}
                      {/* <p className="card-text">Tiempo de entrega: {tiempo}</p> */}
                      {/* <h3>${total}</h3> */}

                      <input
                        type="button"
                        className={
                          active === servicio.PRODUCTO_CODIGO
                            ? "active btn"
                            : "btn"
                        }
                        value="Seleccionar"
                        onClick={() => {
                          ///console.log(servicios)
                          ///console.log(servicios.IdCotizacion,servicio.PRODUCTO_CODIGO,servicio.TOTAL)
                          setActive(servicio.PRODUCTO_CODIGO);
                          setIdCotizacion(servicios.IdCotizacion);
                          setCodigoServicio(servicio.PRODUCTO_CODIGO);
                          setDescripcionServicio(servicio.PRODUCTO_DESCRIPCION);
                          setTipoEntrega(titulo);
                          //setTotalServicio(servicio.TOTAL);
                          setTotalServicio(0);
                          asignarParametros(
                            servicios.IdCotizacion,
                            servicio.PRODUCTO_DESCRIPCION,
                            servicio.PRODUCTO_CODIGO,
                            titulo,
                            servicio.TOTAL
                          );
                        }}
                        key={key}
                      />
                    </div>
                  </div>
                );
              //else if (servicio.PRODUCTO_CODIGO == "8687")
              else if (servicio.PRODUCTO_CODIGO == "3000")
                return (
                  <div key={key++} className="card text-center col-md-6">
                    <div className="card-titulo">{titulo}</div>
                    <div className="card-body">
                      <img
                        src={imagen}
                        className="img-fluid"
                        alt="Placeholder image"
                      />
                      <br />
                      <br />
                      <h5 className="card-title">{descripcion}</h5>
                      <br />
                      <h3>
                        <p>
                          <b className="card-no-price">${total}</b> $0
                        </p>
                        <p>
                          <b className="card-servicio-limitado">
                            Servicio bonificado por tiempo limitado
                          </b>
                        </p>
                      </h3>
                      <br />
                      <p className="card-text">
                        Servicio de despacho en puntos de la ciudad para entrega
                        en agencia, hasta 15kg.
                      </p>
                      {/* <hr className="my-4" /> */}
                      {/* <p className="card-text">Tiempo de entrega: {tiempo}</p> */}
                      {/* <h3>${total}</h3> */}

                      <input
                        type="button"
                        className={
                          active === servicio.PRODUCTO_CODIGO
                            ? "active btn"
                            : "btn"
                        }
                        value="Seleccionar"
                        onClick={() => {
                          ///console.log(servicios)
                          ///console.log(servicios.IdCotizacion,servicio.PRODUCTO_CODIGO,servicio.TOTAL)
                          setActive(servicio.PRODUCTO_CODIGO);
                          setIdCotizacion(servicios.IdCotizacion);
                          setCodigoServicio(servicio.PRODUCTO_CODIGO);
                          setDescripcionServicio(servicio.PRODUCTO_DESCRIPCION);
                          setTipoEntrega(titulo);
                          //setTotalServicio(servicio.TOTAL);
                          setTotalServicio(0);
                          asignarParametros(
                            servicios.IdCotizacion,
                            servicio.PRODUCTO_DESCRIPCION,
                            servicio.PRODUCTO_CODIGO,
                            titulo,
                            servicio.TOTAL
                          );
                        }}
                        key={key}
                      />
                    </div>
                  </div>
                );
                else if (servicio.PRODUCTO_CODIGO == "1005")
                  return (
                    <div key={key++} className="card text-center col-md-6">
                      <div className="card-titulo">{titulo}</div>
                      <div className="card-body">
                        <img
                          src={imagen}
                          className="img-fluid"
                          alt="Placeholder image"
                        />
                        <br />
                        <br />
                        <h5 className="card-title">{descripcion}</h5>
                        <br />
                        <h3>
                          <p>
                            <b className="card-no-price">${total}</b> $0
                          </p>
                          <p>
                            <b className="card-servicio-limitado">
                              Servicio bonificado por tiempo limitado
                            </b>
                          </p>
                        </h3>
                        <br />
                        <p className="card-text">
                          Servicio de despacho en puntos de la ciudad para entrega
                          en domicilio, hasta 15kg.
                        </p>
                        {/* <hr className="my-4" /> */}
                        {/* <p className="card-text">Tiempo de entrega: {tiempo}</p> */}
                        {/* <h3>${total}</h3> */}

                        <input
                          type="button"
                          className={
                            active === servicio.PRODUCTO_CODIGO
                              ? "active btn"
                              : "btn"
                          }
                          value="Seleccionar"
                          onClick={() => {
                            ///console.log(servicios)
                            ///console.log(servicios.IdCotizacion,servicio.PRODUCTO_CODIGO,servicio.TOTAL)
                            setActive(servicio.PRODUCTO_CODIGO);
                            setIdCotizacion(servicios.IdCotizacion);
                            setCodigoServicio(servicio.PRODUCTO_CODIGO);
                            setDescripcionServicio(servicio.PRODUCTO_DESCRIPCION);
                            setTipoEntrega(titulo);
                            //setTotalServicio(servicio.TOTAL);
                            setTotalServicio(0);
                            asignarParametros(
                              servicios.IdCotizacion,
                              servicio.PRODUCTO_DESCRIPCION,
                              servicio.PRODUCTO_CODIGO,
                              titulo,
                              servicio.TOTAL
                            );
                          }}
                          key={key}
                        />
                      </div>
                    </div>
                  );
                  else
                  return (
                    <div key={key++} className="card text-center col-md-6">
                      <div className="card-titulo">{titulo}</div>
                      <div className="card-body">
                        <img
                          src={imagen}
                          className="img-fluid"
                          alt="Placeholder image"
                        />
                        <br />
                        <br />
                        <h5 className="card-title">{descripcion}</h5>
                        <br />
                        <h3>
                          <p>
                            <b className="card-no-price">${total}</b> $0
                          </p>
                          <p>
                            <b className="card-servicio-limitado">
                              Servicio bonificado por tiempo limitado
                            </b>
                          </p>
                        </h3>
                        <br />
                        <p className="card-text">
                          Servicio de despacho en puntos de la ciudad para entrega
                          en domicilio, hasta 15kg.
                        </p>
                        {/* <hr className="my-4" /> */}
                        {/* <p className="card-text">Tiempo de entrega: {tiempo}</p> */}
                        {/* <h3>${total}</h3> */}

                        <input
                          type="button"
                          className={
                            active === servicio.PRODUCTO_CODIGO
                              ? "active btn"
                              : "btn"
                          }
                          value="Seleccionar"
                          onClick={() => {
                            ///console.log(servicios)
                            ///console.log(servicios.IdCotizacion,servicio.PRODUCTO_CODIGO,servicio.TOTAL)
                            setActive(servicio.PRODUCTO_CODIGO);
                            setIdCotizacion(servicios.IdCotizacion);
                            setCodigoServicio(servicio.PRODUCTO_CODIGO);
                            setDescripcionServicio(servicio.PRODUCTO_DESCRIPCION);
                            setTipoEntrega(titulo);
                            //setTotalServicio(servicio.TOTAL);
                            setTotalServicio(0);
                            asignarParametros(
                              servicios.IdCotizacion,
                              servicio.PRODUCTO_DESCRIPCION,
                              servicio.PRODUCTO_CODIGO,
                              titulo,
                              servicio.TOTAL
                            );
                          }}
                          key={key}
                        />
                      </div>
                    </div>
                  );
            })}

          {!descripcion && (
            <div>
              <p>
                No se encontro cotización para los códigos postales ingresados
              </p>
              <br />
              <Form.Row>
                <Col md={{ span: 3, offset: 10 }}>
                  <Button
                    variant="secondary"
                    className="h-auto"
                    onClick={atrasForm1}
                  >
                    Anterior
                  </Button>
                </Col>
              </Form.Row>
            </div>
          )}
        </div>
        {servicios.Cotizacion && descripcion && (
          <div>
            <br />
            <br />
            <p>
              Los valores de cotización son informativos y están sujetos a la
              veracidad de la información cargada en este formulario. La
              cotización final se realizará al momento del retiro.
            </p>
            <p>
              En todos los casos el acondicionamiento del{" "}
              <a href="/tips" target="_blank">
                embalaje de las piezas
              </a>{" "}
              es responsabilidad del remitente
            </p>
            {/* <p>
              **En todos los casos el acondicionamiento del{" "}
              <a href="/tips">embalaje de las piezas</a> es responsabilidad del
              remitente.
            </p>
            <p>
              * Toda operación tiene un costo mínimo y fijo de ${" "}
              <b>
                <i>{servicios.CostoFijo}</i>
              </b>
              , en concepto de flete por el retiro a domicilio de encomienda y
              gastos de servicio.
            </p> */}

            {/* <br />
            <Button variant="secondary" onClick={atrasForm1}>
              Anterior
            </Button>{" "}
            <br />
            {active && (
              <Button variant="primary" onClick={handleSubmit}>
                Siguiente
              </Button>
            )}
            {!active && (
              <Button variant="primary" onClick={handleSubmit} disabled>
                Siguiente
              </Button>
            )} */}

            <Form.Row>
              <Col md={{ span: 3, offset: 10 }}>
                <Button variant="secondary" onClick={atrasForm1}>
                  Anterior
                </Button>
                {"   "}
                {active && (
                  <Button variant="primary" onClick={handleSubmit}>
                    Siguiente
                  </Button>
                )}
                {!active && (
                  <Button variant="primary" onClick={handleSubmit} disabled>
                    Siguiente
                  </Button>
                )}
              </Col>
            </Form.Row>
          </div>
        )}
      </div>
    );
  } else {
    if (servicios.IdCotizacion) {
      return (
        <div>
          <div>
            No se encontro cotización para los códigos postales ingresados
          </div>
          <br />
          <Form.Row>
            <Col md={{ span: 3, offset: 10 }}>
              <Button variant="secondary" onClick={atrasForm1}>
                Anterior
              </Button>
            </Col>
          </Form.Row>
        </div>
      );
    } else {
      return (
        <div>
          <div>Espere mientras se carga la información</div>
          <br />
          <Form.Row>
            <Col md={{ span: 3, offset: 10 }}>
              <Button variant="secondary" onClick={atrasForm1}>
                Anterior
              </Button>
            </Col>
          </Form.Row>
        </div>
      );
    }
  }
}
export default TiposServicios;
