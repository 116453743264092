import React from "react";
import Item from "./Item";
import "./styles.css";

const SummaryDetails = () => {
  return (
    <section className="summary">
      <Item title="Personas">
        Sea lo que sea que tengas que enviar nosotros podemos encargarnos.
        Entendemos la importancia o el valor afectivo que pueden tener tus
        envíos. Ofrecemos un servicio personalizado, confiable y seguro,
        mediante una extensa red a lo largo de todo el país.
      </Item>

      <Item title="Emprendedores y PyMES">
        Simplificamos tu día a día para que puedas dedicarte a lo importante.
        Ayudamos a los emprendedores y a las Pymes a generar vínculos directos
        con sus clientes, brindando soluciones de logística versátiles para que
        puedan hacer crecer sus negocios.
      </Item>

      <Item title="Corporativo">
        Sabemos lo que significa comprometerse con los clientes y el valor de
        cumplir. Por eso brindamos un servicio corporativo con una amplia gama
        de soluciones logísticas de acuerdo a las necesidades y características
        particulares de cada una.
      </Item>
    </section>
  );
};

export default SummaryDetails;
