import React, { Component, useState, render, useEffect } from "react";
import "../styles.css";
import "../css/datepicker.css";
import "../css/dropdown.css";
import DatePicker from "react-datepicker";
import { addYears, addDays, addMinutes } from "date-fns";
import moment from "moment";
//import { registerLocale, setDefaultLocale, DatePicker} from  "react-datepicker";
//import es from 'date-fns/locale/es';
import {
  Accordion,
  Card,
  Col,
  Button,
  Form,
  Image,
  Modal,
  CardGroup,
  Transitions,
  Collapse,
  InputGroup,
  Row,
  Dropdown,
  Table,
  Stack
} from "react-bootstrap";
import {
  isValidEmail,
  returnOnlyNumbers1,
  returnOnlyNumbers,
  isPositiveNumber
} from "../../../../../utils/index";
import axios from "axios";
import Select from "react-select";
import { Redirect } from "react-router-dom";
import Calendar from "./controles/Calendar";
import alto_ico from "../../../../../../src/assets/product/formats/alto.png";
import ancho_ico from "../../../../../../src/assets/product/formats/ancho.png";
import bulto_ico from "../../../../../../src/assets/product/formats/bulto.png";
import peso_ico from "../../../../../../src/assets/product/formats/peso.png";
import profundidad_ico from "../../../../../../src/assets/product/formats/profundidad.png";
import valor_declarado_ico from "../../../../../../src/assets/product/formats/valor-declarado.png";

function Formulario(props) {
  const [validated, setValidated] = useState(false);
  const [CodigoSucursalOrigen, setCodigoSucursalOrigen] = useState("none");
  const [CodigoPostalRemitente, setCodigoPostalRemitente] = useState("none");
  const [CodigoPostalDestinatario, setCodigoPostalDestinatario] = useState(
    "none"
  );
  const [CodigoPuntoRecoleccion, setCodigoPuntoRecoleccion] = useState("none");
  const [codDestError, setCodDestError] = useState("none");
  const [codRecError, setCodRecError] = useState("none");
  const [globalError, setGlobalError] = useState(false);
  const [NumeroBultos, setNumeroBultos] = useState("");
  const [Kilos, setKilos] = useState("");
  const [Alto, setAlto] = useState("");
  const [Ancho, setAncho] = useState("");
  const [Largo, setLargo] = useState("");
  const [msjPickupMovil, setMsjPickupMovil] = useState("");
  const [msjBultos, setMsjBultos] = useState("");
  const [msjDimensionAlto, setDimensionAlto] = useState("");
  const [msjDimensionAncho, setDimensionAncho] = useState("");
  const [msjDimensionLargo, setDimensionLargo] = useState("");
  const [msjKgEnvio, setKgEnvio] = useState("");
  const [ImporteValorDeclarado, setImporteValorDeclarado] = useState("");
  const [pago, setPago] = useState("");
  const [pagoError, setpagoError] = useState("none");
  const [importe, setImporte] = useState("");
  const [importesRango, setImportesRango] = useState("");
  const [codSucOrigen, setCodSucOrigen] = useState("");
  const [codRem, setCodRem] = useState("");
  const [codDest, setCodDest] = useState("");
  const [codPuntoRecoleccion, setCodPuntoRecoleccion] = useState("");
  const [descPuntoRecoleccion, setDescPuntoRecoleccion] = useState(null);
  const [codPostal, setCodPostal] = useState(null);
  const [formattedValueCalendar, setFormattedValueCalendar] = useState(null);
  const [valueCalendar, setValueCalendar] = useState(null);
  const [token, setToken] = useState(sessionStorage.getItem("token"));
  const [selectedServicio, setSelectedServicio] = useState(optionsServicios);
  const [agencias, setAgencias] = useState(null);
  const [paradasMovil, setParadasMovil] = useState(null);
  const [feriados, setFeriados] = useState(null);
  const [findesyferiados, setFindesyFeriados] = useState(null);
  const [arrayPickupMovil, setArrayPickupMovil] = useState(null); //aqui el arreglo de pickup moviles

  const [idxCodPosRem, setIdxCodPosRem] = useState(4);
  const [idxCodPosDest, setIdxCodPosDes] = useState(5);
  const [idxBulto, setIdxBulto] = useState(6);
  const [idxPeso, setIdxPeso] = useState(7);
  const [idxAlto, setIdxAlto] = useState(8);
  const [idxAncho, setIdxAncho] = useState(9);
  const [idxLargo, setIdxLargo] = useState(10);
  const [idxValorDec, setIdxValorDec] = useState(11);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [show, setShow] = useState(false);

  //registerLocale('es', es);

  useEffect(() => {
    setToken(sessionStorage.getItem("token"));
    initialValueServicio();
  }, []);

  useEffect(() => {
    //const token = sessionStorage.getItem("token");
    var getUrl = window.location;
    var baseUrl = getUrl.host;
    var protocol = getUrl.protocol;
    var pathArray = baseUrl.split(":");
    let limDec;
    if (props.codigoProducto == "rd") {
      limDec = process.env.REACT_APP_ID_LIMITE_IMPORTE_DECLARADO_RD;
    } else if (props.codigoProducto == "ea") {
      limDec = process.env.REACT_APP_ID_LIMITE_IMPORTE_DECLARADO_EA;
    } else {
      limDec = process.env.REACT_APP_ID_LIMITE_IMPORTE_DECLARADO_PM;
    }
    let importe_declarado =
      process.env.REACT_APP_API_URL + `/api/importe_declarado`; //Descomentar cuando se quiera subir a preminens/Test
    // let haveHost = pathArray[1] != "" ? ":" + pathArray[1] : ""; //Solo local porque sino no funciona el acordeon
    // let importe_declarado =
    //   protocol + "//" + pathArray[0] + haveHost + `/api/importe_declarado`; //Solo local porque sino no funciona el acordeon

    if (token) {
      axios
        .get(importe_declarado, {
          params: { token: token, IdlimitanteDeclarado: limDec }
        })
        .then(response => {
          let { data } = response;

          setImportesRango(data[0]);
        })
        .catch(e => {
          /*this.setState({
              //infoPago: e.response.data.message,
              xhr: false,
              ////showModal: true,
              errorMessage: "server-error: "+e.response.data.message
            });    */

          console.log(e);
        });

      let url_cod_postal = process.env.REACT_APP_API_URL + "/api/codigo_postal";
      axios
        .get(url_cod_postal, "")
        .then(response => {
          let { data } = response;

          let datos = [];
          if (data.length > 0) {
            data.map(cod =>
              datos.push({
                value: cod.CodigoPostal,
                label: cod.Descripcion + " - " + cod.Provincia
              })
            );
            datos.sort((a, b) => a.Descripcion - b.Descripcion);
            setCodPostal(datos);
          }
        })
        .catch(e => {
          console.log(e);
          /*this.setState({
          //infoPago: e.response.data.message,
          xhr: false,
          showModal: true,
          errorMessage: "server-error: " + e.response.data.message
        });*/
        });

      if (props.codigoProducto == "pm") {
        _getFeriados();
        _getPuntoRecoleccion("");
      } else {
        _getAgencies();
      }
    } else {
      setToken(props.checkToken());
    }
  }, [token]);

  const saveDataInStorage = (name, data, value) => {
    if (!window.localStorage.getItem(name)) {
      window.localStorage.setItem(name, "{}");
    }
    let datos = JSON.parse(window.localStorage.getItem(name));
    if (data) {
      datos[data] = value;
    }
    window.localStorage.setItem(name, JSON.stringify(datos));
  };

  const _getAgencies = () => {
    let agenciesUrl = process.env.REACT_APP_API_URL + `/api/agencies`;
    axios
      .get(agenciesUrl, "data")
      .then(response => {
        let { data } = response;

        let datos = [];
        if (data.length > 0) {
          data.map(agency =>
            datos.push({
              value: agency.IdCodigoPostal,
              label:
                agency.Localidad +
                " - " +
                agency.Provincia +
                " (" +
                agency.Direccion +
                ")"
            })
          );
          //var sortedObjs = _.sortBy( datos, 'label' );
          datos.sort((a, b) => a.label.localeCompare(b.label));
          setAgencias(datos);
        }

        // console.log(data)
        // let opciones =[{value:null, label:"TODAS LAS AGENCIAS"}];
        // //response.data.map((agency) => opciones.push({ value:agency.Localidad+"-"+agency.Provincia, label: agency.Localidad+"-"+agency.Provincia }) );
        // data.map((agency) => opciones.push({ Localidad:agency.Localidad, Provincia:agency.Provincia, key: agency.IdAgencia }) );

        // this.setState((state, props) =>({
        //   agencies: data,
        //   options: opciones,
        // })
        // )
        // this.setState({ isLoading: false });
        // this.state.agencies.map(agency => { if("San Fernando" == agency.Localidad) {console.log(agency)}});
      })
      .catch(e => {
        this.setState({
          xhr: false,
          showModal: true,
          errorMessage: "server-error"
        });
        console.log(e);
      });
  };

  const _getPuntoRecoleccion = fechaSeleccionada => {
    let movilesUrl = process.env.REACT_APP_API_URL + `/api/buscar_paradas`;
    let currDate = new Date();
    let hoursMin =
      currDate.getHours() + ":" + addMinutes(currDate, 30).getMinutes();
    if (fechaSeleccionada.length != 0) {
      currDate.setHours(0, 0, 0, 0);
      fechaSeleccionada.setHours(0, 0, 0, 0);
      if (fechaSeleccionada > currDate) {
        hoursMin = "00:01";
      }
    }

    axios
      .get(movilesUrl, { params: { HorarioDesde: hoursMin } })
      .then(response => {
        let { data } = response;
        //console.log("Response /api/buscar_paradas response:" + JSON.stringify(response));
        let datos = [];
        let paradasMovil = [];
        if (data.length > 0) {
          data.map(movil =>
            datos.push({
              value: movil.IdParadasMovil,
              label:
                movil.Descripcion +
                " - " +
                movil.Direccion +
                " (" +
                movil.HorarioDesde +
                " a " +
                movil.HorarioHasta +
                ") ",
              order: movil.Orden.toString()
            })
          );
          //Ordeno los datos por el campo order
          datos.sort((a, b) => a.order - b.order);
          //creo un nuevo array sin el elemento order para que sea utilizado en el dropdown
          datos.map(movil =>
            paradasMovil.push({
              value: movil.value,
              label: movil.label
            })
          );
          setParadasMovil(paradasMovil);
          setArrayPickupMovil(response.data);
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({
          xhr: false,
          showModal: true,
          errorMessage: "server-error"
        });
      });
  };

  const _getFeriados = () => {
    let apiUrl = process.env.REACT_APP_API_URL + `/api/feriados`;
    let datos;
    axios
      .get(apiUrl, { params: { fecha: new Date().getFullYear() } })
      .then(response => {
        let { data } = response;
        let datos = [];
        if (data.length > 0) {
          data.map(fecha =>
            datos.push(
              new Date(new Date().getFullYear(), fecha.mes - 1, fecha.dia)
            )
          );
          console.log("_getFeriados:" + JSON.stringify(datos));
          setFeriados(datos);
          _feriadosyfindes(datos);
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({
          xhr: false,
          showModal: true,
          errorMessage: "server-error"
        });
      });
  };

  const _feriadosyfindes = feriados => {
    var actualDate = new Date(); //fecha actual
    var endDate = addDays(actualDate, 25); // agego 10 dias a la fecha actual
    var daysOfWeekend = [];

    while (actualDate < endDate) {
      var dayOfWeek = actualDate.getDay();
      var isWeekend = dayOfWeek === 6 || dayOfWeek === 0; //6 = Saturday, 0 = Sunday
      if (isWeekend) {
        daysOfWeekend.push(
          new Date(
            actualDate.getFullYear(),
            actualDate.getMonth(),
            actualDate.getDate()
          )
        );
      }
      actualDate.setDate(actualDate.getDate() + 1);
    }

    //console.log("daysOfWeekend:" + JSON.stringify(daysOfWeekend));
    //console.log("feriados:" + JSON.stringify(feriados));
    var diasDeshabilitados = daysOfWeekend.concat(feriados.map(fecha => fecha));
    //console.log("diasDeshabilitados:" + JSON.stringify(diasDeshabilitados));
    setFindesyFeriados(diasDeshabilitados);
  };

  const options = codPostal;
  const optionsAgencias = agencias;
  const optionsParadasMovil = paradasMovil;
  const optionsArrayPickupMovil = arrayPickupMovil;
  //const optionsPuntoRecoleccion = codPuntoRecoleccion;
  const optionsServicios = [
    { value: "rd", label: "Retiro en domicilio" },
    { value: "ea", label: "Entrega en agencia" }
    // { value: "pm", label: "Pickup móvil" }
  ];
  //

  const [campos, setCampos] = useState({
    CodigoSucursalOrigen: "none",
    CodigoPostalRemitente: "none",
    CodigoPostalDestinatario: "none",
    CodigoPuntoRecoleccion: "none",
    DescPuntoRecoleccion: "",
    NumeroBultos: "none",
    Kilos: "none",
    Alto: "none",
    Ancho: "none",
    Largo: "none",
    ImporteValorDeclarado: "none",
    pago: "none",
    IdClienteRemitente: "99999999",
    IdCentroRemitente: "99",
    PaisRemitente: "054",
    CodigoPostalRemitente: "1611",
    PaisDestinatario: "054",
    CodigoPostalDestinatario: "4400",
    TipoMoneda: "ARS"
  });

  const [values, setValues] = useState({
    CodigoSucursalOrigen: 0,
    CodigoPostalRemitente: 0,
    CodigoPostalDestinatario: 0,
    CodigoPuntoRecoleccion: 0,
    DescPuntoRecoleccion: "",
    NumeroBultos: 0,
    Kilos: 0,
    Alto: 0,
    Ancho: 0,
    Largo: 0,
    ImporteValorDeclarado: 0,
    pago: 0,
    IdClienteRemitente: "99999999",
    IdCentroRemitente: "99",
    PaisRemitente: "054",
    CodigoPostalRemitente: "1611",
    PaisDestinatario: "054",
    CodigoPostalDestinatario: "4400",
    TipoMoneda: "ARS"
  });

  //aqui el pickup movil seleccionado
  const [valuePickupMovil, setValuePickupMovil] = useState({
    IdParadasMovil: 0,
    Orden: 0,
    Descripcion: "",
    Direccion: "",
    Latitud: 0,
    Longitud: 0,
    HorarioDesde: "",
    HorarioHasta: "",
    CodigoPostal: "",
    TotalPages: null,
    Page: null,
    Fecha: null
  });

  const [boton, setBoton] = useState(true);
  const nombreCampos = [
    "CodigoSucursalOrigen",
    "CodigoPostalRemitente",
    "CodigoPostalDestinatario",
    "CodigoPuntoRecoleccion",
    "NumeroBultos",
    "Kilos",
    "Alto",
    "Ancho",
    "Largo",
    "ImporteValorDeclarado",
    "pago"
  ];

  var variables = [];
  let siguiente = false;
  let cRem = "";
  let cDest = "";

  const handleSubmit2 = event => {
    let name = event.currentTarget.activeElement.name;
    let value = event.currentTarget.activeElement.value;

    siguiente = true;
    switch (name) {
      case "Alto":
      case "Ancho":
      case "Largo": {
        if (value < 5) {
          setBoton(true);
          siguiente = false;
          setCampos({
            ...campos,
            [name]: validarEntero("incorrecto")
          });
        }
      }
      case "ImporteValorDeclarado": {
        if (
          validarEntero(value) == "none" &&
          (parseInt(value) > importesRango.Maximo ||
            parseInt(value) < importesRango.Minimo)
        ) {
          setImporte(
            "El valor declarado debe estar entre " +
              importesRango.Minimo +
              " y " +
              importesRango.Maximo +
              " pesos"
          );
          setBoton(true);
          siguiente = false;
        }
      }
      case "Kilos": {
        if (value < 0 || value > 50) {
          setBoton(true);
          siguiente = false;
          setCampos({
            ...campos,
            [name]: validarEntero("incorrecto")
          });
        }
      }
    }
  };

  const handleSubmit = event => {
    //console.log("event.currentTarget: " + event.currentTarget);

    var trigger_field = document.getElementById(event.target.id);
    var form = trigger_field.form;

    // const form =
    //   event.currentTarget.parentNode[0] !== undefined
    //     ? event.currentTarget.parentNode
    //     : event.currentTarget.parentNode.parentNode;
    var nuevosCampos = campos;

    //if (event.currentTarget.parentNode[0] !== undefined) {
    if (form !== undefined) {
      //console.log("if 1");
      var valueBulto = document.getElementById("my-form").elements[
        "NumeroBultos"
      ].value;
      var valueKilos = document.getElementById("my-form").elements["Kilos"]
        .value;
      var valueAlto = document.getElementById("my-form").elements["Alto"].value;
      var valueAncho = document.getElementById("my-form").elements["Ancho"]
        .value;
      var valueLargo = document.getElementById("my-form").elements["Largo"]
        .value;
      var valueImporteValorDeclarado = document.getElementById("my-form")
        .elements["ImporteValorDeclarado"].value;

      //setCodigoPostalRemitente(validarEntero(form[0].value))
      //setCodigoPostalDestinatario(validarEntero(form[1].value))
      setNumeroBultos(validarBultos(valueBulto));
      //console.log(validarKg(form[5].value));
      setKilos(validarKg(valueKilos));
      setAlto(validarDimensiones(valueAlto, "alto"));
      setAncho(validarDimensiones(valueAncho, "ancho"));
      setLargo(validarDimensiones(valueLargo, "largo"));
      setImporteValorDeclarado(
        validarImporteDeclarado(valueImporteValorDeclarado)
      );

      var nuevosCampos = {
        CodigoSucursalOrigen: codSucOrigen,
        CodigoPostalRemitente: codRem, //validarEntero(form[0].value),
        CodigoPostalDestinatario: codDest, //validarEntero(form[1].value),
        CodigoPuntoRecoleccion: codPuntoRecoleccion,
        DescPuntoRecoleccion: descPuntoRecoleccion,
        NumeroBultos: validarBultos(valueBulto),
        Kilos: validarKg(valueKilos),
        Alto: validarDimensiones(valueAlto, "alto"),
        Ancho: validarDimensiones(valueAncho, "ancho"),
        Largo: validarDimensiones(valueLargo, "largo"),
        ImporteValorDeclarado: validarDecimal(valueImporteValorDeclarado),
        Pago: pago,
        IdClienteRemitente: "99999999",
        IdCentroRemitente: "99",
        PaisRemitente: "054",
        CodigoPostalRemitente: "1611",
        PaisDestinatario: "054",
        CodigoPostalDestinatario: "4400",
        TipoMoneda: "ARS"
      };

      setCampos(nuevosCampos);
      //console.log(form.checkValidity());
      var supero = false;
      switch (selectedServicio.value) {
        case "ea":
          supero =
            valueKilos < 0 ||
            valueAlto < 1 ||
            valueAncho < 1 ||
            valueLargo < 1 ||
            parseInt(valueImporteValorDeclarado) > importesRango.Maximo ||
            parseInt(valueImporteValorDeclarado) < importesRango.Minimo
              ? false
              : true;
          supero = supero && form.checkValidity() === true;
          break;
        case "rd":
          supero =
            valueKilos < 0 ||
            valueKilos > 50 ||
            valueAlto < 1 ||
            valueAncho < 1 ||
            valueLargo < 1 ||
            parseInt(valueImporteValorDeclarado) > importesRango.Maximo ||
            parseInt(valueImporteValorDeclarado) < importesRango.Minimo
              ? false
              : true;
          supero = supero && form.checkValidity() === true;
          break;
        case "pm":
          //console.log("handleSubmit case pm values: " + JSON.stringify(nuevosCampos));
          supero =
            valueKilos < 0 ||
            valueKilos > 15 ||
            valueAlto > 26 ||
            valueAncho > 62 ||
            valueLargo > 62 ||
            parseInt(valueImporteValorDeclarado) > importesRango.Maximo ||
            parseInt(valueImporteValorDeclarado) < importesRango.Minimo
              ? false
              : true;
          supero = supero && form.checkValidity() === true;
          break;
      }
      //console.log("handleSubmitRadio, supero: " +supero +", form.checkValidity():" +form.checkValidity());
      if (!supero) {
        setGlobalError(true);
        setBoton(true);
        event.preventDefault();
        event.stopPropagation();
      } else {
        setGlobalError(false);
        setBoton(false);

        setValues({
          CodigoSucursalOrigen: codSucOrigen,
          CodigoPostalRemitente: codRem,
          CodigoPostalDestinatario: codDest,
          CodigoPuntoRecoleccion: codPuntoRecoleccion,
          DescPuntoRecoleccion: descPuntoRecoleccion,
          NumeroBultos: valueBulto,
          Kilos: valueKilos,
          Alto: valueAlto,
          Ancho: valueAncho,
          Largo: valueLargo,
          ImporteValorDeclarado: valueImporteValorDeclarado,
          Pago: pago,
          IdClienteRemitente: "99999999",
          IdCentroRemitente: "99",
          PaisRemitente: "054",
          //CodigoPostalRemitente: '1611',
          PaisDestinatario: "054",
          //CodigoPostalDestinatario: '4400',
          TipoMoneda: "ARS"
        });
        event.preventDefault();
        event.stopPropagation();
      }
      if (codDest === "") {
        setBoton(true);
        setCodDestError("block");
        event.preventDefault();
        event.stopPropagation();
      }
      if (codPuntoRecoleccion === "") {
        setBoton(true);
        setCodRecError("block");
        event.preventDefault();
        event.stopPropagation();
      }
    } else {
      //console.log("if 4");
      var valueBulto = document.getElementById("my-form").elements[
        "NumeroBultos"
      ].value;
      var valueKilos = document.getElementById("my-form").elements["Kilos"]
        .value;
      var valueAlto = document.getElementById("my-form").elements["Alto"].value;
      var valueAncho = document.getElementById("my-form").elements["Ancho"]
        .value;
      var valueLargo = document.getElementById("my-form").elements["Largo"]
        .value;
      var valueImporteValorDeclarado = document.getElementById("my-form")
        .elements["ImporteValorDeclarado"].value;
      let errorEncontrado = false;
      const form =
        event.currentTarget.parentNode.parentNode[0] !== undefined
          ? event.currentTarget.parentNode.parentNode
          : event.currentTarget.parentNode.parentNode.parentNode;

      let validar = returnOnlyNumbers1(event.currentTarget.value);
      validar = validar.replace(/,/g, "");
      event.currentTarget.value = validar;

      if (event.currentTarget.name == "ImporteValorDeclarado") {
        siguiente = true;
        if (
          validarEntero(event.currentTarget.value) == "none" &&
          (parseInt(event.currentTarget.value) > importesRango.Maximo ||
            parseInt(event.currentTarget.value) < importesRango.Minimo)
        ) {
          setImporte(
            "Valor debe estar entre " +
              importesRango.Minimo +
              " y " +
              importesRango.Maximo +
              " pesos"
          );
          setBoton(true);
          setGlobalError(true);
          siguiente = false;
          errorEncontrado = true;
        }
      } else {
        if (
          event.currentTarget.name == "Alto" ||
          event.currentTarget.name == "Ancho" ||
          event.currentTarget.name == "Largo"
        ) {
          //alert(event.currentTarget.value)

          siguiente = true;
          if (event.currentTarget.value < 5) {
            setGlobalError(true);
            setBoton(true);

            siguiente = false;
            setCampos({
              ...campos,
              [event.currentTarget.name]: validarDimensiones("incorrecto", "")
            });
          }
        }

        if (event.currentTarget.name == "Kilos") {
          //alert(event.currentTarget.value)

          siguiente = true;
          if (event.currentTarget.value < 0 || event.currentTarget.value > 50) {
            setGlobalError(true);
            setBoton(true);

            siguiente = false;
            setCampos({
              ...campos,
              [event.currentTarget.name]: validarEntero("incorrecto")
            });
          }
        }
      }

      if (siguiente == true) {
        if (validarEntero(event.currentTarget.value) != "block") {
          setImporte("");
          setGlobalError(false);
          setBoton(false);

          setCampos({
            ...campos,
            [event.currentTarget.name]: validarEntero(event.currentTarget.value)
          });
        }
      }

      if (
        form.checkValidity() === false ||
        valueKilos < 0 ||
        valueKilos > 50 ||
        valueAlto < 5 ||
        valueAncho < 5 ||
        valueLargo < 5 ||
        parseInt(valueImporteValorDeclarado) > importesRango.Maximo ||
        parseInt(valueImporteValorDeclarado) < importesRango.Minimo
      ) {
        setBoton(true);
        setGlobalError(true);
        siguiente = false;
        event.preventDefault();
        event.stopPropagation();
      } else {
        // por si ingresan los valores de forma desordenada
        setGlobalError(false);
        setBoton(false);
        siguiente = true;

        if (event.currentTarget.name == "ImporteValorDeclarado") {
          if (
            validarEntero(event.currentTarget.value) == "none" &&
            (parseInt(event.currentTarget.value) > importesRango.Maximo ||
              parseInt(event.currentTarget.value) < importesRango.Minimo)
          ) {
            setImporte(
              "Valor debe estar entre " +
                importesRango.Minimo +
                " y " +
                importesRango.Maximo +
                " pesos"
            );
            setBoton(true);
            setGlobalError(true);
            siguiente = false;
          } else {
            setGlobalError(false);
            setBoton(false);
          }
        }

        if (pago == "") {
          setGlobalError(true);
          setBoton(true);
        } else {
          setGlobalError(false);
          setBoton(false);
        }

        setValues({
          CodigoSucursalOrigen: codSucOrigen,
          CodigoPostalRemitente: codRem,
          CodigoPostalDestinatario: codDest,
          CodigoPuntoRecoleccion: codPuntoRecoleccion,
          DescPuntoRecoleccion: descPuntoRecoleccion,
          NumeroBultos: valueBulto,
          Kilos: valueKilos,
          Alto: valueAlto,
          Ancho: valueAncho,
          Largo: valueLargo,
          ImporteValorDeclarado: valueImporteValorDeclarado,
          Pago: pago,
          IdClienteRemitente: "99999999",
          IdCentroRemitente: "99",
          PaisRemitente: "054",
          //CodigoPostalRemitente: '1611',
          PaisDestinatario: "054",
          //CodigoPostalDestinatario: '4400',
          TipoMoneda: "ARS"
        });
        event.preventDefault();
        event.stopPropagation();
      }

      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handleSubmitRetiroDomicilio = (form, nuevosCampos) => {};

  const handleSubmitEntregaAgencia = (form, nuevosCampos) => {};

  const handleSubmitPickupMovil = (form, nuevosCampos) => {};

  const validar = event => {
    const form = event.currentTarget.parentNode;

    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setBoton(false);
    }
  };

  const validarCampoEntero = e => {
    const form = e.currentTarget;

    if (!form.checkValidity()) {
      setBoton(true);
      e.preventDefault();
      e.stopPropagation();
    } else {
      setBoton(false);
    }

    setCampos({
      ...campos,
      [e.target.name]: validarEntero(e.target.value)
    });
  };

  const validarCampoDecimal = e => {
    const form = e.currentTarget.parentNode;

    if (!form.checkValidity()) {
      setBoton(true);
      e.preventDefault();
      e.stopPropagation();
    } else {
      setBoton(false);
    }

    setCampos({
      ...campos,
      [e.target.name]: validarDecimal(e.target.value)
    });
  };

  const validarEntero = valor => {
    valor = valor.replace(/\D/g, "");
    return valor;
    //intento convertir a entero.
    //si era un entero no le afecta, si no lo era lo intenta convertir
    // valor = parseInt(valor);

    // //Compruebo si es un valor numérico
    // if (isNaN(valor) || valor < 0) {
    //   //entonces (no es numero) devuelvo el valor cadena vacia

    //   return "block";
    // } else {
    //   //En caso contrario (Si era un número) devuelvo el valor
    //   return "none";
    // }
  };

  const validarDecimal = valor => {
    valor = parseFloat(valor);

    if (isNaN(valor) || valor < 0) {
      return "block";
    } else {
      return "none";
    }
  };

  const validarBultos = valor => {
    valor = valor.replace(/\D/g, "");
    switch (selectedServicio.value) {
      case "rd":
        if (parseInt(valor) < 1 || valor == "") {
          setMsjBultos("Campo requerido");
        } else {
          setMsjBultos("");
        }
        break;
      case "ea":
        if (parseInt(valor) < 1 || valor == "") {
          setMsjBultos("Campo requerido");
        } else {
          setMsjBultos("");
        }
        break;
      case "pm":
        if (parseInt(valor) < 1 || valor == "") {
          setMsjBultos("Campo requerido");
        } else {
          if (parseInt(valor) > 5) {
            setMsjBultos("Máximo 5 bultos");
          } else {
            setMsjBultos("");
          }
        }

        break;
    }
    return valor;
  };

  const validarDimensiones = (valor, dimension) => {
    valor = valor.replace(/\D/g, "");
    //console.log("validarDimensiones, valor:" +valor +", dimension: " +dimension +"selectedServicio: " +selectedServicio.value);
    switch (selectedServicio.value) {
      case "rd":
        switch (dimension) {
          case "alto":
            if (
              parseInt(valor) < 1 ||
              parseInt(valor) === "" ||
              parseInt(valor) === NaN ||
              valor === ""
            ) {
              setDimensionAlto("Campo obligatorio.");
            } else {
              setDimensionAlto("");
            }
            break;
          case "ancho":
            if (
              parseInt(valor) < 1 ||
              parseInt(valor) === "" ||
              parseInt(valor) === NaN ||
              valor === ""
            ) {
              setDimensionAncho("Campo obligatorio.");
            } else {
              setDimensionAncho("");
            }
            break;
          case "largo":
            if (
              parseInt(valor) < 1 ||
              parseInt(valor) === "" ||
              parseInt(valor) === NaN ||
              valor === ""
            ) {
              setDimensionLargo("Campo obligatorio.");
            } else {
              setDimensionLargo("");
            }
            break;
        }
        break;
      case "ea":
        switch (dimension) {
          case "alto":
            if (
              parseInt(valor) < 1 ||
              parseInt(valor) === "" ||
              parseInt(valor) === NaN ||
              valor === ""
            ) {
              setDimensionAlto("Campo obligatorio.");
            } else {
              setDimensionAlto("");
            }
            break;
          case "ancho":
            if (
              parseInt(valor) < 1 ||
              parseInt(valor) === "" ||
              parseInt(valor) === NaN ||
              valor === ""
            ) {
              setDimensionAncho("Campo obligatorio.");
            } else {
              setDimensionAncho("");
            }
            break;
          case "largo":
            if (
              parseInt(valor) < 1 ||
              parseInt(valor) === "" ||
              parseInt(valor) === NaN ||
              valor === ""
            ) {
              setDimensionLargo("Campo obligatorio.");
            } else {
              setDimensionLargo("");
            }
            break;
        }
        break;
      case "pm":
        switch (dimension) {
          case "alto":
            if (parseInt(valor) > 25) {
              setDimensionAlto("Campo obligatorio, máximo 25cm.");
            } else if (
              parseInt(valor) === "" ||
              parseInt(valor) === NaN ||
              valor === ""
            ) {
              setDimensionAlto("Campo obligatorio.");
            } else if (parseInt(valor) < 1) {
              setDimensionAlto("Campo obligatorio.");
            } else {
              setDimensionAlto("");
            }
            break;
          case "ancho":
            if (parseInt(valor) > 40) {
              setDimensionAncho("Campo obligatorio, máximo 40cm.");
            } else if (
              parseInt(valor) === "" ||
              parseInt(valor) === NaN ||
              valor === ""
            ) {
              setDimensionAncho("Campo obligatorio.");
            } else if (parseInt(valor) < 1) {
              setDimensionAncho("Campo obligatorio.");
            } else {
              setDimensionAncho("");
            }
            break;
          case "largo":
            if (parseInt(valor) > 45) {
              setDimensionLargo("Campo obligatorio, máximo 45cm.");
            } else if (
              parseInt(valor) === "" ||
              parseInt(valor) === NaN ||
              valor === ""
            ) {
              setDimensionLargo("Campo obligatorio.");
            } else if (parseInt(valor) < 1) {
              setDimensionLargo("Campo obligatorio.");
            } else {
              setDimensionLargo("");
            }
            break;
        }
        break;
    }

    return valor;
    // valor = parseInt(valor);

    // if (isNaN(valor)) return "block";
    // validar = returnOnlyNumbers1(valor);
    // if (validar.length) validar = validar.replace(/,/g, "");
    // valor = validar;

    // if (isNaN(valor) || valor < 5) {
    //   return "block";
    // } else {
    //   return "none";
    // }
  };

  const validarKg = valor => {
    valor = valor.replace(/\D/g, "");
    //console.log("validarKg, " + selectedServicio.value + ", Kg: " + valor);
    switch (selectedServicio.value) {
      case "rd":
        if (parseInt(valor) > 50) {
          setKgEnvio("Campo obligatorio, máximo 50 Kg.");
        } else if (parseInt(valor) < 1) {
          setKgEnvio("Campo obligatorio, mínimo 1 Kg.");
        } else if (
          parseInt(valor) === "" ||
          parseInt(valor) === NaN ||
          valor === ""
        ) {
          setKgEnvio("Campo obligatorio");
        } else {
          setKgEnvio("");
        }
        break;
      case "ea":
        if (parseInt(valor) < 1 || parseInt(valor) === NaN || valor === "") {
          setKgEnvio("Campo obligatorio");
        } else {
          setKgEnvio("");
        }
        break;
      case "pm":
        if (parseInt(valor) > 15) {
          setKgEnvio("Campo obligatorio, máximo 15 Kg.");
        } else if (parseInt(valor) < 1) {
          setKgEnvio("Campo obligatorio, mínimo 1 Kg.");
        } else if (
          parseInt(valor) === "" ||
          parseInt(valor) === NaN ||
          valor === ""
        ) {
          setKgEnvio("Campo obligatorio");
        } else {
          setKgEnvio("");
        }
        break;
    }
    return valor;
    // // valor = parseFloat(valor);
    // valor = parseInt(valor);

    // if (isNaN(valor) || valor < 0 || valor > 50) {
    //   return "block";
    // } else {
    //   return "none";
    // }
  };

  const validarImporteDeclarado = valor => {
    valor = valor.replace(/\D/g, "");
    if (
      parseInt(valor) > importesRango.Maximo ||
      parseInt(valor) < importesRango.Minimo
    ) {
      setImporte(
        "Valor debe estar entre " +
          importesRango.Minimo +
          " y " +
          importesRango.Maximo +
          " pesos"
      );
    } else {
      setImporte("");
    }
    return valor;
  };

  const handleBlockedButtom = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const handleSubmitRadio = event => {
    // const form =
    //   event.currentTarget.parentNode[0] !== undefined
    //     ? event.currentTarget.parentNode
    //     : event.currentTarget.parentNode.parentNode;

    var trigger_field = document.getElementById(event.target.id);
    var form = trigger_field.form;
    var valueBulto = document.getElementById("my-form").elements["NumeroBultos"]
      .value;
    var valueKilos = document.getElementById("my-form").elements["Kilos"].value;
    var valueAlto = document.getElementById("my-form").elements["Alto"].value;
    var valueAncho = document.getElementById("my-form").elements["Ancho"].value;
    var valueLargo = document.getElementById("my-form").elements["Largo"].value;
    var valueImporteValorDeclarado = document.getElementById("my-form")
      .elements["ImporteValorDeclarado"].value;

    setPago(event.currentTarget.value);
    var supero = false;
    switch (selectedServicio.value) {
      case "ea":
        supero =
          valueKilos < 0 ||
          valueAlto < 1 ||
          valueAncho < 1 ||
          valueLargo < 1 ||
          parseInt(valueImporteValorDeclarado) > importesRango.Maximo ||
          parseInt(valueImporteValorDeclarado) < importesRango.Minimo
            ? false
            : true;
        supero = supero && form.checkValidity() === true;
        break;
      case "rd":
        supero =
          valueKilos < 0 ||
          valueKilos > 50 ||
          valueAlto < 1 ||
          valueAncho < 1 ||
          valueLargo < 1 ||
          parseInt(valueImporteValorDeclarado) > importesRango.Maximo ||
          parseInt(valueImporteValorDeclarado) < importesRango.Minimo
            ? false
            : true;
        supero = supero && form.checkValidity() === true;
        break;
      case "pm":
        supero =
          valueKilos < 0 ||
          valueKilos > 15 ||
          valueAlto > 26 ||
          valueAncho > 62 ||
          valueLargo > 62 ||
          parseInt(valueImporteValorDeclarado) > importesRango.Maximo ||
          parseInt(valueImporteValorDeclarado) < importesRango.Minimo
            ? false
            : true;
        supero = supero && form.checkValidity() === true;
        break;
    }

    //console.log("handleSubmitRadio, supero: " +supero +", form.checkValidity():" +form.checkValidity());
    if (
      !supero ||
      codDest == null ||
      (codRem == null && codSucOrigen == null && paradasMovil == null)
    ) {
      setBoton(true);
      siguiente = false;
    } else {
      if (form.checkValidity() === true) {
        setBoton(false);
        siguiente = true;
        values.Pago = event.currentTarget.value;
      }
    }
  };

  const initialValueServicio = () => {
    switch (props.codigoProducto) {
      case "rd":
        setSelectedServicio(optionsServicios[0]);
        //console.log("setSelectedServicio: " + optionsServicios[0].value);
        break;
      case "ea":
        setSelectedServicio(optionsServicios[1]);
        //console.log("setSelectedServicio: " + optionsServicios[1].value);
        break;
      case "pm":
        setSelectedServicio(optionsServicios[2]);
        //setCodRem("1414");
        //setCodDest("1428");
        setPago("P");
        //console.log("setSelectedServicio: " + optionsServicios[2].value);
        break;
      default:
        setSelectedServicio(optionsServicios[0]);
        //console.log("setSelectedServicio (default): " + optionsServicios[0].value);
        break;
    }
  };

  const handleChangeServicio = selectedOption => {
    if (selectedOption.value != null) {
      setSelectedServicio(selectedOption.value);
      switch (selectedOption.value) {
        case "rd":
          window.location.href = "/product/rd";
          break;
        case "ea":
          window.location.href = "/product/ea";
          break;
        case "pm":
          window.location.href = "/product/pm";
          break;
      }
    }
  };

  const handleChangeRem = selectedOption => {
    if (selectedOption.value != null) {
      setCodRem(selectedOption.value);
      setValues({
        CodigoSucursalOrigen: values.CodigoSucursalOrigen,
        CodigoPostalRemitente: selectedOption.value,
        CodigoPostalDestinatario: values.CodigoPostalDestinatario,
        PuntoRecoleccion: values.CodigoPuntoRecoleccion,
        DescPuntoRecoleccion: values.DescPuntoRecoleccion,
        NumeroBultos: values.NumeroBultos,
        Kilos: values.Kilos,
        Alto: values.Alto,
        Ancho: values.Ancho,
        Largo: values.Largo,
        ImporteValorDeclarado: values.ImporteValorDeclarado,
        Pago: pago,
        IdClienteRemitente: "99999999",
        IdCentroRemitente: "99",
        PaisRemitente: "054",
        PaisDestinatario: "054",
        TipoMoneda: "ARS"
      });

      setCodigoPostalRemitente(selectedOption.value);
    }
  };

  const handleChangeSucursalOrigen = selectedOption => {
    if (selectedOption.value != null) {
      setCodSucOrigen(selectedOption.value);
      setValues({
        CodigoSucursalOrigen: selectedOption.value,
        CodigoPostalRemitente: values.CodigoPostalRemitente,
        CodigoPostalDestinatario: values.CodigoPostalDestinatario,
        PuntoRecoleccion: values.CodigoPuntoRecoleccion,
        DescPuntoRecoleccion: values.DescPuntoRecoleccion,
        NumeroBultos: values.NumeroBultos,
        Kilos: values.Kilos,
        Alto: values.Alto,
        Ancho: values.Ancho,
        Largo: values.Largo,
        ImporteValorDeclarado: values.ImporteValorDeclarado,
        Pago: pago,
        IdClienteRemitente: "99999999",
        IdCentroRemitente: "99",
        PaisRemitente: "054",
        PaisDestinatario: "054",
        TipoMoneda: "ARS"
      });

      setCodigoSucursalOrigen(selectedOption.value);
    }
  };

  const handleChangeDest = selectedOption => {
    if (selectedOption.value != null) {
      setCodDest(selectedOption.value);
      setValues({
        CodigoSucursalOrigen: values.CodigoSucursalOrigen,
        CodigoPostalRemitente: values.CodigoPostalRemitente,
        CodigoPostalDestinatario: selectedOption.value,
        PuntoRecoleccion: values.CodigoPuntoRecoleccion,
        DescPuntoRecoleccion: values.DescPuntoRecoleccion,
        NumeroBultos: values.NumeroBultos,
        Kilos: values.Kilos,
        Alto: values.Alto,
        Ancho: values.Ancho,
        Largo: values.Largo,
        ImporteValorDeclarado: values.ImporteValorDeclarado,
        Pago: pago,
        IdClienteRemitente: "99999999",
        IdCentroRemitente: "99",
        PaisRemitente: "054",
        PaisDestinatario: "054",
        TipoMoneda: "ARS"
      });
      setCodigoPostalDestinatario(selectedOption.value);
      setCodDestError("none");
      if (!globalError && codRecError === "none") {
        setBoton(false);
      }
    }
  };

  const [selectedValue, selectValue] = useState(null);

  const handleChangeRecoleccion = selectedOption => {
    console.log("handleChangeRecoleccion: " + JSON.stringify(selectedOption));
    if (selectedOption != null) {
      if (props.codigoProducto == "pm") {
        saveDataInStorage(
          "datosRemitente",
          "HoraRetiro",
          selectedOption.HorarioDesde
        );
        saveDataInStorage(
          "datosRemitente",
          "FechaRetiro",
          moment(startDate).format("DD/MM/YYYY")
        );
      }

      setMsjPickupMovil("");
      setpagoError("none");
      setCodPuntoRecoleccion(selectedOption.IdParadasMovil);
      setDescPuntoRecoleccion(selectedOption.Descripcion);
      setValues({
        CodigoSucursalOrigen: values.CodigoSucursalOrigen,
        CodigoPostalRemitente: values.CodigoPostalRemitente,
        CodigoPostalDestinatario: values.CodigoPostalDestinatario,
        PuntoRecoleccion: selectedOption.IdParadasMovil,
        DescPuntoRecoleccion: selectedOption.Descripcion,
        NumeroBultos: values.NumeroBultos,
        Kilos: values.Kilos,
        Alto: values.Alto,
        Ancho: values.Ancho,
        Largo: values.Largo,
        ImporteValorDeclarado: values.ImporteValorDeclarado,
        Pago: pago,
        IdClienteRemitente: "99999999",
        IdCentroRemitente: "99",
        PaisRemitente: "054",
        PaisDestinatario: "054",
        TipoMoneda: "ARS"
      });
      setCodigoPuntoRecoleccion(selectedOption.IdParadasMovil);
      LoadValuePickupMovil(selectedOption.IdParadasMovil);
      setCodRecError("none");
      if (!globalError && codDestError === "none") {
        setBoton(false);
      }
    }
  };

  // const handleChangeRecoleccion = selectedOption => {
  //   console.log("handleChangeRecoleccion: "+ JSON.stringify(selectedOption.value));
  //   if (selectedOption.value != null) {
  //     setMsjPickupMovil("");
  //     setpagoError("none");
  //     setCodPuntoRecoleccion(selectedOption.value);
  //     setDescPuntoRecoleccion(selectedOption.label);
  //     setValues({
  //       CodigoSucursalOrigen: values.CodigoSucursalOrigen,
  //       CodigoPostalRemitente: values.CodigoPostalRemitente,
  //       CodigoPostalDestinatario: values.CodigoPostalDestinatario,
  //       PuntoRecoleccion: selectedOption.value,
  //       DescPuntoRecoleccion: selectedOption.label,
  //       NumeroBultos: values.NumeroBultos,
  //       Kilos: values.Kilos,
  //       Alto: values.Alto,
  //       Ancho: values.Ancho,
  //       Largo: values.Largo,
  //       ImporteValorDeclarado: values.ImporteValorDeclarado,
  //       Pago: pago,
  //       IdClienteRemitente: "99999999",
  //       IdCentroRemitente: "99",
  //       PaisRemitente: "054",
  //       PaisDestinatario: "054",
  //       TipoMoneda: "ARS"
  //     });
  //     setCodigoPuntoRecoleccion(selectedOption.value);
  //     LoadValuePickupMovil(selectedOption.value);
  //   }
  // };

  const LoadValuePickupMovil = codigoPickupMovil => {
    try {
      let pickup = arrayPickupMovil.find(
        elem => elem.IdParadasMovil == codigoPickupMovil
      );
      console.log("LoadValuePickupMovil pickup: " + JSON.stringify(pickup));
      if (JSON.stringify(pickup).length > 0) {
        setCodRem(pickup.CodigoPostal);
        values.CodigoPuntoRecoleccion = pickup.IdParadasMovil;
        setValuePickupMovil({
          IdParadasMovil: pickup.IdParadasMovil,
          Orden: pickup.Orden,
          Descripcion: pickup.Descripcion,
          Direccion: pickup.Direccion,
          Latitud: pickup.Latitud,
          Longitud: pickup.Longitud,
          HorarioDesde: pickup.HorarioDesde,
          HorarioHasta: pickup.HorarioHasta,
          CodigoPostal: pickup.CodigoPostal,
          TotalPages: pickup.TotalPages,
          Page: pickup.Page,
          Fecha: startDate.toLocaleDateString("es-Es")
        });
        setCodigoPostalRemitente(pickup.CodigoPostal);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const nextStepHandler = event => {
    //const selectedService = services.find(s => s.selected);

    if (pago == "") {
      setpagoError("block");
      setBoton(true);
      return false;
    } else {
      if (props.codigoProducto == "pm") {
        saveDataInStorage(
          "datosRemitente",
          "FechaRetiro",
          moment(startDate).format("DD/MM/YYYY")
        );
        if (valuePickupMovil.IdParadasMovil != "0") {
          props.pickupMovil(valuePickupMovil);
        } else {
          setMsjPickupMovil("Campo obligatorio");
          return false;
        }
      }
      setpagoError("none");
      props.tiposServicios(values);
      if (codDest === "") {
        setCodDestError("block");
        event.preventDefault();
        event.stopPropagation();
      } else {
        setCodRecError("none");
      }
      if (codPuntoRecoleccion === "") {
        setCodRecError("block");
        event.preventDefault();
        event.stopPropagation();
      } else {
        setCodRecError("none");
      }
    }
  };

  const handleChangeCalendar = (value, formattedValue) => {
    setValueCalendar(value); // ISO String, ex: "2016-11-19T12:00:00.000Z"
    setFormattedValueCalendar(formattedValue); // Formatted String, ex: "11/19/2016"
  };

  const getData = () => {
    console.log(
      "getData arrayPickupMovil: " + JSON.stringify(arrayPickupMovil)
    );
    //arrayPickupMovil.map(pickup => data.push({id: pickup.IdParadasMovil, descripcion: pickup.Descripcion, direccion: pickup.Direccion, horarioDesde: pickup.HorarioDesde, horarioHasta: pickup.HorarioHasta}))
    if (arrayPickupMovil != null) {
    } else {
      return <tr></tr>;
    }
  };

  if (importesRango) {
    let title = "";
    switch (props.codigoProducto) {
      case "rd":
        title = "Retiro en domicilio";
        break;
      case "ea":
        title = "Pickup en agencia";
        break;

      case "pm":
        title = "Pickup móvil";
        break;
      default:
        title = "";
        break;
    }
    return (
      <Form noValidate validated={validated} id="my-form">
        {/* <h1 className="product-title-primary">
          Completá los datos de tu envío ({title})
        </h1> */}
        {/* */}
        <p>Los campos marcados con * son obligatorios</p>
        {props.codigoProducto == "pm" && (
          <Form.Row>
            <Form.Group as={Col} controlId="SelectServicio">
              <Form.Label>¿Cómo querés enviar?*</Form.Label>
              <Select
                options={optionsServicios}
                // defaultValue={optionsServicios[0]}
                defaultValue={selectedServicio}
                //value={selectedServicio}
                placeholder="Seleccionar Servicio"
                name="SelectServicio"
                onChange={handleChangeServicio}
                className="inputCorto"
                required
              />
            </Form.Group>
          </Form.Row>
        )}
        <Form.Row>
          {props.codigoProducto == "rd" && (
            <Form.Group as={Col} controlId="SelectServicio">
              <Form.Label>¿Cómo querés enviar?*</Form.Label>
              <Select
                options={optionsServicios}
                // defaultValue={optionsServicios[0]}
                defaultValue={selectedServicio}
                //value={selectedServicio}
                placeholder="Seleccionar Servicio"
                name="SelectServicio"
                onChange={handleChangeServicio}
                required
              />
            </Form.Group>
          )}

          {props.codigoProducto == "ea" && (
            <Form.Group as={Col} controlId="SelectServicio">
              <Form.Label>¿Cómo querés enviar?*</Form.Label>
              <Select
                options={optionsServicios}
                // defaultValue={optionsServicios[0]}
                defaultValue={selectedServicio}
                //value={selectedServicio}
                placeholder="Seleccionar Servicio"
                name="SelectServicio"
                onChange={handleChangeServicio}
                required
              />
            </Form.Group>
          )}

          {props.codigoProducto == "rd" && (
            <Form.Group as={Col} controlId="CodigoPostalRemitente">
              <Form.Label>Código postal para el retiro*</Form.Label>
              <Select
                options={options}
                isClearable
                placeholder="Provincia, localidad o CP"
                name="CodigoPostalRemitente"
                onChange={handleChangeRem}
                required
              />
            </Form.Group>
          )}
          {props.codigoProducto == "ea" && (
            <Form.Group as={Col} controlId="CodigoSucursalOrigen">
              <Form.Label>Sucursal de origen*</Form.Label>
              <Select
                options={optionsAgencias}
                isClearable
                placeholder="Provincia, localidad o CP"
                name="CodigoSucursalOrigen"
                onChange={handleChangeSucursalOrigen}
                required
              />
            </Form.Group>
          )}
          {props.codigoProducto == "pm" && (
            <Form.Group as={Col} controlId="Calendario">
              <Form.Label>Fecha*</Form.Label>
              <DatePicker
                selected={startDate}
                onChange={date => {
                  setStartDate(date);
                  _getPuntoRecoleccion(date);
                  saveDataInStorage(
                    "datosRemitente",
                    "FechaRetiro",
                    moment(date).format("DD/MM/YYYY")
                  );
                }}
                dateFormat="dd/MM/yyyy"
                excludeDates={findesyferiados}
                minDate={
                  new Date().getDay() == 5
                    ? new Date()
                    : new Date().getDay() == 6
                    ? addDays(new Date(), 2)
                    : new Date().getDay() == 0
                    ? addDays(new Date(), 1)
                    : new Date()
                }
                maxDate={
                  new Date().getDay() == 5
                    ? addDays(new Date(), 3)
                    : new Date().getDay() == 6
                    ? addDays(new Date(), 1)
                    : new Date().getDay() == 0
                    ? addDays(new Date(), 1)
                    : addDays(new Date(), 1)
                }

                // selectsRange
                // selectsDisabledDaysInRange
              />
            </Form.Group>
          )}
          {props.codigoProducto == "pm" && (
            <Form.Group as={Col} controlId="CodigoPuntoRecoleccion">
              <Form.Label>Punto de recoleccion*</Form.Label>
              <Dropdown
                className="dd"
                show={show}
                onToggle={show => setShow(show)}
              >
                <Dropdown.Toggle>
                  {" "}
                  {descPuntoRecoleccion != null
                    ? descPuntoRecoleccion
                    : "Seleccione punto de recolección"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {/* variant="dark" */}
                  <div className="table-paradas">
                    <Table className="unstyledTable" hover borderless>
                      <thead>
                        <tr>
                          <td>Descripcion</td>
                          <td>Direccion</td>
                          <td>Desde</td>
                          <td>Hasta</td>
                        </tr>
                      </thead>
                      <tbody>
                        {optionsArrayPickupMovil != null
                          ? optionsArrayPickupMovil.map(data => {
                              return (
                                <tr
                                  key={data.IdParadasMovil}
                                  onClick={() => {
                                    handleChangeRecoleccion(data);
                                    selectValue(data);
                                    setShow(false);
                                  }}
                                >
                                  <td>{data.Descripcion}</td>
                                  <td>{data.Direccion}</td>
                                  <td>{data.HorarioDesde}</td>
                                  <td>{data.HorarioHasta}</td>
                                </tr>
                              );
                            })
                          : () => {
                              return <tr></tr>;
                            }}
                      </tbody>
                    </Table>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              {/* <span>{`Selected value is: ${selectedValue?.additionalInfo}`}</span> */}
              {/* </Stack> */}
              <Form.Control.Feedback
                type="invalid"
                style={{ display: codRecError }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
              {/* <span style={{ color: "#dc3545", fontSize: 13 }}>
                {msjPickupMovil}
              </span> */}
            </Form.Group>
          )}
          {(props.codigoProducto == "rd" ||
            props.codigoProducto == "ea" ||
            props.codigoProducto == "pm") && (
            <Form.Group as={Col} controlId="CodigoPostalDestinatario">
              <Form.Label>Código postal para el envío*</Form.Label>
              <Select
                options={options}
                isClearable
                placeholder="Provincia, localidad o CP"
                name="CodigoPostalDestinatario"
                onChange={handleChangeDest}
                required
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: codDestError }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </Form.Row>
        {/* <h4>Describí tu envío</h4> */}

        <Form.Label>Describí tu envío</Form.Label>

        <Form.Row>
          <Form.Group as={Col} controlId="TestIcoLabel"></Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="NumeroBultos">
            <Form.Label>Bultos*</Form.Label>
            <Form.Row>
              <div className="col-xs-2 col-sm-3 col-lg-3">
                <img src={bulto_ico} alt="bulto" className="icono" />
              </div>
              <div className="col">
                <Form.Control
                  type="text"
                  placeholder="Ej: 1, 2, 3"
                  name="NumeroBultos"
                  required
                  value={NumeroBultos}
                  onChange={handleSubmit}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: campos.NumeroBultos }}
                >
                  Campo obligatorio.
                </Form.Control.Feedback>
              </div>
            </Form.Row>
            <span style={{ color: "#dc3545", fontSize: 13 }}>{msjBultos}</span>
          </Form.Group>

          <Form.Group as={Col} controlId="Kilos">
            {props.codigoProducto == "rd" && (
              <Form.Label>Peso* (max. 50kg)</Form.Label>
            )}
            {props.codigoProducto == "ea" && <Form.Label>Peso*</Form.Label>}
            {props.codigoProducto == "pm" && (
              <Form.Label>Peso* (max. 15kg)</Form.Label>
            )}

            <Form.Row>
              <div className="col-xs-2 col-sm-3 col-lg-3">
                <img src={peso_ico} alt="peso" className="icono" />
              </div>
              <div className="col">
                <Form.Control
                  type="text"
                  placeholder="Ej: 1, 2, 3"
                  name="Kilos"
                  value={Kilos}
                  required
                  onChange={handleSubmit}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: campos.Kilos }}
                >
                  Campo obligatorio, no superar el máximo.
                </Form.Control.Feedback>
              </div>
            </Form.Row>
            <span style={{ color: "#dc3545", fontSize: 13 }}>{msjKgEnvio}</span>
          </Form.Group>

          <Form.Group as={Col} controlId="Alto">
            {props.codigoProducto == "rd" && (
              <Form.Label>Alto (cm)*</Form.Label>
            )}
            {props.codigoProducto == "ea" && (
              <Form.Label>Alto (cm)*</Form.Label>
            )}
            {props.codigoProducto == "pm" && (
              <Form.Label>Alto (max. 25cm)*</Form.Label>
            )}
            <Form.Row>
              <div className="col-xs-2 col-sm-3 col-lg-3">
                <img src={alto_ico} alt="alto" className="icono" />
              </div>
              <div className="col">
                <Form.Control
                  type="text"
                  placeholder="Ej: 5, 6, 7"
                  name="Alto"
                  required
                  value={Alto}
                  onChange={handleSubmit}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: campos.Alto }}
                >
                  Campo obligatorio, mínimo 5cm.
                </Form.Control.Feedback>
              </div>
            </Form.Row>
            <span style={{ color: "#dc3545", fontSize: 13 }}>
              {msjDimensionAlto}
            </span>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="Ancho">
            {props.codigoProducto == "rd" && (
              <Form.Label>Ancho (cm)*</Form.Label>
            )}
            {props.codigoProducto == "ea" && (
              <Form.Label>Ancho (cm)*</Form.Label>
            )}
            {props.codigoProducto == "pm" && (
              <Form.Label>Ancho (max. 40cm)*</Form.Label>
            )}
            <Form.Row>
              <div className="col-xs-2 col-sm-3 col-lg-3">
                <img src={ancho_ico} alt="ancho" className="icono" />
              </div>
              <div className="col">
                <Form.Control
                  type="text"
                  placeholder="Ej: 5, 6, 7"
                  name="Ancho"
                  required
                  value={Ancho}
                  onChange={handleSubmit}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: campos.Ancho }}
                >
                  Campo obligatorio, mínimo 5cm.
                </Form.Control.Feedback>
              </div>
            </Form.Row>
            <span style={{ color: "#dc3545", fontSize: 13 }}>
              {msjDimensionAncho}
            </span>
          </Form.Group>

          <Form.Group as={Col} controlId="Largo">
            {props.codigoProducto == "rd" && (
              <Form.Label>Profundidad (cm)*</Form.Label>
            )}
            {props.codigoProducto == "ea" && (
              <Form.Label>Profundidad (cm)*</Form.Label>
            )}
            {props.codigoProducto == "pm" && (
              <Form.Label>Profundidad (max. 45cm)*</Form.Label>
            )}
            <Form.Row>
              <div className="col-xs-2 col-sm-3 col-lg-3">
                <img
                  src={profundidad_ico}
                  alt="profundidad"
                  className="icono"
                />
              </div>
              <div className="col">
                <Form.Control
                  type="text"
                  placeholder="Ej: 5, 6, 7"
                  name="Largo"
                  required
                  value={Largo}
                  onChange={handleSubmit}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: campos.Largo }}
                >
                  Campo obligatorio, mínimo 5cm.
                </Form.Control.Feedback>
              </div>
            </Form.Row>
            <span style={{ color: "#dc3545", fontSize: 13 }}>
              {msjDimensionLargo}
            </span>
          </Form.Group>

          <Form.Group as={Col} controlId="ImporteValorDeclarado">
            <Form.Label>Valor declarado*</Form.Label>
            <Form.Row>
              <div className="col-xs-2 col-sm-3 col-lg-3">
                <img src={valor_declarado_ico} alt="valor" className="icono" />
              </div>
              <div className="col">
                <Form.Control
                  type="text"
                  placeholder={
                    "Min $" +
                    importesRango.Minimo +
                    "- Max $" +
                    importesRango.Maximo
                  }
                  name="ImporteValorDeclarado"
                  required
                  value={ImporteValorDeclarado}
                  onChange={handleSubmit}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: campos.ImporteValorDeclarado }}
                >
                  Campo obligatorio.
                </Form.Control.Feedback>
              </div>
            </Form.Row>
            <span style={{ color: "#dc3545", fontSize: 13 }}>{importe}</span>
          </Form.Group>
        </Form.Row>

        {props.codigoProducto == "pm" && (
          <Form.Group>
            <Form.Row>
              <Form.Label>Seleccione forma de pago*</Form.Label>
            </Form.Row>
            <Form.Row>
              {/* <p>Seleccione forma de pago*</p> */}
              <Form.Check
                inline
                defaultChecked={true}
                label="Pago en origen"
                name="pago"
                type="radio"
                id="origen"
                value="P"
                onChange={handleSubmitRadio}
              ></Form.Check>
              <Form.Control.Feedback
                type="invalid"
                style={{ display: pagoError }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Row>
          </Form.Group>
        )}
        {props.codigoProducto != "pm" && (
          <Form.Group>
            <Form.Row>
              <Form.Label>Seleccione forma de pago*</Form.Label>
            </Form.Row>
            <Form.Row>
              <Form.Check
                inline
                label="Pago en origen"
                name="pago"
                type="radio"
                id="origen"
                value="P"
                onChange={handleSubmitRadio}
              ></Form.Check>
              <Form.Check
                inline
                label="Pago en destino"
                name="pago"
                type="radio"
                id="destino"
                value="D"
                onChange={handleSubmitRadio}
              ></Form.Check>
              <Form.Control.Feedback
                type="invalid"
                style={{ display: pagoError }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Row>
          </Form.Group>
        )}

        {/* {props.codigoProducto != "ea" && (
          <Form.Row>
            <Col md={12}>
              <span style={{ color: "#6db568", fontSize: "1.2rem" }}>
                *Estimado cliente, tenga en cuenta que solo puede despachar en
                la sucursal seleccionada de origen
              </span>
            </Col>
          </Form.Row>
        )} */}
        <Form.Row>
          <Col md={{ span: 3, offset: 11 }}>
            {boton && (
              <Button variant="primary" onClick={handleBlockedButtom} disabled>
                Siguiente
              </Button>
            )}
            {!boton && (
              <Button variant="primary" onClick={nextStepHandler}>
                Siguiente
              </Button>
            )}
          </Col>
        </Form.Row>
      </Form>
    );
  } else {
    return <div></div>;
  }
}
export default Formulario;
//render(<Formulario />);
