import React, { Component } from "react";
import { node, string } from "prop-types";
import "./styles.css";

class Collapse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
  }

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    let { title, children } = this.props;
    let { open } = this.state;

    return (
      <div className="match-detail">
        <h1 className="match-title" onClick={this.toggle}>
          {title}
          {open ? (
            <i className={"material-icons"}>keyboard_arrow_down</i>
          ) : (
            <i className={"material-icons"}>keyboard_arrow_up</i>
          )}
        </h1>
        {open && children}
      </div>
    );
  }
}

Collapse.propTyoes = {
  children: node,
  title: string
};

export default Collapse;
