import React, { Component, useState, useEffect, useRef } from "react";
import {
  Accordion,
  Card,
  Col,
  Button,
  Form,
  CardGroup,
  Transitions,
  Collapse,
  InputGroup
} from "react-bootstrap";

import "./../styles.css";

function PagoProcesado(props) {
  useEffect(() => {
    props.ocultarConfirmar();
  }, []);
  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ textAlign: "center" }}>
        {
          <img
            src="/images/logo-via-cargo-verde.png"
            alt="Placeholder image"
            width="150"
            className="img-fluid"
          />
        }
        <hr />
        <h5 className="card-title" id="tituloventana">
          {props.correcto && <strong>Orden confirmada</strong>}
          {!props.correcto && <strong>Orden NO confirmada</strong>}
        </h5>
      </div>
      {props.correcto && (
        <p className="card-text">
          Su solicitud se envió correctamente. Gracias.
        </p>
      )}
      {!props.correcto && (
        <p className="card-text">
          Su solicitud no pudo ser enviada. <br /> <b>{props.pagoFallo}</b>
        </p>
      )}

      {
        <img
          src="/images/orden-confirmada.png"
          alt="Placeholder image"
          width="200"
          className="img-fluid"
        />
      }

      <br />
      <br />

      <p className="card-text"> Estimado, {props.senderFullName} </p>

      {props.correcto && (
        <p className="card-text">
          Tu pedido fué registrado con éxito, te enviaremos un e-mail con los
          datos del envío y la etiqueta correspondiente que deberás imprimir y
          colocar en el paquete antes de acercarte al punto del pickup.
        </p>
      )}

      {!props.correcto && (
        <p className="card-text">
          Hemos tenido problema al procesar su solicitud.
        </p>
      )}

      <p className="card-text">
        Gracias por confiar en <strong>Via Cargo.</strong>
      </p>
      <Button
        variant="primary"
        onClick={props.volverForm}
        style={{ maxHeight: 38 }}
      >
        Volver
      </Button>
      {/*Tarea GDM-1031*/}
    </div>
  );
}
export default PagoProcesado;
