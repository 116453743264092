import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  Link,
  Redirect,
  withRouter,
  BrowserRouter as Router,
  Route
} from "react-router-dom";

import agencias from "assets/sidebar/agencias.png";
import contacto from "assets/sidebar/contacto.png";
import cotizacion from "assets/sidebar/cotizacion.png";
import packs from "assets/sidebar/packs.png";
import rastreo from "assets/sidebar/rastreo.png";
import envio from "assets/sidebar/envios.png";
import arrow from "assets/icons/flecha.svg";
import menos from "assets/icons/menos.png";
import mas from "assets/icons/mas.png";
import pickup from "assets/icons/pickup_movil.png";
import pickup_web from "assets/icons/pickup_web.png";
import entrega_agencia from "assets/icons/entrega_agencia.png";
import banner from "assets/side-banner.jpg";
import "./styles.css";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      checked: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeServicio = this.handleChangeServicio.bind(this);
  }

  handleChange(checked) {
    if (this.state.checked) {
      this.setState({ checked: false });
    } else {
      this.setState({ checked: true });
    }
  }

  handleChangeServicio(code) {
    switch (code) {
      case "rd":
        window.location.href = "/product/rd";
        break;
      case "ea":
        window.location.href = "/product/ea";
        break;
      case "pm":
        window.location.href = "/product/pm";
        break;
    }
  }

  _handleInput = ev => {
    let value = ev.target.value;

    if (value.length > 12) {
      return false;
    }

    this.setState({ code: value });
  };

  _submitSearch = ev => {
    ev.preventDefault();

    let { code } = this.state;
    this.props.history.push(`/tracking${code !== "" ? `/${code}` : ""}`);
  };

  _handleChangeServicio = (ev, code) => {
    ev.preventDefault();
    switch (code) {
      case "rd":
        window.location.href = "/product/rd";
        break;
      case "ea":
        window.location.href = "/product/ea";
        break;
      case "pm":
        window.location.href = "/product/pm";
        break;
    }
  };

  render() {
    let { code } = this.state.code;

    return (
      <div className="sidebar floating-sidebar">
        <div className="sidebar-title">
          <h1>Acceso rápido</h1>
        </div>

        <Link to={`/tracking${code !== "" ? `/${code}` : ""}`}>
          <div className="side-item">
            <img className="side-img" src={rastreo} alt="Tracking" />
            <span className="side-title">Seguimiento de tu envío</span>
            <img className="side-img arrow" src={arrow} alt="Next" />
          </div>
        </Link>

        <div className="side-item">
          <form onSubmit={this._submitSearch} style={{ width: "100%" }}>
            <input
              type="text"
              value={code}
              onChange={this._handleInput}
              className="tracking-input"
            />
            <button type={"submit"} style={{ display: "none" }} />
          </form>
        </div>

        {/* <Link to={"/pickup-home"}> */}
        <div
          className="side-item separator dropcl"
          onClick={this.handleChange} /*checked={this.state.checked}*/
        >
          <img className="side-img" src={envio} alt="Generar Envíos" />
          <span className="side-title">Generar Envíos</span>
          <img
            className="side-img arrow"
            src={this.state.checked ? menos : mas}
            alt="Next"
          />
        </div>
        {/* </Link> */}

        {/* <Link to={"/product/rd"}> */}
        {/* <div onClick={this._handleChangeServicio("rd")}> */}
        <div
          class="pointer-link"
          onClick={ev => this._handleChangeServicio(ev, "rd")}
        >
          <div
            className="side-item separator"
            hidden={this.state.checked ? false : true}
          >
            <img className="side-img" src={pickup_web} alt="Retiros web" />
            <span className="side-title">Retiro en domicilio</span>
            <img className="side-img arrow" src={arrow} alt="Next" />
          </div>
        </div>
        {/* </Link> */}

        {/* <Link to={"/product/ea"}> */}
        <div
          class="pointer-link"
          onClick={ev => this._handleChangeServicio(ev, "ea")}
        >
          <div
            className="side-item separator"
            hidden={this.state.checked ? false : true}
          >
            <img
              className="side-img"
              src={entrega_agencia}
              alt="Entrega en agencia"
            />
            <span className="side-title">Entrega en agencia</span>
            <img className="side-img arrow" src={arrow} alt="Next" />
          </div>
        </div>
        {/* </Link> */}

        {/* <Link to={"/product/pm"}> */}
        {/* <div
            class="pointer-link"
            onClick={ev => this._handleChangeServicio(ev, "pm")}
          > */}
        <div
          className="side-item separator"
          hidden={this.state.checked ? false : true}
        >
          <img className="side-img" src={pickup} alt="Pickup movil" />
          <span className="side-title">Pickup móvil</span>
          <img className="side-img arrow" src={arrow} alt="Next" />
        </div>
        {/* </div> */}
        {/* </Link> */}

        <Link to={"/agencies"}>
          <div className="side-item separator">
            <img className="side-img" src={agencias} alt="Agencias" />
            <span className="side-title">Agencias</span>
            <img className="side-img arrow" src={arrow} alt="Next" />
          </div>
        </Link>

        <Link to={"/quote"}>
          <div className="side-item separator">
            <img className="side-img" src={cotizacion} alt="Cotizar" />
            <span className="side-title">Cotizar Envíos</span>
            <img className="side-img arrow" src={arrow} alt="Next" />
          </div>
        </Link>

        <Link to="/tips">
          <div className="side-item separator">
            <img className="side-img" src={packs} alt="Packs" />
            <span className="side-title">Tips</span>
            <img className="side-img arrow" src={arrow} alt="Next" />
          </div>
        </Link>

        {/* <Link to="/support">
          <div className="side-item separator">
            <img className="side-img" src={contacto} alt="Contacto" />
            <span className="side-title">Contacto</span>
            <img className="side-img arrow" src={arrow} alt="Next" />
          </div>
        </Link> */}

        <div></div>
      </div>
    );
  }
}

export default withRouter(Sidebar);
