import React, { Component, useState, render } from "react";
import {
  Accordion,
  Card,
  Col,
  Button,
  Form,
  CardGroup,
  Transitions,
  Collapse,
  InputGroup
} from "react-bootstrap";

import {
  isValidEmail,
  returnOnlyNumbers1,
  isPositiveNumber
} from "../../../../../utils/index";

import "./../styles.css";

function DatosRemitente(props) {
  const [validated, setValidated] = useState(false);
  const [tipoServicio, setTipoServicio] = useState(0);
  const [active, setActive] = useState("");
  const handleDatosRemitente = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (!boton) {
      siguiente();
    }
  };
  const [NombreRemitente, setNombreRemitente] = useState("");
  const [DireccionRemitente, setDireccionRemitente] = useState("");
  const [Numero, setNumero] = useState("");
  const [CodigoPostal, setCodigoPostal] = useState("");
  const [Piso, setPiso] = useState("");
  const [DatosAdicional, setDatosAdicional] = useState("");
  const [NroDocRemitente, setNroDocRemitente] = useState("");
  const [TelefonoRemitente, setTelefonoRemitente] = useState("");
  const [EmailRemitente, setEmailRemitente] = useState("");
  const [correoValidar, setCorreoValidar] = useState(false);

  const [boton, setBoton] = useState(true);

  const [campos, setCampos] = useState({
    NombreRemitente: "none",
    DireccionRemitente: "none",
    Numero: "none",
    CodigoPostal: "none",
    Piso: "none",
    DatosAdicional: "none",
    NroDocRemitente: "none",
    TelefonoRemitente: "none",
    EmailRemitente: "none"
  });

  const [values, setValues] = useState({
    NombreRemitente: "",
    DireccionRemitente: "",
    Numero: "",
    CodigoPostal: "",
    Piso: "",
    DatosAdicional: "",
    NroDocRemitente: "",
    TelefonoRemitente: "",
    EmailRemitente: ""
  });

  let servicios = "{}";

  if (props.tiposServicios) {
    servicios = props.tiposServicios;
  }

  const asignarParametros = (Id, Codigo, Total) => {
    let valores = { Id: Id, Codigo: Codigo, Total: Total };

    props.parametroSegundoForm(valores);
  };

  const handleSubmit = event => {
    const form =
      event.currentTarget.parentNode[0] !== undefined
        ? event.currentTarget.parentNode
        : event.currentTarget.parentNode.parentNode;
    var nuevosCampos = campos;

    if (event.currentTarget.parentNode[0] !== undefined) {
      setNombreRemitente(validarTexto(form[0].value));
      setDireccionRemitente(validarTexto(form[1].value));
      setNumero(validarEntero(form[2].value));
      //setPiso(validarEntero(form[3].value))
      setPiso("none");
      setCodigoPostal(validarEntero(form[4].value));
      setDatosAdicional(validarTexto(form[5].value));
      setNroDocRemitente(validarEntero(form[6].value));
      setTelefonoRemitente(validarEntero(form[7].value));
      setEmailRemitente(isValidEmail(form[8].value));
      //setpago(validarEntero(form[0].value))

      var nuevosCampos = {
        NombreRemitente: validarTexto(form[0].value),
        DireccionRemitente: validarTexto(form[1].value),
        Numero: validarEntero(form[2].value),
        Piso: "none",
        CodigoPostal: validarEntero(form[4].value),
        DatosAdicional: validarTexto(form[5].value),
        NroDocRemitente: validarEntero(form[6].value),
        TelefonoRemitente: validarEntero(form[7].value),
        EmailRemitente: isValidEmail(form[8].value)
      };
      setCampos(nuevosCampos);

      if (form.checkValidity() === false) {
        setBoton(true);
        event.preventDefault();
        event.stopPropagation();
      } else {
        setBoton(false);

        setValues({
          NombreRemitente: form[0].value,
          DireccionRemitente: form[1].value,
          Numero: form[2].value,
          Piso: form[3].value,
          CodigoPostal: form[4].value,
          DatosAdicional: form[5].value,
          NroDocRemitente: form[6].value,
          TelefonoRemitente: form[7].value,
          EmailRemitente: form[8].value
        });
        event.preventDefault();
        event.stopPropagation();
      }
    } else {
      const form =
        event.currentTarget.parentNode.parentNode.parentNode[0] !== undefined
          ? event.currentTarget.parentNode.parentNode.parentNode
          : event.currentTarget.parentNode.parentNode.parentNode.parentNode;

      let numerosEnteros = [
        "NroDocRemitente",
        "TelefonoRemitente",
        "CodigoPostal",
        "Numero"
      ];
      let numerosDecimal = [];
      let palabras = ["NombreRemitente", "DireccionRemitente", "Piso"];
      let correo = ["EmailRemitente"];

      let validar = "";

      if (numerosEnteros.includes(event.currentTarget.name)) {
        validar = returnOnlyNumbers1(event.currentTarget.value);
        if (validar.length) validar = validar.replace(/,/g, "");
        event.currentTarget.value = validar;
        setCampos({
          ...campos,
          [event.currentTarget.name]: validarEntero(validar)
        });
      } else {
        if (palabras.includes(event.currentTarget.name)) {
          validar = validarTexto(event.currentTarget.value);
          setCampos({
            ...campos,
            [event.currentTarget.name]: validar
          });
        } else {
          if (correo.includes(event.currentTarget.name)) {
            validar = isValidEmail(event.currentTarget.value);
            setCorreoValidar(validar);
            setCampos({
              ...campos,
              [event.currentTarget.name]: validar ? "none" : "block"
            });
          }
        }
      }

      if (form.checkValidity() === false || !correoValidar || !validar) {
        setBoton(true);
        event.preventDefault();
        event.stopPropagation();
      } else {
        setBoton(false);

        setValues({
          NombreRemitente: form[0].value,
          DireccionRemitente: form[1].value,
          Numero: form[2].value,
          Piso: form[3].value,
          CodigoPostal: form[4].value,
          DatosAdicional: form[5].value,
          NroDocRemitente: form[6].value,
          TelefonoRemitente: form[7].value,
          EmailRemitente: form[8].value
        });

        let valores = {
          NombreRemitente: form[0].value,
          DireccionRemitente: form[1].value,
          Numero: form[2].value,
          Piso: form[3].value,
          CodigoPostal: form[4].value,
          DatosAdicional: form[5].value,
          NroDocRemitente: form[6].value,
          TelefonoRemitente: form[7].value,
          EmailRemitente: form[8].value
        };

        props.parametroRemitente(valores);

        event.preventDefault();
        event.stopPropagation();
      }
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const atrasForm2 = () => {
    props.atrasForm2();
  };

  const siguiente = () => {
    props.cuartoForm();
  };

  const validarEntero = valor => {
    //intento convertir a entero.
    //si era un entero no le afecta, si no lo era lo intenta convertir
    valor = parseInt(valor);

    //Compruebo si es un valor numérico
    if (isNaN(valor) || valor < 0) {
      //entonces (no es numero) devuelvo el valor cadena vacia

      return "block";
    } else {
      //En caso contrario (Si era un número) devuelvo el valor
      return "none";
    }
  };

  const validarTexto = valor => {
    if (valor.length === 0) {
      return "block";
    } else {
      return "none";
    }
  };

  return (
    <div>
      <Form noValidate validated={validated} autoComplete="nope">
        {/* <h1 className="product-title-primary">
          Completá los datos del remitente
        </h1> */}
        <p>Los campos marcados con * son obligatorios</p>
        <Form.Row>
          <Col xs={12}>
            <Form.Group as={Col} controlId="NombreRemitente">
              <Form.Label>Nombre completo del remitente*</Form.Label>
              <Form.Control
                type="text"
                name="NombreRemitente"
                placeholder="Como figura en el documento"
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.NombreRemitente }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={4}>
            <Form.Group as={Col} controlId="DireccionRemitente">
              <Form.Label>Dirección*</Form.Label>
              <Form.Control
                type="text"
                name="DireccionRemitente"
                placeholder="Ejemplo: Avenida de los Incas"
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.DireccionRemitente }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} controlId="Numero">
              <Form.Label>Número*</Form.Label>
              <Form.Control
                type="text"
                name="Numero"
                placeholder="Ejemplo: 1652"
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.Numero }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group as={Col} controlId="Piso">
              <Form.Label>Piso/Depto</Form.Label>
              <Form.Control
                type="text"
                name="Piso"
                placeholder="Ejemplo: 6A"
                onChange={handleSubmit}
              />
              {/* <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.Piso }}
              >
                Campo obligatorio.
              </Form.Control.Feedback> */}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} controlId="CodigoPostal">
              <Form.Label>Código postal*</Form.Label>
              <Form.Control
                type="text"
                name="CodigoPostal"
                placeholder="Ejemplo: 1900, 1920, 1930, etc"
                readOnly
                value={props.CodigoPostalRemitente}
                onChange={handleSubmit}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={4}>
            <Form.Group as={Col} controlId="DatosAdicional">
              <Form.Label>Observaciones</Form.Label>
              <Form.Control
                type="text"
                name="DatosAdicional"
                placeholder=""
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.DatosAdicional }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col>
            <Form.Group as={Col} controlId="NroDocRemitente">
              <Form.Label>DNI/CUIL/CUIT del remitente*</Form.Label>
              <Form.Control
                type="text"
                name="NroDocRemitente"
                placeholder="Ejemplo: 21851985"
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.NroDocRemitente }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} controlId="TelefonoRemitente">
              <Form.Label>Teléfono del remitente*</Form.Label>
              <Form.Control
                type="text"
                name="TelefonoRemitente"
                placeholder="Ejemplo: 0112345678"
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.TelefonoRemitente }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} controlId="EmailRemitente">
              <Form.Label>Email del remitente*</Form.Label>
              <Form.Control
                type="text"
                name="EmailRemitente"
                placeholder=""
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.EmailRemitente }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>

        <Button variant="secondary" onClick={atrasForm2}>
          Anterior
        </Button>
        <br />
        {boton && (
          <Button variant="primary" onClick={handleDatosRemitente}>
            Siguiente
          </Button>
        )}
        {!boton && (
          <Button variant="primary" onClick={siguiente}>
            Siguiente
          </Button>
        )}
      </Form>
    </div>
  );
}
export default DatosRemitente;
