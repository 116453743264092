import React, { Component, useState, render, useEffect } from "react";
import {
  Accordion,
  Card,
  Col,
  Button,
  Form,
  CardGroup,
  Transitions,
  Collapse,
  InputGroup
} from "react-bootstrap";
import Select from "react-select";

import {
  isValidEmail,
  returnOnlyNumbers1,
  isPositiveNumber
} from "../../../../../utils/index";

import "./../styles.css";

function DatosDestinatario(props) {
  const [validated, setValidated] = useState(false);
  const [tipoServicio, setTipoServicio] = useState(0);
  const [active, setActive] = useState("");
  const handleDatosDestinatario = event => {
    const form = event.currentTarget;
    //console.log("handleDatosDestinatario: " + JSON.stringify(values));
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (!boton) {
      siguiente();
    }
  };
  const [NombreDestinatario, setNombreDestinatario] = useState("");
  const [DireccionDestinatario, setDireccionDestinatario] = useState("");
  const [NumeroDestinatario, setNumeroDestinatario] = useState("");
  const [CodigoPostal, setCodigoPostal] = useState("");
  const [PisoDestinatario, setPiso] = useState("");
  const [DeptoDestinatario, setDeptoDestinatario] = useState("");
  const [NroDocDestinatario, setNroDocDestinatario] = useState("");
  const [CodPaisDestinatario, setCodPaisDestinatario] = useState(54);
  const [codPaisDest, setCodPaisDest] = useState(54);
  const [CodAreaDestinatario, setCodAreaDestinatario] = useState("");
  const [NroCelularDestinatario, setTelefonoDestinatario] = useState("");
  const [EmailDestinatario, setEmailDestinatario] = useState("");
  const [DatosAdicional, setDatosAdicional] = useState("");
  const [correoValidar, setCorreoValidar] = useState(false);

  const [boton, setBoton] = useState(true);

  const [campos, setCampos] = useState({
    NombreDestinatario: "none",
    DireccionDestinatario: "none",
    NumeroDestinatario: "none",
    CodigoPostal: "none",
    PisoDestinatario: "none",
    DeptoDestinatario: "none",
    NroDocDestinatario: "none",
    CodPaisDestinatario: "none",
    CodAreaDestinatario: "none",
    NroCelularDestinatario: "none",
    EmailDestinatario: "none",
    DatosAdicional: "none"
  });

  const [values, setValues] = useState({
    NombreDestinatario: "",
    DireccionDestinatario: "",
    NumeroDestinatario: "",
    CodigoPostal: "",
    PisoDestinatario: "",
    DeptoDestinatario: "",
    NroDocDestinatario: "",
    CodPaisDestinatario: "",
    CodAreaDestinatario: "",
    NroCelularDestinatario: "",
    EmailDestinatario: "",
    DatosAdicional: ""
  });

  const optionsCodPais = [
    { value: "54", label: "Arg +54" },
    { value: "55", label: "Bra +55" },
    { value: "56", label: "Chi +56" },
    { value: "591", label: "Bol +591" },
    { value: "595", label: "Par +595" },
    { value: "57", label: "Col +57" },
    { value: "51", label: "Per +51" },
    { value: "598", label: "Uru +598" },
    { value: "58", label: "Ven +58" }
  ];

  let servicios = "{}";

  if (props.tiposServicios) {
    servicios = props.tiposServicios;
    // setCodPaisDest(54);
    // setCodPaisDestinatario(54);
  }

  const asignarParametros = (Id, Codigo, Total) => {
    let valores = { Id: Id, Codigo: Codigo, Total: Total };

    props.parametroSegundoForm(valores);
  };

  const handleSubmit = event => {
    // const form =
    //   event.currentTarget.parentNode[0] !== undefined
    //     ? event.currentTarget.parentNode
    //     : event.currentTarget.parentNode.parentNode;
    var nuevosCampos = campos;

    var form = document.getElementById("form-destinatario");
    var valueNombreDestinatario = document.getElementById("form-destinatario")
      .elements["NombreDestinatario"].value;
    var valueDireccionDestinatario = document.getElementById(
      "form-destinatario"
    ).elements["DireccionDestinatario"].value;
    var valueNumeroDestinatario = document.getElementById("form-destinatario")
      .elements["NumeroDestinatario"].value;
    var valuePisoDestinatario = document.getElementById("form-destinatario")
      .elements["PisoDestinatario"].value;
    var valueDeptoDestinatario = document.getElementById("form-destinatario")
      .elements["DeptoDestinatario"].value;
    var valueCodigoPostal = document.getElementById("form-destinatario")
      .elements["CodigoPostal"].value;
    var valueNroDocDestinatario = document.getElementById("form-destinatario")
      .elements["NroDocDestinatario"].value;
    var valueCodAreaDestinatario = document.getElementById("form-destinatario")
      .elements["CodAreaDestinatario"].value;
    var valueNroCelularDestinatario = document.getElementById(
      "form-destinatario"
    ).elements["NroCelularDestinatario"].value;
    var valueEmailDestinatario = document.getElementById("form-destinatario")
      .elements["EmailDestinatario"].value;
    var valueDatosAdicional = document.getElementById("form-destinatario")
      .elements["DatosAdicional"].value;

    //if (event.currentTarget.parentNode[0] !== undefined) {
    if (form !== undefined) {
      setNombreDestinatario(validarTexto(valueNombreDestinatario));
      setDireccionDestinatario(validarTexto(valueDireccionDestinatario));
      setNumeroDestinatario(validarEntero(valueNumeroDestinatario));
      setPiso(validarEntero(valuePisoDestinatario));
      setDeptoDestinatario(validarTexto(valueDeptoDestinatario));
      setCodigoPostal(validarEntero(valueCodigoPostal));
      setNroDocDestinatario(validarEntero(valueNroDocDestinatario));
      //setCodPaisDestinatario(validarTexto(form[7].value));
      setCodPaisDestinatario(validarTexto(codPaisDest));
      setCodAreaDestinatario(validarCodigoArea(valueCodAreaDestinatario));
      setTelefonoDestinatario(
        validarNumeroCelular(valueNroCelularDestinatario)
      );
      setEmailDestinatario(isValidEmail(valueEmailDestinatario));
      setDatosAdicional(valueDatosAdicional);
      //setpago(validarEntero(form[0].value))

      var nuevosCampos = {
        NombreDestinatario: valueNombreDestinatario,
        DireccionDestinatario: valueDireccionDestinatario,
        NumeroDestinatario: validarEntero(valueNumeroDestinatario),
        PisoDestinatario: valuePisoDestinatario,
        DeptoDestinatario: valueDeptoDestinatario,
        CodigoPostal: validarEntero(valueCodigoPostal),
        NroDocDestinatario: validarEntero(valueNroDocDestinatario),
        //CodPaisDestinatario: validarTexto(form[7].value),
        CodPaisDestinatario: validarTexto(codPaisDest),
        CodAreaDestinatario: validarCodigoArea(valueCodAreaDestinatario),
        NroCelularDestinatario: validarNumeroCelular(
          valueNroCelularDestinatario
        ),
        EmailDestinatario: isValidEmail(valueEmailDestinatario),
        DatosAdicional: valueDatosAdicional
      };
      setCampos(nuevosCampos);
      // console.log("handleSubmit nuevosCampos: " + JSON.stringify(nuevosCampos));
      // console.log("handleSubmit valueNombreDestinatario: " + JSON.stringify(valueNombreDestinatario));
      // console.log("handleSubmit valueDireccionDestinatario: " + JSON.stringify(valueDireccionDestinatario));
      if (form.checkValidity() === false) {
        setBoton(true);
      } else {
        setBoton(false);

        setValues({
          NombreDestinatario: valueNombreDestinatario,
          DireccionDestinatario: valueDireccionDestinatario,
          NumeroDestinatario: validarEntero(valueNumeroDestinatario),
          PisoDestinatario: valuePisoDestinatario,
          DeptoDestinatario: valueDeptoDestinatario,
          CodigoPostal: validarEntero(valueCodigoPostal),
          NroDocDestinatario: validarEntero(valueNroDocDestinatario),
          //CodPaisDestinatario: form[7].value,
          CodPaisDestinatario: validarTexto(codPaisDest),
          CodAreaDestinatario: validarCodigoArea(valueCodAreaDestinatario),
          NroCelularDestinatario: validarNumeroCelular(
            valueNroCelularDestinatario
          ),
          EmailDestinatario: isValidEmail(valueEmailDestinatario),
          DatosAdicional: valueDatosAdicional
        });

        let valores = {
          NombreDestinatario: valueNombreDestinatario,
          DireccionDestinatario: valueDireccionDestinatario,
          NumeroDestinatario: valueNumeroDestinatario,
          PisoDestinatario: valuePisoDestinatario,
          DeptoDestinatario: valueDeptoDestinatario,
          CodigoPostal: valueCodigoPostal,
          NroDocDestinatario: valueNroDocDestinatario,
          //CodPaisDestinatario: form[7].value,
          CodPaisDestinatario: codPaisDest,
          CodAreaDestinatario: valueCodAreaDestinatario,
          NroCelularDestinatario: valueNroCelularDestinatario,
          EmailDestinatario: valueEmailDestinatario,
          DatosAdicional: valueDatosAdicional
        };

        props.parametroDestinatario(valores);
      }
      event.preventDefault();
      event.stopPropagation();
    } else {
      const form =
        event.currentTarget.parentNode.parentNode.parentNode[0] !== undefined
          ? event.currentTarget.parentNode.parentNode.parentNode
          : event.currentTarget.parentNode.parentNode.parentNode.parentNode;

      let numerosEnteros = [
        "NroDocDestinatario",
        "NroCelularDestinatario",
        "CodigoPostal",
        "NumeroDestinatario"
      ];
      let numerosDecimal = [];
      let palabras = [
        "NombreDestinatario",
        "DireccionDestinatario",
        "PisoDestinatario",
        "DeptoDestinatario",
        "CodPaisDestinatario",
        "CodAreaDestinatario"
      ];
      let correo = ["EmailDestinatario"];

      let validar = "";

      if (numerosEnteros.includes(event.currentTarget.name)) {
        validar = returnOnlyNumbers1(event.currentTarget.value);
        if (validar.length) validar = validar.replace(/,/g, "");
        event.currentTarget.value = validar;
        setCampos({
          ...campos,
          [event.currentTarget.name]: validarEntero(validar)
        });
      } else {
        if (palabras.includes(event.currentTarget.name)) {
          validar = validarTexto(event.currentTarget.value);
          setCampos({
            ...campos,
            [event.currentTarget.name]: validar
          });
        } else {
          if (correo.includes(event.currentTarget.name)) {
            validar = isValidEmail(event.currentTarget.value);
            setCorreoValidar(validar);
            setCampos({
              ...campos,
              [event.currentTarget.name]: validar ? "none" : "block"
            });
          }
        }
      }

      if (form.checkValidity() === false || validar != true) {
        setBoton(true);
      } else {
        setBoton(false);

        setValues({
          NombreDestinatario: valueNombreDestinatario,
          DireccionDestinatario: valueDireccionDestinatario,
          NumeroDestinatario: validarEntero(valueNumeroDestinatario),
          PisoDestinatario: valuePisoDestinatario,
          DeptoDestinatario: valueDeptoDestinatario,
          CodigoPostal: validarEntero(valueCodigoPostal),
          NroDocDestinatario: validarEntero(valueNroDocDestinatario),
          //CodPaisDestinatario: form[7].value,
          CodPaisDestinatario: validarTexto(codPaisDest),
          CodAreaDestinatario: validarCodigoArea(valueCodAreaDestinatario),
          NroCelularDestinatario: validarNumeroCelular(
            valueNroCelularDestinatario
          ),
          EmailDestinatario: isValidEmail(valueEmailDestinatario),
          DatosAdicional: valueDatosAdicional
        });

        let valores = {
          NombreDestinatario: valueNombreDestinatario,
          DireccionDestinatario: valueDireccionDestinatario,
          NumeroDestinatario: valueNumeroDestinatario,
          PisoDestinatario: valuePisoDestinatario,
          DeptoDestinatario: valueDeptoDestinatario,
          CodigoPostal: valueCodigoPostal,
          NroDocDestinatario: valueNroDocDestinatario,
          //CodPaisDestinatario: form[7].value,
          CodPaisDestinatario: codPaisDest,
          CodAreaDestinatario: valueCodAreaDestinatario,
          NroCelularDestinatario: valueNroCelularDestinatario,
          EmailDestinatario: valueEmailDestinatario,
          DatosAdicional: valueDatosAdicional
        };

        props.parametroDestinatario(valores);
      }
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const handleChangeCodPais = selectedOption => {
    if (selectedOption.value != null) {
      setCodPaisDest(selectedOption.value);
      setCodPaisDestinatario(validarTexto(selectedOption.value));
    }
  };

  const siguiente = () => {
    // console.log("siguiente values: " + JSON.stringify(values));
    props.quintoForm();
  };

  const atrasForm3 = () => {
    props.atrasForm3();
  };

  const validarEntero = valor => {
    //intento convertir a entero.
    //si era un entero no le afecta, si no lo era lo intenta convertir
    valor = parseInt(valor);

    //Compruebo si es un valor numérico
    if (isNaN(valor) || valor < 0) {
      //entonces (no es numero) devuelvo el valor cadena vacia

      return "block";
    } else {
      //En caso contrario (Si era un número) devuelvo el valor
      return "none";
    }
  };

  const validarNumeroCelular = numero => {
    const regex = /^[0-9]{7,8}$/; // Expresión regular para validar un número de celular de entre 7 u 8 dígitos

    if (regex.test(numero)) {
      return "none";
    } else {
      return "block";
    }
    //return regex.test(numero);
  };

  const validarCodigoArea = numero => {
    const regex = /^[0-9]{1,4}$/; // Expresión regular para validar un número de area de 1 hasta 4 dígitos

    if (regex.test(numero)) {
      return "none";
    } else {
      return "block";
    }
    //return regex.test(numero);
  };

  const validarTexto = valor => {
    if (valor.length === 0) {
      return "block";
    } else {
      return "none";
    }
  };

  return (
    <div>
      <Form
        novalidate
        validated={validated}
        autoComplete="on"
        id="form-destinatario"
      >
        {/* <h1 className="product-title-primary">
          Completá los datos del destinatario
        </h1> */}
        <p>Los campos marcados con * son obligatorios</p>
        <Form.Row>
          <Col xs={12}>
            <Form.Group as={Col} controlId="NombreDestinatario">
              <Form.Label>Nombre completo del destinatario*</Form.Label>
              <Form.Control
                type="text"
                name="NombreDestinatario"
                placeholder="Como figura en el documento"
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.NombreDestinatario }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col xs={12}>
            <Form.Group as={Col} controlId="DireccionDestinatario">
              <Form.Label>Dirección*</Form.Label>
              <Form.Control
                type="text"
                name="DireccionDestinatario"
                placeholder="Ejemplo: Avenida de los Incas"
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.DireccionDestinatario }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          {/* <Col md={4}> */}
          <Col>
            <Form.Group as={Col} controlId="NumeroDestinatario">
              <Form.Label>Número*</Form.Label>
              <Form.Control
                type="text"
                name="NumeroDestinatario"
                placeholder="Ejemplo: 1652"
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.NumeroDestinatario }}
              >
                {" "}
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} controlId="PisoDestinatario">
              <Form.Label>Piso</Form.Label>
              <Form.Control
                type="text"
                name="PisoDestinatario"
                placeholder="Ejemplo: 6"
                onChange={handleSubmit}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} controlId="DeptoDestinatario">
              <Form.Label>Depto</Form.Label>
              <Form.Control
                type="text"
                name="DeptoDestinatario"
                placeholder="Ejemplo: A"
                onChange={handleSubmit}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} controlId="CodigoPostal">
              <Form.Label>Código postal*</Form.Label>
              <Form.Control
                type="text"
                name="CodigoPostal"
                placeholder="Ejemplo: 1900, 1920, 1930, etc"
                readOnly
                value={props.CodigoPostalDestinatario}
                onChange={handleSubmit}
              />
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <Form.Group as={Col} controlId="NroDocDestinatario">
              <Form.Label>DNI/CUIL/CUIT del destinatario*</Form.Label>
              <Form.Control
                type="text"
                name="NroDocDestinatario"
                placeholder="Ejemplo: 21851985"
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.NroDocDestinatario }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group as={Col} controlId="CodPaisDestinatario">
              <Form.Label>Código pais*</Form.Label>
              <Select
                options={optionsCodPais}
                defaultValue={{ value: "54", label: "Arg +54" }}
                isClearable
                placeholder="Seleccione"
                name="CodPaisDestinatario"
                onChange={handleChangeCodPais}
                required
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.CodPaisDestinatario }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group as={Col} controlId="CodAreaDestinatario">
              <Form.Label>Código de área*</Form.Label>
              <Form.Control
                type="text"
                name="CodAreaDestinatario"
                placeholder="Ejemplo: 011"
                pattern="^[0-9]{1,4}$"
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.CodAreaDestinatario }}
              >
                ingrese un código de área válido.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group as={Col} controlId="NroCelularDestinatario">
              <Form.Label>Celular*</Form.Label>
              <Form.Control
                type="text"
                name="NroCelularDestinatario"
                placeholder="Ejemplo: 2345678"
                pattern="^[0-9]{7,8}$"
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.NroCelularDestinatario }}
              >
                Ingrese un numero de celular válido.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col>
            <Form.Group as={Col} controlId="EmailDestinatario">
              <Form.Label>Email del destinatario*</Form.Label>
              <Form.Control
                type="email"
                name="EmailDestinatario"
                placeholder=""
                pattern="^[^@]+@[^@]+\.[a-zA-Z]{1,}$"
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.EmailDestinatario }}
              >
                Ingrese un email válido
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} controlId="DatosAdicional">
              <Form.Label>Observaciones</Form.Label>
              <Form.Control
                type="text"
                name="DatosAdicional"
                placeholder=""
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.DatosAdicional }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={{ span: 3, offset: 10 }}>
            <Button variant="secondary" onClick={atrasForm3}>
              Anterior
            </Button>{" "}
            {boton && (
              <Button variant="primary" onClick={handleDatosDestinatario}>
                Siguiente
              </Button>
            )}
            {!boton && (
              <Button variant="primary" onClick={siguiente}>
                Siguiente
              </Button>
            )}
          </Col>
        </Form.Row>
        {/* <br /> */}
      </Form>
    </div>
  );
}
export default DatosDestinatario;
