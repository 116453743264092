import React from "react";
import Sidebar from "../../../../shared/components/Sidebar/Sidebar";
import background from "../../../../assets/backgrounds/01.jpg";
import "./styles.css";

const JoinUs = () => (
  <section className="screen-contents joinus-screen">
    <div className="background">
      <img src={background} alt="Fondo" />
    </div>
    <div className="details-container">
      <div className="content">
        <div className="content-title">
          <h1>Sumate a la red</h1>
        </div>

        <div className="packs-content">
          <h1>Proximamente.</h1>
        </div>
      </div>
      <Sidebar />
    </div>
  </section>
);

export default JoinUs;
