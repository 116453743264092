import React from "react";
import "./styles.css";
import Button from "../../../../../../shared/components/Button/Button";

const Banner = () => (
  <section className="banner">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <h1 className="info-title">Servicio Pickup móvil</h1>

          <p>Mirá los puntos de pickup y seguí el recorrido en tiempo real.</p>
        </div>
        <div class="col-md-4 row-button">
          {/* <a href={"/product/pm"} class="banner-button"> */}
          <div class="banner-button">Contratá el servicio</div>
          {/* </a> */}
          <br />
          {/* <a href={"/pickup-tracing"} target="_blank" class="banner-button"> */}
          <div target="_blank" class="banner-button">
            Seguí el recorrido
          </div>
          {/* </a> */}
        </div>
      </div>
    </div>
  </section>
);

export default Banner;
