import React from "react";

import "./styles.css";

export const userRegister = ({
  submitForm,

  nameHandler,
  nameError,

  lastNameHandler,
  lastNameError,

  emailHandler,
  emailError,

  numberDniHandler,
  numberDniError,

  addressHandler,
  addressError,

  numberAddressHandler,
  numberAddressError,

  codeAddressHandler,
  codeAddressError,

  codeAreaHandler,
  codeAreaError,

  phoneHandler,
  phoneError,

  passwordHandler,
  passwordError,

  confirmPasswordHandler,
  confirmPasswordError,

  activar,
  activarMensaje
}) => {
  if (activar) {
    return (
      <section className="screen-contents contact-screen">
        <div className="details-container">
          <div className="content">
            <div className="container form-container">
              <div className="content titulo-formulario">
                <h3>01-Registro de usuario.</h3>
              </div>
              <div className="containerFormm">
                <div className="formulario">
                  <div className="campo">
                    <div className="campoNa">
                      <label>Nombre</label>
                      <br />
                      <input
                        className="input-nombre"
                        type="text"
                        name="name"
                        onChange={nameHandler}
                      />
                      <div className="validacion-error">
                        <p>{nameError}</p>
                      </div>
                    </div>
                    <div className="campoNa">
                      <label>Apellido</label>
                      <br />
                      <input
                        type="text"
                        name="name"
                        onChange={lastNameHandler}
                      />
                      <div className="validacion-error">
                        <p>{lastNameError}</p>
                      </div>
                    </div>
                  </div>
                  <div className="campoNa">
                    <label>Correo electrónico*</label>
                    <br />
                    <input
                      type="text"
                      className="input-apellido"
                      name="name"
                      onChange={emailHandler}
                    />
                    <div className="validacion-error">
                      <p>{emailError}</p>
                    </div>
                  </div>

                  <div className="campo campo-select">
                    <div className="campoNa select-campo">
                      <label>Tipo de documento*</label>
                      <br />
                      <select className="documento select">
                        <option selected disabled>
                          DNI, DNI Extranjero, CI.
                        </option>
                        <option>DNI</option>
                        <option>DNI Extranjero</option>
                        <option>CI</option>
                      </select>
                    </div>

                    <div className="campoNa">
                      <label>Numero de documento*</label>
                      <br />
                      <input
                        type="number"
                        placeholder="  Solo números. Ej: 12345678"
                        name="dni"
                        onChange={numberDniHandler}
                      />

                      <div className="validacion-error">
                        <p>{numberDniError}</p>
                      </div>
                    </div>
                  </div>
                  <div className="campoNa">
                    <label>Calle</label>
                    <br />
                    <input
                      type="text"
                      className="input-apellido"
                      name="name"
                      onChange={addressHandler}
                    />
                    <div className="validacion-error">
                      <p>{addressError}</p>
                    </div>
                    <p>Este domicilio será donde retiraremos tus envios.</p>
                  </div>

                  <div className="campo">
                    <div className="campoNa">
                      <label>Número*</label>
                      <br />
                      <input
                        className="input-direccion"
                        type="number"
                        name="dni"
                        onChange={numberAddressHandler}
                      />
                      <div className="validacion-error">
                        <p>{numberAddressError}</p>
                      </div>
                    </div>

                    <div className="campoNa">
                      <label>Piso</label>
                      <br />
                      <input
                        className="input-direccion"
                        type="number"
                        name="dni"
                      />
                    </div>

                    <div className="campoNa">
                      <label>Depto</label>
                      <br />
                      <input
                        className="input-direccion"
                        type="text"
                        name="dni"
                      />
                    </div>

                    <div className="campoNa">
                      <label>Código Postal*</label>
                      <br />
                      <input
                        type="number"
                        placeholder="  Ej, 1000, 1001, etc."
                        name="dni"
                        onChange={codeAddressHandler}
                      />
                      <div className="validacion-error">
                        <p>{codeAddressError}</p>
                      </div>
                    </div>
                  </div>

                  <div className="campo">
                    <div className="campoNa">
                      <label>Cod. de área*</label>
                      <br />
                      <input
                        className="input-nombre"
                        type="number"
                        placeholder="  Solo números. Ej: 011"
                        name="dni"
                        onChange={codeAreaHandler}
                      />
                      <div className="validacion-error">
                        <p>{codeAreaError}</p>
                      </div>
                    </div>

                    <div className="campoNa">
                      <label>Teléfono*</label>
                      <br />
                      <input
                        type="number"
                        placeholder="  Números sin puntos ni guiones. Ej: 12345678"
                        name="dni"
                        onChange={phoneHandler}
                      />
                      <div className="validacion-error">
                        <p>{phoneError}</p>
                      </div>
                    </div>
                  </div>

                  <div className="campoNa">
                    <label>Contraseña*</label>
                    <br />
                    <input
                      type="password"
                      name="dni"
                      onChange={passwordHandler}
                    />
                    <div className="validacion-error">
                      <p>{passwordError}</p>
                    </div>
                    <p>
                      La contraseña deberá contener como mínimo 8 caracteres,
                      una letra mayúscula y almenos 1 número.
                    </p>
                  </div>
                  <div className="campoNa">
                    <label>Confirmar Contraseña*</label>
                    <br />
                    <input
                      type="password"
                      name="dni"
                      onChange={confirmPasswordHandler}
                    />
                    <div className="validacion-error">
                      <p>{confirmPasswordError}</p>
                    </div>
                  </div>

                  {/*<div className="recaptcha-container">
                    <h4>¿Sos humano?</h4>
                    <div>espacio para el reCAPTCHA</div>
                  </div>*/}

                  <div className="terminos-condiciones">
                    <input
                      type="checkbox"
                      className="checkBox"
                      name="checkbox"
                    />
                    <span>
                      <a href="https://viacargo.com.ar/terms">
                        Acepto términos y condiciones
                      </a>
                    </span>
                  </div>

                  <div className="boton-crear-cuenta">
                    <input
                      type="button"
                      value="Crear cuenta"
                      name="dni"
                      onClick={submitForm}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="screen-contents contact-screen">
        <div className="details-container">
          <div className="content">
            <div className="container form-container">
              <div className="content titulo-formulario">
                <h3>Registro de usuario.</h3>
              </div>
              <div>{activarMensaje}</div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default userRegister;
