import React from "react";
import "./styles.css";

const Tabbar = ({ options }) => (
  <div className="tabbar-container">
    {options.map((option, index) => (
      <div
        key={index}
        onClick={() => option.action(option.value)}
        className={`tabbar-option ${option.active ? "active" : ""}`}
      >
        {option.label}
      </div>
    ))}
  </div>
);

export default Tabbar;

/*
  options = [
    {
      value: string,
      label: string,
      action: () => {},
      active: bool
    }
  ]
*/
