import React, { Component, useState, render, useRef } from "react";
import "../styles.css";
import {
  Accordion,
  Card,
  Col,
  Button,
  Form,
  CardGroup,
  Transitions,
  Collapse,
  InputGroup,
  Modal
} from "react-bootstrap";
import Reaptcha from "reaptcha";
import axios from "axios";

import "./../styles.css";

function ConfirmarDatos(props) {
  const [validated, setValidated] = useState(false);
  const [tipoServicio, setTipoServicio] = useState(0);
  const [active, setActive] = useState("");
  const [accept, setAccept] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(false);
  const [boton, setBoton] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  const captchaRef = useRef(null);
  const token = sessionStorage.getItem("token");
  const [xhr, setXhr] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [pago, setPago] = useState("");
  let timeout = 0;

  // Modal Exito
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    window.location.href = "/";
  };
  const handleShow = () => setShow(true);

  function openModalObjProhibidos() {
    setIsOpen(true);
  }

  function closeModalObjProhibidos() {
    setIsOpen(false);
  }

  let servicios = {};
  if (props.tiposServicios) {
    servicios = props.tiposServicios;
  }

  const openNewWindowTips = () => {
    window.open("/tips", "name", "width=960,height=600");
  };

  const openNewWindowLegales = () => {
    window.open("/terms", "name", "width=960,height=600");
  };

  const siguiente = () => {
    props.sextoForm();
    setBoton(!boton); //Tarea GDM-1031
    //props.retiros("")
  };

  const atrasForm4 = () => {
    props.atrasForm4();
  };

  const aceptar = valor => {
    //setAccept(valor)
    //setBoton(!boton)
    //props.atrasForm4(valor)
  };

  // console.log("Confirmar datos props.pickupMovil: " + JSON.stringify(props.pickupMovil));
  // console.log("Confirmar datos props.datosRemitente: " +JSON.stringify(props.datosRemitente));
  // console.log("Confirmar datos props.datosRemitente: " +JSON.stringify(props.datosDestinatario));

  return (
    <div>
      <div className="row">
        {/* <div className="container">
          <div className="text-left">
            <p>Por favor, revisá cuidadosamente los datos ingresados</p>
          </div>        
        </div> */}

        {/* <div className="col-md-3 col-lg-5">
          <img
            src="/images/box.png"
            alt="Via Cargo Mi retiro en domicilio Online"
            width="100"
            className="img-fluid"
          />
        </div> */}
        <div style={{ height: 15 }} />
        <div className="col-md-3 col-lg-12">
          <div className="text-left">
            <p>Por favor, revisá cuidadosamente los datos ingresados</p>
            <p>
              <strong>Descripción del envío</strong>
            </p>
          </div>
          <div className="text-left">
            <p>{props.DescripcionServicio}</p>
            <p>Cantidad de bultos: {props.NumeroBultos}</p>
            <p>
              {props.Alto} cm x {props.Ancho} cm x {props.Largo} cm
            </p>
            {/* <h4>
              <b>{props.TipoEntrega}</b>
            </h4> */}
          </div>
          <div className="text-left">
            <p>
              <strong>Tipo de servicio</strong>
            </p>
            {props.codigoProducto == "ea" && <p>Entrega en Agencia</p>}

            {props.codigoProducto == "pm" && <p>Pickup Móvil</p>}

            {props.codigoProducto == "rd" && <p>Retiro en Domicilio</p>}
          </div>
        </div>
        <div className="col-md-3 col-lg-6">
          <hr className="my-4" />
          <div className="text-left">
            <p>
              <strong>Remitente</strong>
            </p>
          </div>

          <div className="text-left">
            <h5>{props.datosRemitente.NombreRemitente}</h5>
            {props.codigoProducto != "pm" && (
              <Form.Label>
                <p>
                  Direccion: {props.datosRemitente.DireccionRemitente}{" "}
                  {props.datosRemitente.NumeroRemitente}, Piso:{" "}
                  {props.datosRemitente.PisoRemitente}, Depto:{" "}
                  {props.datosRemitente.DeptoRemitente}
                  <br />
                  Codigo postal: {props.datosRemitente.CodigoPostal}
                </p>
              </Form.Label>
            )}
            {props.codigoProducto == "pm" && (
              <Form.Label>
                <p>
                  <b>Pickup Movil</b>: Horario {props.pickupMovil.HorarioDesde}{" "}
                  a {props.pickupMovil.HorarioHasta} - Punto de pickup{" "}
                  {props.pickupMovil.Descripcion}
                  {" - "} {props.pickupMovil.Direccion} <br />
                  Fecha: {props.pickupMovil.Fecha}
                </p>
              </Form.Label>
            )}
            <p>
              DNI: {props.datosRemitente.NroDocRemitente} <br />
              Email: {props.datosRemitente.EmailRemitente} <br />
              Cel: ({props.datosRemitente.CodPaisRemitente}){" "}
              {props.datosRemitente.CodAreaRemitente} {" - "}
              {props.datosRemitente.NroCelularRemitente}
              {/* <b>Cel:</b> {props.datosRemitente.TelefonoRemitente} */}
            </p>
          </div>
          <hr className="my-4" />
        </div>
        <div className="col-md-3 col-lg-6">
          <hr className="my-4" />
          <div className="text-left">
            <p>
              <strong>Destinatario</strong>
            </p>
          </div>
          <div className="text-left">
            <h5>{props.datosDestinatario.NombreDestinatario}</h5>
            <p>
              Direccion: {props.datosDestinatario.DireccionDestinatario}{" "}
              {props.datosDestinatario.NumeroDestinatario}, Piso:{" "}
              {props.datosDestinatario.PisoDestinatario}, Depto:{" "}
              {props.datosDestinatario.DeptoDestinatario}
              <br />
              Codigo postal:{props.datosDestinatario.CodigoPostal}
              <br />
              <br />
              DNI: {props.datosDestinatario.NroDocDestinatario} <br /> Email:{" "}
              {props.datosDestinatario.EmailDestinatario} <br /> Cel: (
              {props.datosDestinatario.CodPaisDestinatario}){" "}
              {props.datosDestinatario.CodAreaDestinatario}
              {" - "}
              {props.datosDestinatario.NroCelularDestinatario}
            </p>
          </div>
          <hr className="my-4" />
        </div>
        <div className="col-md-3 col-lg-12">
          <div className="text-left">
            <h5>Total</h5>
            <h3>${props.TotalServicio}</h3>
            <p>
              Los valores de cotización son informativos y están sujetos a la
              veracidad de la información cargada en este formulario. La
              cotización final se realizará al momento del despacho.
            </p>
            <p>
              En todos los casos el acondicionamiento del embalaje de las piezas
              es responsabilidad del remitente
            </p>
          </div>
        </div>

        <div className="form-group form-group col-md-3 col-lg-12">
          <div className="text-left">
            El contenido del envío que realizo está bajo mi responsabilidad.{" "}
            <br />
            Acepto no enviar{" "}
            <a
              href=""
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                openModalObjProhibidos();
              }}
            >
              Elementos Prohibidos y embalar de forma adecuada
            </a>{" "}
            los productos, siendo responsable de cualquier daño y/o rotura.
            <br />
            {/* onClick={openNewWindowLegales} */}
            <a href="/terms" target="_blank">
              Ver legales
            </a>
          </div>

          <div className="col-md-3 col-lg-12">
            <div class="form-check-div">
              <input
                class="form-check-input"
                name="accept"
                id="accept"
                type="checkbox"
                defaultValue={accept}
                value=""
                onClick={() => {
                  setAccept(!accept);
                  setBoton(!boton && captchaToken);
                }}
              />
              <label class="form-check-accept" htmlFor="accept">
                Acepto las condiciones para continuar
              </label>
            </div>
          </div>

          <div style={{ marginLeft: "15px" }}>
            <Reaptcha
              sitekey={process.env.REACT_APP_SITE_KEY}
              ref={captchaRef}
              onVerify={() => {
                setCaptchaToken(!captchaToken);
                setBoton(!boton && accept);
              }}
              onExpire={() => {
                setCaptchaToken(!captchaToken);
                setBoton(false);
              }}
            />
          </div>
          {/* <br />
          <br /> */}

          <Form.Row>
            <Col md={{ span: 3, offset: 10 }}>
              <Button variant="secondary" onClick={atrasForm4}>
                Anterior
              </Button>
              {"   "}
              {/* {boton && (
                <Button variant="primary" onClick={handleShow}>
                  Siguiente
                </Button>
              )} */}
              {boton && (
                <Button variant="primary" onClick={siguiente}>
                  Siguiente
                </Button>
              )}
              {!boton && (
                <Button variant="primary" onClick={siguiente} disabled>
                  Siguiente
                </Button>
              )}
            </Col>
          </Form.Row>
          {/* <Button variant="secondary" onClick={atrasForm4} style={{ maxHeight: 38 }}>
            Anterior
          </Button>
          <br />
          {!boton && (
            <Button variant="primary" onClick={siguiente} disabled style={{ maxHeight: 38 }}>
              Siguiente
            </Button>
          )}
          {boton && (
            <Button variant="primary" onClick={handleShow} style={{ maxHeight: 38 }}>
              Siguiente
            </Button>
          )} */}

          {/* MODAL Objetos prohibidos */}
          <Modal
            show={modalIsOpen}
            onHide={closeModalObjProhibidos}
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="modal-90w"
            scrollable="true"
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body
              className="modalInterlineado"
              style={{ fontSize: "16px" }}
            >
              <p style={{ textAlign: "center", color: "#e63434" }}>
                <strong>¿Qué objetos son de prohibida circulación? </strong>
              </p>
              <p>
                Está terminantemente prohibido realizar envíos que por su
                naturaleza o embalaje resulten peligrosos para las personas que
                los manipulan, para otros envíos y para los equipos postales o
                medios de transporte:
              </p>
              <p>
                Sustancias inflamables, corrosivos, explosivos, contaminantes y
                precursores químicos. Armas, pólvora, municiones y afines.
                <br />
                Drogas, estupefacientes, medicamentos psicotrópicos y
                psicofármacos en general.
                <br />
                Obras de arte y joyas.
                <br />
                Elementos líquidos y/o frágiles y/o explosivos. Sustancias de
                carácter infeccioso y/o peligroso.
                <br />
                Plantas y animales vivos.
                <br />
                Dinero en efectivo, cheques u otros valores, tarjetas de
                crédito, débito o compra, billetes de lotería y/o cualquier otro
                tipo de comprobante de juego de azar. <br />
                Alimentos perecederos. <br />
                Materiales magnéticos, radioactivos y biológicos. <br />
                Presentaciones en licitaciones y/o concursos de precios. <br />
                Cualquier otra sustancia y/o material que no es permitida por la
                normativa postal o cuyo uso o tenencia esté penado legalmente o
                que se hubiera obtenido en violación a normas del Código
                Aduanero y/o de cualquier otra normativa vigente, como también
                publicaciones de carácter presuntamente inmoral o delictuoso,
                cuya circulación esté prohibida en la República Argentina
                <br />
              </p>
              <p style={{ textAlign: "center", color: "#6db568" }}>
                <strong>Recomendaciones a la hora de enviar </strong>
              </p>
              <p>
                <strong>Empaques </strong>
                <br />
                El empaque de un producto es el contenedor que protege el envio.
                Sirve principalmente para preservar las condiciones propias del
                producto, su imagen y la descripción de uso o consumo, además de
                tener un diseño que evita el daño durante su manipulación
              </p>
              <p>
                <strong>Embalaje </strong>
                <br />
                El embalaje es la envoltura contenedora que permite transportar,
                manipular y almacenar el producto, individual o grupalmente. Su
                función principal es la protección y manipulación. Prepara el
                embalaje de manera segura y adecuada teniendo en cuenta,
                Amortiguación - Envolturas - Sellado
              </p>
              <p>
                <strong>Identificación </strong>
                <br />
                Una vez que el producto se encuentra debidamente empacado y
                embalado, se debe colocar en un sitio visible de la encomienda
                la etiqueta que indique cómo tiene que ser manipulado y
                conservado. Es necesario para aclarar si requiere de algún
                manejo especial.
              </p>
              <p>
                <strong>Protección </strong>
                <br />
                Es importante que el producto este protegido de los impactos,
                golpes y caidas, al igual que de los agentes biológicos y
                climáticos que podrían presentarse durante el transporte.
                Recomendamos usar algunos de los siguientes materiales: metal,
                madera, cartón corrugado, plásticos, entre otros.
              </p>
              <p>
                <strong>Sujeción y agarre </strong>
                <br />
                Para su mayor seguridad, es preferible que se utilicen peliculas
                plásticas adheribles (Strech), zunchos, cintas o ganchos para
                sellar los empaques y embalajes, y facilitar así su transporte,
                manipulación y almacenamiento
              </p>
              <p>
                <strong>Tiempo de envio y colecta </strong>
                <br />
                Tu envío será recogido dentro de las 24 horas siguientes. En el
                momento de la recolección del envío el mensajero esperará un
                máximo de 15 minutos para la entrega del paquete.
              </p>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "center" }}>
              <Button
                variant="dark"
                size="lg"
                onClick={closeModalObjProhibidos}
              >
                Entendido
              </Button>
            </Modal.Footer>
          </Modal>

          {/* MODAL DEL EXITO */}
          <Modal
            show={show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: "20px" }}>
              Tu pedido fue registrado con éxito, te enviaremos un correo con
              los datos y la etiqueta que deberás pegar en los bultos.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}
export default ConfirmarDatos;
