import React, { Component } from "react";

import ServiceItem from "../../Services/components/ServiceItem";

import mas from "../../../../../assets/about/funcacion-mas.png";
import ruta40 from "../../../../../assets/about/fundacion-40.png";

class News extends Component {
  render() {
    return (
      <div>
        <div className="content-description">
          <p>
            "Los servicios que ofrece VIA Cargo son prestados por las empresas
            VIA BARILOCHE S.A. y VIA CARGO S.A., operadores inscriptos en el
            REGISTRO NACIONAL DE PRESTADORES DE SERVICIOS POSTALES creado por
            Decreto Nº 1187/93 y a cargo del ENTE NACIONAL DE COMUNICACIONES, lo
            que importa total ajuste con lo que exige la normativa nacional
            aplicable. Para más información, dirigirse a
            enacom.gob.ar/serviciospostales"
          </p>
        </div>

        <div className="content-description">
          <p className="bigger-description">
            Vía Cargo ofrece una amplia gama de servicios postales para que, de
            manera rápida y segura, puedas realizar tus envíos desde y hasta
            cualquier punto del país.
          </p>
        </div>

        <div className="content-description">
          <p>
            Comenzamos en 1999 integrando el grupo Vía Bariloche S.A. como
            prestadores del servicio postal, brindándole a terceros un servicio
            de logística y distribución. La sinergia que se produjo provocó la
            división entre la logística y distribución, permitiéndonos brindar
            una oferta integral del servicio, acompañada de una extensa red de
            rutas, amplitud de frecuencias y una red postal de 500 bocas de
            admisión. Desde entonces, el crecimiento fue constante.
          </p>
        </div>

        <ServiceItem title="Quiénes somos">
          Somos una empresa líder en el servicio de paquetería y encomiendas.
          Contamos con una amplia red postal de más de 500 puntos de venta
          distribuidos en todo el país que nos permite brindarle a nuestros
          clientes una gran cantidad de servicios destinados al envío de
          postales y carga. <br /> Poseemos una gran capacidad de almacenaje en
          bodegas y camiones especialmente acondicionados para realizar viajes
          eficientes y dinámicos que cumplen estrictamente con los plazos de
          entrega.
        </ServiceItem>

        <ServiceItem title="Visión">
          Liderar el mercado de servicios de encomiendas, transformándonos en el
          curier más elegido por los argentinos.
        </ServiceItem>

        <ServiceItem title="Misión">
          A través del uso de la tecnología y los recursos humanos, ser la
          compañía más eficiente y eficaz en el transporte de encomiendas punto
          a punto, con la mayor cobertura y frecuencia.
        </ServiceItem>

        <ServiceItem title="Nuestra flota">
          Contamos con una flota propia de camiones y camionetas, además de los
          vehículos de Vía Bariloche que nos permiten realizar los envíos de
          encomienda de manera rápida y segura.
        </ServiceItem>

        <ServiceItem title="Compromiso Social / RSE">
          En Vía Cargo trabajamos cotidianamente manteniendo una relación ética
          con todas las comunidades, estableciendo objetivos compatibles con el
          desarrollo comunitario y preservando los recursos naturales.
          Accionamos y tomamos decisiones que demuestran un sostén incondicional
          a quienes más lo necesitan. Son los casos de Fundación Más y Fundación
          Ruta 40, las cuales confían en nuestro servicio y nos permiten ser
          partícipes de sus iniciativas y trabajar junto a ellas para ayudar a
          los chicos de escuelas rurales a recibir lo que necesiten para vivir,
          estudiar y tener un futuro mejor; porque todos podemos aportar para
          conseguir una sociedad respetuosa donde se puedan establecer políticas
          de apoyo y sea digno vivir. Por eso, además, los integrantes de
          nuestra empresa realizan un aporte voluntario ante las exigencias
          éticas a las que la actualidad nos expone para crear una zona libre,
          armoniosa y de sana convivencia.
        </ServiceItem>

        <div className="content-description">
          <p>
            Queremos compartir las palabras que nos dedicaron dichas Fundaciones
            ya que nos enorgullecen e incentivan a seguir esforzándonos y
            contribuir para que nuestra comunidad prospere continuamente.
          </p>
        </div>

        <div className="fundacion-quote">
          <div className="quote-sign">
            <a href={"http://fundacionmas.org/"} target={"_blank"}>
              <img src={mas} alt={"Fundación Más"} />
            </a>
          </div>
          <h1 className="quote-title">Fundación Más</h1>
          <p className="quote-description">
            "Desde hace más de 4 años, Vía Cargo se transformó en EL PILAR
            FUNDAMENTAL de la misión que nos propusimos en la Fundación. Con los
            envíos que nos proveen para llegar a Misiones y Chaco, se
            convirtieron en el puente para que más de 500 chicos en 4 escuelas
            rurales puedan recibir todo lo necesario para estudiar dignamente
            (útiles, vestimenta, juguetes, elementos de higiene, afecto a través
            de las cartas que intercambian con sus padrinos y muchos más) y así
            seguir avanzando y acercándose a lograr un futuro mejor. Desde
            Fundación Más, estamos muy agradecidos por este inmenso aporte que
            realizan a nuestra organización. Su compromiso, su buena
            predisposición permanente y la apuesta que realizan por el futuro de
            los más necesitados, facilitan el cumplimiento de nuestra misión:
            colaborar con poblaciones rurales en situaciones de vulnerabilidad,
            especialmente con chicos. ¡Gracias Vía Cargo!. Sin ustedes sería
            imposible".
          </p>
          <p className={"quote-owner"}>
            Valeria Sobrino. Fundadora y presidente de la Fundación.
          </p>
        </div>
        <div className="fundacion-quote">
          <div className="quote-sign">
            <a href={"http://www.fundacionruta40.org.ar/"} target={"_blank"}>
              <img src={ruta40} alt={"Fundación Ruta 40"} />
            </a>
          </div>
          <h1 className="quote-title">Fundación Ruta 40</h1>
          <p className="quote-description">
            "Gracias a Vía Cargo - Vía Bariloche, Fundación Ruta 40 puede hacer
            llegar las donaciones de muchas personas y empresas a las escuelas
            rurales que acompaña a Neuquén, Río Negro y Chubut. Dichas
            donaciones les permite a las escuelas contar con elementos básicos y
            fundamentales para el día a día: ropa y calzado para los alumnos,
            alimentos para el comedor, útiles escolares para el desarrollo de
            las clases, material de lectura para enriquecer la experiencia
            lectora de toda la comunidad, y artículos de limpieza para los
            albergues escolares, etc. Con el aporte de Vía Cargo las distancias
            se acortan, podemos acompañarlos en su camino y las escuelas sienten
            que a pesar de la lejanía no están solas. Detrás de cada donación
            hay mucha gente ayudando para un futuro mejor".
          </p>
          <p className={"quote-owner"}>Paula Ayerza. Directora ejecutiva.</p>
        </div>
      </div>
    );
  }
}

export default News;
