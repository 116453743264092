import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyAa6MJtEisuiJW107yTrKiYihHX00iu0bg");

export const helpers = {
  geocode: {
    fromAddress: address => {
      return Geocode.fromAddress(address);
    },
    fromCoords: (lat, lng) => {
      return Geocode.fromLatLng(lat, lng);
    }
  }
};
