import React from "react";
import { bool, string, oneOfType, object } from "prop-types";
import "./styles.css";

const MenuItem = ({ lowerCase, secondaryMenu, title, className = "" }) => {
  const listStyle = !secondaryMenu ? "separator" : undefined;
  const textStyle = secondaryMenu
    ? "secondary-menu-item"
    : lowerCase
    ? "menu-item-lower"
    : "menu-item";

  return (
    <div className={`menu-item ${listStyle} ${className}`}>
      <span className={textStyle}>{title}</span>
    </div>
  );
};

MenuItem.propTypes = {
  secondaryMenu: bool,
  lowerCase: bool,
  title: oneOfType([string, object]).isRequired,
  className: string
};

export default MenuItem;
