import React from "react";

import error from "../../../../assets/404_error.png";
import "./styles.css";

const PageNotFound = () => (
  <section className="error-screen">
    <div className="background">
      <img src={error} alt="Fondo" />
    </div>
    <div className="details-container">
      <div className="packs-content">
        <p>
          No fue posible conectarnos con la página que buscás. Por favor, volvé
          a intentarlo en un rato.
        </p>
        <p className={"error"}>Error 404</p>
      </div>
    </div>
  </section>
);

export default PageNotFound;
