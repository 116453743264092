import React from "react";
import ReactDOM from "react-dom";
import App from "./screens/App";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import registerServiceWorker from "./registerServiceWorker";

ReactDOM.render(
  <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
registerServiceWorker();
