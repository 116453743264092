import React, { Component, useState } from "react";
import { Link, Route, Switch, withRouter } from "react-router-dom";
import Scroll from "react-scroll";

import Footer from "./components/Footer";
import FooterMobile from "./components/FooterMobile";
import locationIcon from "../../assets/icons/agencias1.png";
import atentionIcon from "../../assets/icons/contact.png";
import mobileBanner from "../../assets/banner-mobile.png";
import warningIcon from "../../assets/warning.png";
import Button from "./screens/Main/components/Mobile/Button";

import KnowUs from "./screens/KnowUs";
import Main from "./screens/Main";
import Menu from "./components/Menu";
import MenuItem from "./components/MenuItem";
import Logo from "../../assets/logo.png";
import LogoViaCompras from "../../assets/via-compras.png";
import Products from "./screens/Products";
import Services from "./screens/Services";
import Tips from "./screens/Tips";
import Tracking from "./screens/Tracking";
import Packs from "./screens/Packs";
import Quote from "./screens/Quote";
import Terms from "./screens/Terms";
import Rezagos from "./screens/Rezagos";
import Access from "./screens/Access";
import Agencies from "./screens/Agencies";
import JoinUs from "./screens/JoinUs";
import FrequentAsk from "./screens/FrequentAsk";
import Contact from "./screens/Contact";
import PickUp from "./screens/PickUp";
import PickUpSeguimiento from "./screens/PickUpSeguimiento";
import PickUpHome from "./screens/PickUpHome";
import PickUpFormulario from "./screens/PickUpFormulario";
import PageNotFound from "./screens/PageNotFound";
import CustomerSupports from "./screens/CustomerSupports";
import Product from "./screens/Product";
import Login from "./screens/Login";
import UserRegister from "./screens/UserRegister";
import Password from "./screens/Password";
import UserCategoryConfirm from "./screens/UserCategoryConfirm";
import UserCategoryConfirm2 from "./screens/UserCategoryConfirm2";

import "./styles.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import Icon from "@material-ui/core/Icon";
import Modal from "simple-react-modal";
import hamburgerIcon from "shared/assets/hamburger.png";
import axios from "axios";
import whatsApp from "../../assets/WhatsApp.png";
import passwordChange from "./screens/passwordChange";
import userCategory from "./screens/UserCategory";
import Warning from "./components/Warning";

const scroll = Scroll.animateScroll;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: false,
      open: false,
      showModal: false,
      modalImg: "",
      token: false
    };
    this.checkToken();
    setInterval(() => {
      this.checkToken();
    }, 900000);
    const vcVersion = localStorage.getItem("viacargoVersion");
    const tokenTiendaNube = localStorage.getItem("tokenTiendaNube");
    const estadoTiendaNubeToken = localStorage.getItem("estadoTiendaNubeToken");
    const StoreId = localStorage.getItem("StoreId");

    localStorage.clear();

    localStorage.setItem("viacargoVersion", vcVersion);
    localStorage.setItem("tokenTiendaNube", tokenTiendaNube);
    localStorage.setItem("estadoTiendaNubeToken", estadoTiendaNubeToken);
    localStorage.setItem("StoreId", StoreId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      scroll.scrollToTop({});
    }

    if (localStorage.getItem("timeout")) {
      const date = new Date(parseInt(localStorage.getItem("timeout")));
      if (
        parseInt(new Date().getTime()) -
          parseInt(localStorage.getItem("timeout")) >
        15 * 60 * 1000
      ) {
        localStorage.removeItem("timeout");
        localStorage.removeItem("loggedUser");
        window.location.href = "/login";
      }
    }
  }

  checkToken = () => {
    if (
      !sessionStorage.getItem("token") &&
      !sessionStorage.getItem("timestamp")
    ) {
      axios
        .get(process.env.REACT_APP_API_URL + "/api/token")
        .then(res => {
          if (
            /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
              res.data
            )
          ) {
            sessionStorage.setItem("token", res.data);
            sessionStorage.setItem("timestamp", new Date());
            this.setState({ token: true });
            window.location.reload();
          }
        })
        .catch(error => {
          this.setState({ token: true });
        });
    } else {
      const now = new Date();
      const tokenTimestamp = new Date(sessionStorage.getItem("timestamp"));
      const diffTime = Math.abs(tokenTimestamp - now);
      const diffMin = Math.round(((diffTime % 86400000) % 3600000) / 60000);
      if (diffMin > 40) {
        axios
          .get(process.env.REACT_APP_API_URL + "/api/token")
          .then(res => {
            if (
              /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
                res.data
              )
            ) {
              sessionStorage.setItem("token", res.data);
              sessionStorage.setItem("timestamp", new Date());
              this.setState({ token: true });
              window.location.reload();
            }
          })
          .catch(error => {
            this.setState({ token: true });
          });
      } else {
        this.state.token = true;
      }
    }
  };

  fetchImage = () => {
    const imgUrl = "https://www.viacargo.com.ar/files/images/modal/imagen.jpg";
    axios
      .get(imgUrl, { responseType: "blob" })
      .then(response => {
        if (response.status === 200) {
          this.setState({
            modalImg: URL.createObjectURL(response.data), // this method is not supported by IE11
            showModal: true
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleModal = () => {
    this.fetchImage();
  };

  compareVersion = (version1, version2) => {
    const regex = /\./g;
    version1 = version1.replace(regex, "");
    version2 = version2.replace(regex, "");
    while (version1.length < version2.length) {
      version1 = version1 + "0";
    }
    while (version2.length < version1.length) {
      version2 = version2 + "0";
    }
    version1 = parseInt(version1);
    version2 = parseInt(version2);
    if (version1 > version2) {
      return true;
    } else {
      return false;
    }
  };

  clearCacheData = () => {
    caches.keys().then(names => {
      names.forEach(name => {
        caches.delete(name);
      });
    });
  };
  componentDidMount() {
    if (localStorage.getItem("viacargoVersion")) {
      let subeVersion = this.compareVersion(
        process.env.REACT_APP_VERSION,
        localStorage.getItem("viacargoVersion")
      );
      let bajaVersion = this.compareVersion(
        localStorage.getItem("viacargoVersion"),
        process.env.REACT_APP_VERSION
      );
      if (subeVersion) {
        console.log("La version actual es mayor, se vacia la cache");
        localStorage.setItem("viacargoVersion", process.env.REACT_APP_VERSION);
        this.clearCacheData();
      } else {
        if (bajaVersion) {
          console.log("La version actual es menor, se vacia la cache");
          localStorage.setItem(
            "viacargoVersion",
            process.env.REACT_APP_VERSION
          );
          this.clearCacheData();
        } else {
          console.log("La version actual es igual a la anterior, sin efecto");
        }
      }
    } else {
      localStorage.setItem("viacargoVersion", process.env.REACT_APP_VERSION);
    }

    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));

    if (this.props.location.pathname === "/") this.handleModal();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    if (window.innerWidth < 900) {
      this.setState({ mobile: true });
    } else {
      this.setState({ mobile: false, open: false });
    }
  }

  handleLogout = () => {
    localStorage.removeItem("loggedUser");
    window.location.href = "/login";
  };

  render() {
    return (
      <div className="app">
        <CssBaseline />
        <Warning />
        {/* <div className="header-gradient" /> Eze*/}
        <header className="header">
          <div className="logo">
            <Link to="/">
              <img alt="Via Cargo" src={Logo} className="via-cargo-logo" />
            </Link>
          </div>
          <div className="menu-container">
            <Menu primaryMenu>
              <Link className="menu-link" to="/know-us">
                <MenuItem title="Conocenos" lowerCase />
                <span className="menu-separator">|</span>
              </Link>
              <Link className="menu-link" to="/access">
                <MenuItem title="Acceso Usuarios" />
              </Link>
            </Menu>
            <Menu>
              <Link to="/products">
                <MenuItem title="Productos" secondaryMenu />
              </Link>
              <Link
                to={{ pathname: "https://www.viacompras.com.ar/" }}
                target="_blank"
              >
                {/* <MenuItem title="Vía Compras" secondaryMenu /> */}
                <img
                  alt="Via Compras"
                  src={LogoViaCompras}
                  className="logo-via-compras"
                />
              </Link>
            </Menu>
          </div>
          <div className={"hamburger-menu"}>
            <img
              src={hamburgerIcon}
              alt="hamburger"
              className="hamburgerIcon"
              onClick={() => this.setState({ open: !this.state.open })}
            />
            <div
              className={"floating-menu"}
              style={{ display: this.state.open ? "block" : "none" }}
            >
              <div className={"fm-item top-border"}>
                <Link to="/know-us">
                  <div
                    onClick={() => this.setState({ open: !this.state.open })}
                  >
                    Conocenos
                    <div className={"chevron"}></div>
                  </div>
                </Link>
              </div>
              <div className={"fm-item"}>
                <Link to="/contact?join">
                  <div
                    onClick={() => this.setState({ open: !this.state.open })}
                  >
                    Sumate a la Red
                    <div className={"chevron"}></div>
                  </div>
                </Link>
              </div>
              <div className={"fm-item"}>
                <Link to="/access">
                  <div
                    onClick={() => this.setState({ open: !this.state.open })}
                  >
                    Acceso a usuarios
                    <div className={"chevron"}></div>
                  </div>
                </Link>
              </div>
              <div className={"fm-item"}>
                <Link to="/products">
                  <div
                    onClick={() => this.setState({ open: !this.state.open })}
                  >
                    Productos
                    <div className={"chevron"}></div>
                  </div>
                </Link>
              </div>
              <div className={"fm-item"}>
                <Link to="/services">
                  <div
                    onClick={() => this.setState({ open: !this.state.open })}
                  >
                    Servicios
                    <div className={"chevron"}></div>
                  </div>
                </Link>
              </div>
              <div className={"fm-item"}>
                <Link to="/quote">
                  <div
                    onClick={() => this.setState({ open: !this.state.open })}
                  >
                    Cotizar
                    <div className={"chevron"}></div>
                  </div>
                </Link>
              </div>
              <div className={"fm-item"}>
                <Link to="/agencies">
                  <div
                    onClick={() => this.setState({ open: !this.state.open })}
                  >
                    Agencias
                    <div className={"chevron"}></div>
                  </div>
                </Link>
              </div>
              <div className={"fm-item"}>
                <Link to="/support">
                  <div
                    onClick={() => this.setState({ open: !this.state.open })}
                  >
                    Atención al cliente
                    <div className={"chevron"}></div>
                  </div>
                </Link>
              </div>
            </div>
            <div
              className={"floating-menu-overlay"}
              onClick={() => this.setState({ open: !this.state.open })}
              style={{
                display: this.state.open ? "block" : "none",
                height: `${document.querySelector("body").offsetHeight - 65}px`
              }}
            />
          </div>
        </header>
        <section className="screen-container">
          <Switch>
            <Route exact path="/" component={Main} />
            <Route path="/products" component={Products} />
            <Route path="/services" component={Services} />
            <Route path="/know-us" component={KnowUs} />
            <Route path="/tips" component={Tips} />
            <Route path="/tracking/:code" component={Tracking} />
            <Route path="/tracking" component={Tracking} />
            <Route path="/quote" component={Quote} />
            <Route path="/packs" component={Packs} />
            <Route path="/customer-support" component={Contact} />
            <Route path="/access" component={Access} />
            <Route path="/terms" component={Terms} />
            <Route path="/rezagos" component={Rezagos} />
            <Route path="/agencies" component={Agencies} />
            <Route path="/join-us" component={JoinUs} />
            <Route path="/frequent-ask" component={FrequentAsk} />
            <Route path="/contact" component={Contact} />
            <Route path="/pickup" component={PickUp} />
            <Route path="/pickup-tracing" component={PickUpSeguimiento} />
            <Route path="/pickup-home" component={PickUpHome} />
            <Route path="/product/:code" component={Product} />
            <Route
              path="/pickup-formulario/:info"
              component={PickUpFormulario}
            />
            <Route path="/pickup-formulario/" component={PickUpFormulario} />
            <Route path="/support" component={CustomerSupports} />
            <Route path="/login" component={Login} />
            <Route path="/login/:code" component={Login} />
            <Route path="/login/:code/:correo" component={Login} />
            <Route path="/userRegister" component={UserRegister} />
            <Route path="/password" component={Password} />
            <Route path="/passwordChange" component={passwordChange} />
            <Route path="/userCategory" component={userCategory} />
            <Route
              path="/userCategoryConfirm/:info/:type"
              component={UserCategoryConfirm}
            />
            <Route
              path="/userCategoryConfirm2/:info"
              component={UserCategoryConfirm2}
            />
            <Route path="*" component={PageNotFound} />
          </Switch>
          {localStorage.getItem("loggedUser") && (
            <div className="cerrar_sesion">
              {" "}
              <button onClick={this.handleLogout}> Cerrar sesión </button>
            </div>
          )}
        </section>
        {this.state.mobile && (
          <div>
            {/* <Button
              img={atentionIcon}
              text={"Atención al Cliente"}
              background={"#a8c94b"}
              link={"/customer-support"}
            />
            <Button
              img={locationIcon}
              text={"Agencias Cercanas"}
              background={"#59ac54"}
              link={"/agencies"}
            />
            <a href={"https://www.girodinero.com.ar/"} target={"_blank"}>
              <img
                src={mobileBanner}
                alt={"Giro dinero"}
                style={{ width: "100%" }}
              />
            </a> */}
            <FooterMobile />
            {/*  <div id="WP">
              <a
                href="https://api.whatsapp.com/send?phone=5491132727722"
                class="WP"
                style={{ float: "right", bottom: 0, width: "30%" }}
              >
                <img
                  src={whatsApp}
                  alt="whatsApp"
                  style={{
                    right: 0,
                    width: "20%",
                    float: "right",
                    color: "#fff",
                    cursor: "pointer",
                    position: "fixed",
                    bottom: 0,
                    zIndex: 1000
                  }}
                />
              </a>
            </div> */}
          </div>
        )}
        {!this.state.mobile && <Footer />}
        <div className={"credits"}></div>

        <Modal
          show={this.state.showModal}
          onClose={() => this.setState({ showModal: false })}
          className={"modal"}
          containerClassName={"modal-container"}
        >
          <div className={"close-button"}>
            <i
              className={"material-icons"}
              onClick={() => this.setState({ showModal: false })}
            >
              close
            </i>
          </div>
          <div className={"modal-body"}>
            <img
              className={"modal-img"}
              src={this.state.modalImg}
              alt={"Aviso"}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(App);
