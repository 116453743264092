import React from "react";
import Sidebar from "shared/components/Sidebar/Sidebar";
import background from "assets/backgrounds/01.jpg";
import "./styles.css";

const Rezagos = () => (
  <section className="screen-contents rezagos-screen">
    <div className="background">
      <img src={background} alt="Fondo" />
    </div>
    <div className="details-container">
      <div className="content">
        <div className="content-title">
          <h1>Ver envíos en rezago</h1>
        </div>
        <div className="rezagos-content">
          <p>
            Todo envío postal que no haya sido entregado al destinatario ni
            pueda ser devuelto al remitente, por cualquier causa, se considerará
            caído en rezago y será sometido al procedimiento previsto en las
            normas bajo la supervisión de ENACOM. En forma previa y por un plazo
            de 60 días, será publicado en la web www.viacargo.com.ar el listado
            de los envíos caídos en rezago, para quien tenga derechos que hacer
            valer al respecto. En ese caso, deberá contactarse con la empresa a
            través de las Burbuja de chat ubicado en el margen inferior derecho,
            opción preguntas frecuentes.
            <br />
            <ul>
              <li>
                <a href="http://share.viacorreo.com.ar/owncloud/index.php/s/6SLn5JOTpCVkH0M">
                  {" "}
                  Descargar Envíos en Rezago Vía Bariloche
                </a>
              </li>
              <li>
                <a href="http://share.viacorreo.com.ar/owncloud/index.php/s/pMnrI2xxLq9ESo2">
                  {" "}
                  Descargar Envíos en Rezago Vía Cargo{" "}
                </a>
              </li>
            </ul>
          </p>
        </div>
      </div>
      <Sidebar />
    </div>
  </section>
);

export default Rezagos;
