import React, { Component, useState } from "react";
//import { Link, useParams } from "react-router-dom";
import Sidebar from "shared/components/Sidebar/Sidebar";
import background from "assets/backgrounds/01.jpg";
import "./styles.css";
import basica from "../../../../../src/assets/entrega_domicilio/basica.png";
import estandar from "../../../../../src/assets/entrega_domicilio/estandar.png";
// import alto_ico from "../../../../../src/assets/product/formats/alto.png";
// import ancho_ico from "../../../../../src/assets/product/formats/ancho.png";
// import bulto_ico from "../../../../../src/assets/product/formats/bulto.png";
// import peso_ico from "../../../../../src/assets/product/formats/peso.png";
// import profundidad_ico from "../../../../../src/assets/product/formats/profundidad.png";
// import valor_declarado_ico from "../../../../../src/assets/product/formats/valor-declarado.png";
import Formulario from "./components/Formulario";
import TiposServicios from "./components/TiposServicios";
import DatosRemitente from "./components/DatosRemitente";
import DatosDestinatario from "./components/DatosDestinatario";
import ConfirmarDatos from "./components/ConfirmarDatos";
import FomularioPago from "./components/FomularioPago";
import FomularioPagoProcesar from "./components/FomularioPago";
import PagoProcesado from "./components/PagoProcesado";
import Tarjetas from "./components/Tarjetas";
import PmLogo from "assets/a.png";
import EaLogo from "assets/b.png";
import RdLogo from "assets/c.png";

import PickUpFormulario from "../PickUpFormulario/index";

import axios from "axios";
import Modal from "simple-react-modal";
//import Form from './Form/Form'

import {
  Accordion,
  Card,
  Col,
  Button,
  Form,
  CardGroup,
  Transitions,
  Collapse
} from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";

class Product extends Component {
  componentWillMount() {
    /*let importe_declarado = `/api/importe_declarado`;

    axios.get(importe_declarado, "")
    .then(response  => {
        let { data } = response;
        //console.log(data)
        console.log(data)
        this.setState({Importes:data})
        
    })
    .catch(e => {

        this.setState({
          //infoPago: e.response.data.message,
          xhr: false,
          ////showModal: true,
          errorMessage: "server-error: "+e.response.data.message
        });              
        
        console.log(e);
    });*/
  }

  constructor(props) {
    super(props);

    let code = "";
    if (props.match.params && props.match.params.code) {
      code = props.match.params.code;
    }

    console.log(
      "process.env.NODE_ENV: " + JSON.stringify(process.env.NODE_ENV)
    );
    console.log(
      "process.env.REACT_APP_NODE_ENV: " +
        JSON.stringify(process.env.REACT_APP_NODE_ENV)
    );
    console.log(
      "process.env.REACT_APP_IDCLIREM_PM_PROD: " +
        JSON.stringify(process.env.REACT_APP_IDCLIREM_PM_PROD)
    );
    console.log(
      "process.env.REACT_APP_IDCLIREM_PM_TEST: " +
        JSON.stringify(process.env.REACT_APP_IDCLIREM_PM_TEST)
    );

    let IdCliRemPM = "";
    let IdCliRemEA = "";
    let IdCliRemRD = "";
    let IdCeRemPM = "";
    let IdCeRemEA = "";
    let IdCeRemRD = "";
    if (process.env.REACT_APP_NODE_ENV === "PRODUCTION") {
      IdCliRemPM = process.env.REACT_APP_IDCLIREM_PM_PROD;
      IdCliRemEA = process.env.REACT_APP_IDCLIREM_EA_PROD;
      IdCliRemRD = process.env.REACT_APP_IDCLIREM_RD_PROD;

      IdCeRemPM = process.env.REACT_APP_IDCEREM_PM_PROD;
      IdCeRemEA = process.env.REACT_APP_IDCEREM_EA_PROD;
      IdCeRemRD = process.env.REACT_APP_IDCEREM_RD_PROD;
    } else {
      IdCliRemPM = process.env.REACT_APP_IDCLIREM_PM_TEST;
      IdCliRemEA = process.env.REACT_APP_IDCLIREM_EA_TEST;
      IdCliRemRD = process.env.REACT_APP_IDCLIREM_RD_TEST;

      IdCeRemPM = process.env.REACT_APP_IDCEREM_PM_TEST;
      IdCeRemEA = process.env.REACT_APP_IDCEREM_EA_TEST;
      IdCeRemRD = process.env.REACT_APP_IDCEREM_RD_TEST;
    }

    let title = "";
    let description = "";
    let valid = false;
    let logoHeader = null;

    //console.log("Product.js constructor: " + code);

    switch (code) {
      case "rd":
        title = "Retiro en domicilio";
        description =
          "Servicio de retiro a domicilio con entrega a todo el país hasta 50kg.";
        valid = true;
        logoHeader = RdLogo;
        break;
      case "ea":
        title = "Entrega en agencia";
        description = "Cotizador de entrega en agencia.";
        valid = true;
        logoHeader = EaLogo;
        break;
      case "pm":
        title = "Pickup móvil";
        description =
          "Servicio exclusivo de recolección en Ciudad de Buenos Aires.";
        valid = true;
        logoHeader = PmLogo;
        break;
      default:
        title = "";
        description = "";
        break;
    }

    if (!valid) {
      this.props.history.push("/");
    }

    this.state = {
      title: title,
      logoHeader: logoHeader,
      description: description,
      productCode: code,
      IdClienteRemitentePM: IdCliRemPM,
      IdCentroRemitentePM: IdCeRemPM,
      IdClienteRemitenteEA: IdCliRemEA,
      IdCentroRemitenteEA: IdCeRemEA,
      IdClienteRemitenteRD: IdCliRemRD,
      IdCentroRemitenteRD: IdCeRemRD,
      activeId: 1123,
      setActiveId: 1,
      open1: true,
      open1once: true, //Tarea GDM-1293
      open2: false,
      open3: false,
      open4: false,
      open5: false,
      open6: false,
      open7: false,
      selectedOption: 0, //Tarea GDM-1031
      error: {
        cporigen: ""
      },
      validated: "aki",
      tiposServicios: {},
      message: "",
      showModal: false,
      errorMessage: "",
      modalMessage: "",
      xhr: true,

      IdCotizacion: 0,
      DescripcionServicio: "",
      CodigoServicio: 0,
      TotalServicio: 0,
      TipoEntrega: "",
      NumeroBultos: 0,
      Kilos: 0,
      Alto: 0,
      Ancho: 0,
      CodigoPostalRemitente: 0,
      CodigoPostalDestinatario: 0,
      ImporteValorDeclarado: 0,
      Pago: "",

      pickupMovil: {},
      camposFormulario: {},
      datosRemitente: {},
      datosDestinatario: {},

      NombreRemitente: "",
      DireccionRemitente: "",
      NumeroRemitente: "",
      /*CodigoPostal:"",      
      DatosAdicional:"",*/
      PisoRemitente: "",
      DeptoRemitente: "",
      NroDocRemitente: "",
      CodPaisRemitente: "",
      CodAreaRemitente: "",
      NroCelularRemitente: "",
      TelefonoRemitente: "",
      EmailRemitente: "",

      NombreDestinatario: "",
      DireccionDestinatario: "",
      NumeroDestinatario: "",
      /*CodigoPostal:"",      
      DatosAdicional:"",*/
      PisoDestinatario: "",
      DeptoDestinatario: "",
      NroDocDestinatario: "",
      CodPaisDestinatario: "",
      CodAreaDestinatario: "",
      NroCelularDestinatario: "",
      TelefonoDestinatario: "",
      EmailDestinatario: "",

      IdTarjeta: "",
      IdPosnet: "",
      Cuotas: "",
      Coeficiente: "",

      urlIframe: "",
      infoFirstData: {},
      formPago: {},
      idEnvio: "",
      infoPago: undefined,
      intervalo: undefined,
      costoFijo: "",
      Importes: {},
      confirmar: true, //Tarea GDM1031
      limpiar: false //Tarea GDM1031
    };
  }

  timeoutImprimir = 0;
  contador = 0;
  intentosImprimir = 0;
  token = sessionStorage.getItem("token");

  timeout = 0;
  intentos = 0;

  checkToken() {
    if (
      !sessionStorage.getItem("token") &&
      !sessionStorage.getItem("timestamp")
    ) {
      axios.get(process.env.REACT_APP_API_URL + "/api/token").then(res => {
        if (
          /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
            res.data
          )
        ) {
          sessionStorage.setItem("token", res.data);
          sessionStorage.setItem("timestamp", new Date());

          this.token = res.data;
          return res.data;
        }
      });
    } else {
      const now = new Date();
      const tokenTimestamp = new Date(sessionStorage.getItem("timestamp"));
      const diffTime = Math.abs(tokenTimestamp - now);
      const diffMin = Math.round(((diffTime % 86400000) % 3600000) / 60000);
      if (diffMin > 60) {
        axios.get(process.env.REACT_APP_API_URL + "/api/token").then(res => {
          if (
            /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
              res.data
            )
          ) {
            sessionStorage.setItem("token", res.data);
            sessionStorage.setItem("timestamp", new Date());

            this.token = res.data;
            return res.data;
          }
        });
      } else {
        return sessionStorage.getItem("token");
      }
    }
  }

  toggleActive(id) {
    if (this.state.activeId === id) {
      this.setState({ setActiveId: null });
    } else {
      this.setState({ setActiveId: id });
    }
  }

  setOpen(valor, indice) {
    switch (indice) {
      case 1:
        /*Tarea GDM-1293 - Inicio */
        if (this.state.open1once) {
          this.setState({ open1: valor });
          this.setState({ open1once: false });
        }
        /*Tarea GDM-1293 - Fin */
        break;
      case 2:
        this.setState({ open2: valor });
        break;

      case 3:
        this.setState({ open3: valor });
        break;

      case 4:
        this.setState({ open4: valor });
        break;

      case 5:
        this.setState({ open5: valor });
        break;

      case 6:
        this.setState({ open6: valor });
        break;

      case 7:
        this.setState({ open7: valor });
        break;

      default:
        return "";
    }
  }

  consultarPago = () => {
    if (this.state.idEnvio) {
      let idClienteRem = this.state.IdClienteRemitentePM;
      if (this.state.productCode == "ea") {
        /*para retiros en agencias*/
        idClienteRem = this.state.IdClienteRemitenteEA;
      } else if (this.state.productCode == "rd") {
        idClienteRem = this.state.IdClienteRemitenteRD;
      }
      let camposFirstData = {
        IdOperacion: this.state.idEnvio, //3374303, //formulario.oid,
        IdClienteRemitente: idClienteRem,
        NombreRemitente: "Test",
        DireccionRemitente: "Test",
        TipoDocRemitente: "DNI",
        NroDocRemitente: "1234567"
      };

      let retiros_actualizar_pago =
        process.env.REACT_APP_API_URL + `/api/retiros_actualizar_pago`;

      axios
        .post(retiros_actualizar_pago, camposFirstData, {
          params: { token: this.token }
        })
        .then(response => {
          let { data } = response;

          this.setState({ infoPago: data.data });
          //console.log(this.state.infoPago)
          ///props.actualizarPago(data.data)
          ////setUrl("/Producto-formulario/"+encodeURIComponent( JSON.stringify(response.data.data.Pago)))
          if (data.status && data.data.message == "Pago aprobado") {
            clearInterval(this.timeout);
          }
        })
        .catch(e => {
          this.setState({
            //infoPago: e.response.data.message,
            xhr: false,
            ////showModal: true,
            errorMessage: "server-error: " + e.response.data.message
          });

          console.log(e);
        });

      this.intentos++;

      if (this.intentos > 1) {
        const v = { data: "Ford" };
        clearInterval(this.timeout);
        this.setState({ infoPago: v });
      }
    }
  };

  actualizarPago = valores => {
    this.setState({ idEnvio: valores });

    var consulta = undefined;
  };

  tiposServicios(campos) {
    //Si es retiro en domicilio (rd) : CodigoPostalRemitente = CodigoPostalRemitente;
    //Si es entrega en agencia (ea): CodigoPostalRemitente = CodigoSucursalOrigen
    campos.CodigoPostalRemitente =
      campos.CodigoPostalRemitente != ""
        ? campos.CodigoPostalRemitente
        : campos.CodigoSucursalOrigen != ""
        ? campos.CodigoSucursalOrigen
        : "1414";
    //: campos.CodigoPuntoRecoleccion;
    this.setState({ NumeroBultos: campos.NumeroBultos });
    this.setState({ Kilos: campos.Kilos });
    this.setState({ Largo: campos.Largo });
    this.setState({ Alto: campos.Alto });
    this.setState({ Ancho: campos.Ancho });
    this.setState({ CodigoSucursalOrigen: campos.CodigoSucursalOrigen });
    this.setState({ CodigoPostalRemitente: campos.CodigoPostalRemitente });
    this.setState({
      CodigoPostalDestinatario: campos.CodigoPostalDestinatario
    });
    this.setState({ ImporteValorDeclarado: campos.ImporteValorDeclarado });
    this.setState({ Pago: campos.Pago });

    this.setState({ open2: true });
    this.setState({ open1: false });

    this.setState({ Code: campos.Code });

    console.log(
      "this.state.IdClienteRemitenteRD: " +
        JSON.stringify(this.state.IdClienteRemitenteRD)
    );
    campos.IdClienteRemitente = this.state.IdClienteRemitenteRD;
    campos.IdCentroRemitente = "01";
    if (this.state.productCode == "ea") {
      campos.IdClienteRemitente = this.state.IdClienteRemitenteEA;
      campos.IdCentroRemitente = "02";
    } else if (this.state.productCode == "pm") {
      campos.IdClienteRemitente = this.state.IdClienteRemitentePM;
      campos.IdCentroRemitente = "01";
    }

    let cotizarPost = process.env.REACT_APP_API_URL + `/api/cotizar`;
    console.log("Product tiposServicios request: " + JSON.stringify(campos));
    axios
      .post(cotizarPost, campos, { params: { token: this.token } })
      .then(response => {
        let { data } = response;
        //console.log(data)
        console.log(
          "Product tiposServicios response: " + JSON.stringify(response)
        );

        this.setState((state, props) => ({
          tiposServicios: data
        }));

        this.setState({ open2: true });
        this.setState({ open1: false });
      })
      .catch(e => {
        this.setState({
          xhr: false,
          showModal: true,
          errorMessage: e.response.data.message
        });
        this.atrasForm1();
        console.log(e);
      });
    /*Tarea GDM-1031 - Inicio */
    let datosGuardados = JSON.parse(window.localStorage.getItem('datosRemitente'));
    let datosCampos = { ...datosGuardados,...campos };
    datosCampos.Volumen =
      (datosCampos.Alto * datosCampos.Ancho * datosCampos.Largo) / 1000000;
    datosCampos.TipoPortes = datosCampos.Pago;
    delete datosCampos.CodigoPostalRemitente;
    delete datosCampos.CodigoPostalDestinatario;
    delete datosCampos.Pago;
    window.localStorage.setItem("datosRemitente", JSON.stringify(datosCampos));
    /*Tarea GDM-1031 - Fin */
  }

  retiros = valores => {
    console.log("Product retiros");
    this.timeoutImprimir = setInterval(() => {
      imprimir(valores);
    }, 5000);

    const imprimir = valores => {
      console.log("Product imprimir");
      if (this.contador > 3) {
        clearInterval(this.timeoutImprimir);
        //console.log("a");
        this.setState({
          //infoPago: e.response.data.message,
          xhr: false,
          ////showModal: true,
          errorMessage: "server-error: Error al imprimir"
        });
      } else {
        let sumar = this.intentosImprimir + 1;
        this.intentosImprimir = sumar;
        this.contador++;

        console.log("Product imprimir else");
        let retiros = process.env.REACT_APP_API_URL + `/api/retiros`;
        axios
          .post(retiros, {}, { params: { token: this.token } })
          .then(response => {
            let { data } = response;
            console.log("Response retiros: " + data);
            clearInterval(this.timeoutImprimir);
          })
          .catch(e => {
            this.setState({
              xhr: false,
              showModal: true,
              errorMessage:
                "API Retiros server-error: " + e.response.data.message
            });
            //console.log(e);
          });
      }
    };
  };

  atrasForm1 = () => {
    this.setState({ open1: true });
    this.setState({ open2: false });
  };

  atrasForm2 = () => {
    this.setState({ open2: true });
    this.setState({ open3: false });
  };

  atrasForm3 = () => {
    this.setState({ open3: true });
    this.setState({ open4: false });
  };

  atrasForm4 = () => {
    this.setState({ open4: true });
    this.setState({ open5: false });
  };

  atrasForm6 = () => {
    this.setState({ open5: true });
    this.setState({ open6: false });
  };

  atrasForm7 = () => {
    this.setState({ open6: true });
    this.setState({ open7: false });
  };

  pickupMovil = valores => {
    this.setState({ pickupMovil: valores });
  };

  segundoForm = valores => {
    this.setState({ camposFormulario: valores });
    this.tiposServicios(valores);
  };

  tercerForm = () => {
    this.setState({ open3: true });
    this.setState({ open2: false });
  };

  cuartoForm = () => {
    this.setState({ open4: true });
    this.setState({ open3: false });
  };

  quintoForm = () => {
    this.setState({ open5: true });
    this.setState({ open4: false });
  };

  sextoForm = () => {
    this.setState({ open7: true }); //Tarea GDM-1031
    //this.setState({ open5: false });//Tarea GDM-1031
  };

  septimoForm = () => {
    this.setState({ open7: true });
    this.setState({ open6: false });
  };

  aceptarForm = valor => {
    this.setState({ aceptar: valor });
  };
  /*Tarea GDM-1031 - Inicio */
  limpiarForm() {
    for (let i = 0; i < document.getElementsByTagName("form").length; i++) {
      document.getElementsByTagName("form")[i].reset();
    }
  }
  volverForm = () => {
    this.setState({ limpiar: true });
    this.limpiarForm();
    this.setState({ open1: false });
    this.setState({ open2: false });
    this.setState({ open3: false });
    this.setState({ open4: false });
    this.setState({ open5: false });
    this.setState({ open6: false });
    this.setState({ open7: false });
  };
  ocultarConfirmar = () => {
    this.setState({ confirmar: false });
  };
  /*Tarea GDM-1031 - Fin */
  parametroSegundoForm = valores => {
    // console.log("parametroSegundoForm: " + JSON.stringify(valores));
    this.setState({ IdCotizacion: valores.Id });
    this.setState({ DescripcionServicio: valores.DescripcionServicio });
    this.setState({ CodigoServicio: valores.Codigo });
    this.setState({ TotalServicio: valores.Total });
    this.setState({ TipoEntrega: valores.TipoEntrega });
  };

  parametroRemitente = valores => {
    //if(productCode != "pm")
    valores.DireccionRemitente = valores.DireccionRemitente.replace(/"/g, "");
    valores.NombreRemitente = valores.NombreRemitente.replace(/"/g, "");
    this.setState({ NombreRemitente: valores.NombreRemitente });
    this.setState({ DireccionRemitente: valores.DireccionRemitente });
    this.setState({ NumeroRemitente: valores.NumeroRemitente });
    this.setState({ PisoRemitente: valores.PisoRemitente });
    this.setState({ DeptoRemitente: valores.DeptoRemitente });
    this.setState({ NroDocRemitente: valores.NroDocRemitente });
    this.setState({ CodPaisRemitente: valores.CodPaisRemitente });
    this.setState({ CodAreaRemitente: valores.CodAreaRemitente });
    this.setState({ NroCelularRemitente: valores.NroCelularRemitente });
    this.setState({
      TelefonoRemitente:
        valores.CodPaisRemitente +
        "" +
        valores.CodAreaRemitente +
        "" +
        valores.NroCelularRemitente
    });
    this.setState({ EmailRemitente: valores.EmailRemitente });
    this.setState({ datosRemitente: valores });
    /*Tarea GDM-1031 - Inicio */
    let datosRetiroRemitente = JSON.parse(
      window.localStorage.getItem("datosRemitente")
    );
    let datosRemi = { ...valores };
    datosRemi.DireccionRemitente =
      datosRemi.DireccionRemitente +
      " " +
      datosRemi.NumeroRemitente +
      " " +
      datosRemi.PisoRemitente;

    datosRemi.Observaciones = datosRemi.DatosAdicional;
    datosRemi.CodigoPostalRemitente = datosRemi.CodigoPostal;
    datosRemi.TipoDocRemitente = "DNI";
    datosRemi.TelefonoRemitente =
      valores.CodPaisRemitente +
      "" +
      valores.CodAreaRemitente +
      "" +
      valores.NroCelularRemitente;
    //datosRemi.TelefonoRemitente = valores.NroCelularRemitente;

    /*Original*/
    // console.log(
    //   "this.state.CodigoServicio: " + JSON.stringify(this.state.productCode)
    // );
    datosRemi.IdClienteRemitente = this.state.IdClienteRemitentePM;
    datosRemi.IdCentroRemitente = this.state.IdCentroRemitentePM;
    if (this.state.productCode == "ea") {
      datosRemi.IdClienteRemitente = this.state.IdClienteRemitenteEA;
      datosRemi.IdCentroRemitente = this.state.IdCentroRemitenteEA;
    } else if (this.state.productCode == "rd") {
      datosRemi.IdClienteRemitente = this.state.IdClienteRemitenteRD;
      datosRemi.IdCentroRemitente = this.state.IdCentroRemitenteRD;
    }

    //console.log( "this.state.CodigoServicio: " + JSON.stringify(this.state.CodigoServicio) );
    // if (this.state.productCode == "ea") {
    //   datosRemi.IdClienteRemitente = "00000405";
    // } else if (productCode == "pm"){
    //   datosRemi.IdClienteRemitente = "99999999";
    // }
    // console.log(
    //   "datosRemi.IdClienteRemitente: " +
    //     JSON.stringify(datosRemi.IdClienteRemitente)
    // );

    delete datosRemi.NroCelularRemitente;
    delete datosRemi.DatosAdicional;
    delete datosRemi.NumeroRemitente;
    delete datosRemi.CodigoPostal;
    delete datosRemi.Piso;
    delete datosRemi.CodigoSucursalOrigen;
    datosRemi = { ...datosRetiroRemitente, ...datosRemi };
    window.localStorage.setItem("datosRemitente", JSON.stringify(datosRemi));
    console.log("Product, datosRemitente: " + JSON.stringify(datosRemi));
    /*Tarea GDM-1031 - Fin */
  };

  parametroDestinatario = valores => {
    valores.DireccionDestinatario = valores.DireccionDestinatario.replace(
      /"/g,
      ""
    );
    valores.NombreDestinatario = valores.NombreDestinatario.replace(/"/g, "");
    this.setState({ NombreDestinatario: valores.NombreDestinatario });
    this.setState({ DireccionDestinatario: valores.DireccionDestinatario });
    this.setState({ NumeroDestinatario: valores.NumeroDestinatario });
    this.setState({ PisoDestinatario: valores.PisoDestinatario });
    this.setState({ DeptoDestinatario: valores.DeptoDestinatario });
    this.setState({ NroDocDestinatario: valores.NroDocDestinatario });
    this.setState({ CodPaisDestinatario: valores.CodPaisDestinatario });
    this.setState({ CodAreaDestinatario: valores.CodAreaDestinatario });
    this.setState({ NroCelularDestinatario: valores.NroCelularDestinatario });
    this.setState({
      TelefonoDestinatario:
        valores.CodPaisDestinatario +
        "" +
        valores.CodAreaDestinatario +
        "" +
        valores.NroCelularDestinatario
    });
    this.setState({ EmailDestinatario: valores.EmailDestinatario });
    this.setState({ datosDestinatario: valores });
    /*Tarea GDM-1031 - Inicio */

    let datosRetiroDestinatario = JSON.parse(
      window.localStorage.getItem("datosRemitente")
    );
    let datosDesti = { ...valores };
    datosDesti.DireccionDestinatario =
      datosDesti.DireccionDestinatario +
      " " +
      datosDesti.NumeroDestinatario +
      " " +
      datosDesti.PisoDestinatario;
    datosDesti.Observaciones2 = datosDesti.DatosAdicional;
    datosDesti.CodigoPostalDestinatario = datosDesti.CodigoPostal;
    datosDesti.TipoDocDestinatario = "DNI";
    datosDesti.IdClienteDestinatario = "99999999";
    datosDesti.IdCentroDestinatario = "99";
    datosDesti.TelefonoDestinatario =
      valores.CodPaisDestinatario +
      "" +
      valores.CodAreaDestinatario +
      "" +
      valores.NroCelularDestinatario;
    //datosDesti.TelefonoDestinatario = valores.NroCelularDestinatario;

    delete datosDesti.NroCelularRemitente;
    delete datosDesti.DatosAdicional;
    delete datosDesti.NumeroDestinatario;
    delete datosDesti.CodigoPostal;
    delete datosDesti.Piso;
    datosDesti = { ...datosRetiroDestinatario, ...datosDesti };
    window.localStorage.setItem("datosRemitente", JSON.stringify(datosDesti));
    console.log("Product, datosDestinatario: " + JSON.stringify(datosDesti));
    /*Tarea GDM-1031 - Fin */
  };

  parametroTarjeta = valores => {
    this.setState({ IdTarjeta: valores.IdTarjeta });
    this.setState({ IdPosnet: valores.IdPosnet });
    this.setState({ Cuotas: valores.Cuotas });
    this.setState({ Coeficiente: valores.Coeficiente });
  };

  setOpenClose(valor, indice) {
    if (indice == 2) {
      this.setState({ open2: valor });
      this.setState({ open1: !valor });
    } else if (indice == 3) {
      this.setState({ open3: valor });
      this.setState({ open2: !valor });
    }
  }

  handleSubmit = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setState({ validated: true });
  };

  setearIntervalo(valor) {
    this.setState({ intervalo: valor });
  }

  render() {
    let { activeId, setActiveId } = this.state;
    let valor = "'" + activeId + "'";

    const formulario = Object.entries(this.state.formPago).map(
      ([key, value]) => {
        let i = 0;
        return <input key={i++} type="text" name={key} value={value}></input>;
      }
    );

    const formularioPago = () => {
      return;
      // <form id="checkoutForm" method="post" action={this.state.formPago.action}>
      //   {formulario}
      //   {this.current.submit()}
      // </form>;
    };

    const valorIframe = formularioPago;

    return (
      <div>
        <Modal
          show={this.state.showModal}
          onClose={() => this.setState({ showModal: false })}
          className={"modal"}
          containerClassName={"modal-container"}
        >
          <div className={"close-button"}>
            <i
              className={"material-icons"}
              onClick={() => this.setState({ showModal: false })}
            >
              close
            </i>
          </div>
          <div className={"modal-body"}>
            {this.state.modalMessage}
            {this.state.errorMessage}
            <div>
              <button
                className="quote btn btn-default"
                onClick={() => this.setState({ showModal: false })}
              >
                Cerrar
              </button>
            </div>
          </div>
        </Modal>

        <section className="screen-contents product-screen">
          <div className="background">
            <img src={background} alt="Fondo" />
          </div>
          <div className="details-container">
            <div className="content">
              <div className="content-title title-product">
                <h1>{this.state.title}</h1>
                <div>
                  <img
                    src={this.state.logoHeader}
                    alt="{this.state.title}"
                    className="logoicoservicios"
                  />
                </div>
              </div>
              <div className="content-description description-product">
                <p>{this.state.description}</p>
                <p>La vía más rápida y segura para hacer tus envíos.</p>
              </div>
              <div>
                <Card>
                  <Card.Header
                    onClick={() => this.setOpen(!this.state.open1, 1)}
                    aria-controls="example-collapse-text"
                    aria-expanded={this.state.open1}
                  >
                    1- Comenzá a gestionar
                  </Card.Header>
                  <Collapse in={this.state.open1}>
                    <Card.Body>
                      {/*<Button style={{height:10}}
                          onClick={() => this.setOpenClose(!this.state.open2, 2)}
                          //aria-controls="example-collapse-text"
                          //aria-expanded={this.state.open2}
                        >
                          siguiente
                        </Button>*/}
                      <Formulario
                        codigoProducto={this.state.productCode}
                        titulo={this.state.title}
                        titleDescription={this.state.description}
                        actual={() => this.setOpen(!this.state.open1, 1)}
                        pickupMovil={this.pickupMovil}
                        tiposServicios={this.segundoForm}
                        checkToken={this.checkToken}
                        limpio={this.state.limpiar} //Tarea GDM1031
                        //Importes = {this.state.Importes}
                      />
                    </Card.Body>
                  </Collapse>
                </Card>

                <Card>
                  <Card.Header
                    //onClick={() => this.setOpen(!this.state.open2, 2)}
                    aria-controls="example-collapse-text"
                    aria-expanded={this.state.open2}
                  >
                    2- Tipo de servicio
                  </Card.Header>
                  <Collapse in={this.state.open2}>
                    <Card.Body>
                      {this.state.tiposServicios && (
                        <TiposServicios
                          codigoProducto={this.state.productCode}
                          tiposServicios={this.state.tiposServicios}
                          actual={() => this.setOpen(!this.state.open1, 1)}
                          parametroSegundoForm={this.parametroSegundoForm}
                          tercerForm={this.tercerForm}
                          atrasForm1={this.atrasForm1}
                          checkToken={this.checkToken}
                        />
                      )}
                    </Card.Body>
                  </Collapse>
                </Card>

                <Card>
                  <Card.Header
                    //onClick={() => this.setOpen(!this.state.open3, 3)}
                    aria-controls="example-collapse-text"
                    aria-expanded={this.state.open3}
                  >
                    3- Datos del remitente
                  </Card.Header>
                  <Collapse in={this.state.open3}>
                    <Card.Body>
                      {this.state.tiposServicios && (
                        <DatosRemitente
                          codigoProducto={this.state.productCode}
                          tiposServicios={this.state.tiposServicios}
                          pickupMovil={this.state.pickupMovil}
                          cuartoForm={this.cuartoForm}
                          atrasForm2={this.atrasForm2}
                          parametroRemitente={this.parametroRemitente}
                          CodigoPostalRemitente={
                            this.state.CodigoPostalRemitente
                          }
                        />
                      )}
                    </Card.Body>
                  </Collapse>
                </Card>

                <Card>
                  <Card.Header
                    //onClick={() => this.setOpen(!this.state.open3, 3)}
                    aria-controls="example-collapse-text"
                    aria-expanded={this.state.open4}
                  >
                    4- Datos del destinatario
                  </Card.Header>
                  <Collapse in={this.state.open4}>
                    <Card.Body>
                      {this.state.tiposServicios && (
                        <DatosDestinatario
                          quintoForm={this.quintoForm}
                          atrasForm3={this.atrasForm3}
                          parametroDestinatario={this.parametroDestinatario}
                          CodigoPostalDestinatario={
                            this.state.CodigoPostalDestinatario
                          }
                        />
                      )}
                    </Card.Body>
                  </Collapse>
                </Card>

                <Card>
                  <Card.Header
                    //onClick={() => this.setOpen(!this.state.open5, 5)}
                    aria-controls="example-collapse-text"
                    aria-expanded={this.state.open5}
                  >
                    5- Confirmar datos
                  </Card.Header>
                  <Collapse in={this.state.open5}>
                    <Card.Body>
                      {this.state.tiposServicios && this.state.confirmar && (
                        <ConfirmarDatos
                          codigoProducto={this.state.productCode}
                          sextoForm={this.sextoForm}
                          atrasForm4={this.atrasForm4}
                          DescripcionServicio={this.state.DescripcionServicio}
                          TipoEntrega={this.state.TipoEntrega}
                          Alto={this.state.camposFormulario.Alto}
                          Ancho={this.state.camposFormulario.Ancho}
                          Largo={this.state.camposFormulario.Largo}
                          NumeroBultos={
                            this.state.camposFormulario.NumeroBultos
                          }
                          TotalServicio={this.state.TotalServicio}
                          pickupMovil={this.state.pickupMovil}
                          datosRemitente={this.state.datosRemitente}
                          datosDestinatario={this.state.datosDestinatario}
                          aceptarForm={this.aceptarForm}
                          retiros=""
                          tiposServicios={this.state.tiposServicios}
                          formPago={this.state.formPago}
                          importe={this.state.TotalServicio}
                          actualizarPago={this.actualizarPago}
                          NombreRemitente={this.state.NombreRemitente}
                          DireccionRemitente={this.state.DireccionRemitente}
                          NroDocRemitente={this.state.NroDocRemitente}
                          Email={this.state.EmailRemitente}
                          //retiros={this.retiros}
                          data={this.state}
                        />
                      )}
                      {this.state.open6 && (
                        <div>
                          {this.state.infoPago === undefined && (
                            <Tarjetas
                              septimoForm={this.septimoForm}
                              atrasForm6={this.atrasForm6}
                              parametroTarjeta={this.parametroTarjeta}
                            />
                          )}
                        </div>
                      )}
                      {this.state.open7 && (
                        <div>
                          {this.state.infoPago === undefined && (
                            <FomularioPago
                              codigoProducto={this.state.productCode}
                              formPago={this.state.formPago}
                              importe={this.state.TotalServicio}
                              actualizarPago={this.actualizarPago}
                              NombreRemitente={this.state.NombreRemitente}
                              DireccionRemitente={this.state.DireccionRemitente}
                              NroDocRemitente={this.state.NroDocRemitente}
                              Email={this.state.EmailRemitente}
                              IdTarjeta={this.state.IdTarjeta}
                              IdPosnet={this.state.IdPosnet}
                              Cuotas={this.state.Cuotas}
                              Coeficiente={this.state.Coeficiente}
                              atrasForm7={this.atrasForm7}
                              retiros={this.retiros}
                              data={this.state}
                              volverForm={this.volverForm}
                              ocultarConfirmar={this.ocultarConfirmar}
                            />
                          )}
                          {/* this.state.infoPago && this.state.infoPago.data  && <PagoProcesado 
                        formPago={this.state.infoPago} 
                        importe="1000" 
                        senderFullName={this.state.NombreRemitente}
                        DireccionRemitente={this.state.DireccionRemitente}
                        NroDocRemitente={this.state.NroDocRemitente}
                        NroDocRemitente={this.state.NroDocRemitente}
                        />*/}
                          {/*  <PickUpFormulario info="aki"/>*/}
                        </div>
                      )}
                    </Card.Body>
                  </Collapse>
                </Card>

                {/*
                <Card>
                  <Card.Header
                    //onClick={() => this.setOpen(!this.state.open6, 6)}
                    aria-controls="example-collapse-text"
                    aria-expanded={this.state.open6}
                  >
                    6- Formas de pago
                  </Card.Header>
                  <Collapse in={this.state.open6}>
                    <Card.Body>
                      {this.state.open6 && (
                        <div>
                          {this.state.infoPago === undefined && (
                            <Tarjetas
                              septimoForm={this.septimoForm}
                              atrasForm6={this.atrasForm6}
                              parametroTarjeta={this.parametroTarjeta}
                            />
                          )}
                        </div>
                      )}
                      {this.state.open7 && (
                        <div style={{ marginTop: "20px" }}>
                          {this.state.infoPago === undefined && (
                            <FomularioPago
                              formPago={this.state.formPago}
                              importe={this.state.TotalServicio}
                              actualizarPago={this.actualizarPago}
                              NombreRemitente={this.state.NombreRemitente}
                              DireccionRemitente={this.state.DireccionRemitente}
                              NroDocRemitente={this.state.NroDocRemitente}
                              Email={this.state.EmailRemitente}
                              IdTarjeta={this.state.IdTarjeta}
                              IdPosnet={this.state.IdPosnet}
                              Cuotas={this.state.Cuotas}
                              Coeficiente={this.state.Coeficiente}
                              atrasForm7={this.atrasForm7}
                              retiros={this.retiros}
                              data={this.state}
                            />
                          )}
                        </div>
                      )}
                    </Card.Body>
                  </Collapse>
                </Card>
              */}
              </div>
            </div>
            <Sidebar />
          </div>
        </section>
      </div>
    );
  }
}

export default Product;
